import React, { useState, useEffect, useRef } from 'react'
import Table from '../UI-Elements/Table'
import Modal from '../UI-Elements/Modal'
import Button from '../UI-Elements/Button';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useAuth } from '../Auth/AuthProvider'
import { DEFAULT_PAGE_SIZE } from '../Data/Constants';

function ListComponentMappings(props) {
    let auth = useAuth();
    let { problem_data } = props;

    const [data, setData] = useState([])
    const [tableData, setTableData] = useState(null)

    useEffect(() => {
        console.log(problem_data)
        fetchAllComponents()
    }, [])

    async function fetchAllComponents() {
        console.log("fectching all mapped components...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/problem/component-mappings/' + problem_data['problem_id'])
            .then(res => {
                console.log('components', res.data.data)
                setData(res.data.data)
            })
            .catch(function (error) {
                const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
                openPopup({ type: 'error', text: msg})
            })
    }

    useEffect(() => {
        let temp = [...data].map((value, index) => {
            value["actions"] = <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div className='list-action' onClick={() => { confirmDelete(index) }}><span className="material-icons">delete</span></div>
            </div>
            return value
        })

        setTableData(temp)
    }, [data])

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const deleteModalOpen = useRef(null)
    const deletemodalClose = useRef(null)
    const [deleteModalData, setDeleteModalData] = useState(null)
    const [deleteLoading, setDeleteLoading] = useState(false)

    const handleDeleteModalOpen = (index) => {
        setDeleteModalData(index)
        deleteModalOpen.current()
    }

    const handleDeleteModalClose = () => {
        setDeleteModalData(null)
        deletemodalClose.current()
    }

    function confirmDelete(index) {
        handleDeleteModalOpen(index)
    }

    async function handleDelete() {
        setDeleteLoading(true)
        console.log('delete id', data[deleteModalData]['component_id'])
        try {
            const { res } = await auth.authAxios({
                method: 'delete',
                url: process.env.REACT_APP_BACKEND_API + '/component-mapping/' + data[deleteModalData]['component_id'],
                data: {
                    'problem_id': problem_data['problem_id'],
                    'user':auth.name
                }
            });
            console.log(res);
            handleDeleteModalClose()
            fetchAllComponents()
            openPopup({ type: 'success', text: 'Component Mapping Deleted Successfully' })
        } catch (error) {
            const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
            openPopup({ type: 'error', text: msg})
        }
        setDeleteLoading(false)
    }

    const Header = [
        { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true },
        // { Header: 'Component ID', accessor: 'component_id' },
        { Header: 'Component Name', accessor: 'component_name', canFilter: true },
        { Header: 'Airplane', accessor: 'airplane', canFilter: true },
        { Header: 'Part No.', accessor: 'part_no', canFilter: true },
        { Header: 'Component Position List', accessor: 'component_position_list', Cell: ({ cell: { value } }) => { return value.toString() }, canFilter: true },
        { Header: 'ATA Code', accessor: 'component_ata_code', canFilter: true },
        // { Header: 'Event Tag Rules', accessor: 'component_event_tag_rules', Cell: ({ cell: { value } }) => { return JSON.stringify(value) }, canFilter: true },
        { Header: 'GCS Name', accessor: 'gcs_names',Cell: ({ cell: { value } }) => { return value && value.toString() }, canFilter: true },
    ]

    if (tableData) {
        return (
            <>
                <Table header={Header} data={tableData} title='' pageSize={DEFAULT_PAGE_SIZE} />

                <Modal modalOpen={deleteModalOpen} modalClose={deletemodalClose} title="Delete Component Mapping">
                    <>Deleting this mapping won't delete the Component.<br /> Are you sure you want to delete?<br /><br /></>

                    <Button variant="filled-danger" action={() => { handleDelete() }}>
                        {deleteLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Delete</p>}
                    </Button>
                </Modal>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
            </>
        )

    }
    else {
        return (<></>)
    }
}

export default ListComponentMappings