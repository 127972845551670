import React, { useState, useEffect, useRef } from 'react'
import Modal from '../../Components/UI-Elements/Modal';
import Table from '../../Components/UI-Elements/Table'
import Button from '../../Components/UI-Elements/Button'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import '../../Styles/Pages/ListPage.css'
import EditProblem from './EditProblem';
import ListParameterMappings from '../../Components/Mapping/ListParameterMappings';
import ListComponentMappings from '../../Components/Mapping/ListComponentMappings';
import DataExport from '../../Components/UI-Elements/DataExport';

import { useAuth } from '../../Components/Auth/AuthProvider'
import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';
import { generateOptions } from '../../Common/Helper';

function ListProblem() {
  let auth = useAuth();

  const modalOpen = useRef(null)
  const modalClose = useRef(null)
  const [modalData, setModalData] = useState(null)
  const [data, setData] = useState([])

  const [alert, setAlert] = useState({ type: 'error', text: '' })
  const [popup, setPopup] = useState(false)

  const [tableData, setTableData] = useState(null)
  const [customerData, setCustomerData] = useState(null)
  const [airplaneData, setAirplaneData] = useState(null)

  const [ataCodeOptions, setAtaCodeOptions] = useState(null);

  const [selectedProblems, setSelectedProblems] = useState([]);

  const handleModalOpen = (index) => {
    let curData = JSON.parse(JSON.stringify(data[index]));
    if (typeof curData.airplane_id === 'string' || curData.airplane_id instanceof String) {
      curData.airplane_id = parseInt(curData.airplane_id.split(',')[0]);
    }
    curData.airplane_model = curData.airplane_model.split(',')[0];
    setModalData({ ...curData })
    modalOpen.current()
  }

  const handleModalClose = (_index) => {
    setModalData("")
    modalClose.current()
  }

  useEffect(() => {
    fetchAllProblems()
    fetchCustomers()
    fetchAllAirplanes()
    fetchSettings()
  }, [])

  async function fetchAllProblems() {
    console.log("fectching all problems...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/problem')
      .then(res => {
        console.log('problems', res.data.data)
        res.data.data.map((elem) => {
          elem.airplane_model = elem.airplane_model
          elem.airplane_submodel = elem.airplane_submodel.join(',')
        })
        setData(res.data.data)
      })
      .catch(function (error) {
        const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
        openPopup({ type: 'error', text: msg})
      })
  }

  const fetchAllMappedValues = async (type) => {
    try {
      let promises = [];
      let problemMapping = {};
      let url = '';
      if(type == 'component') {
        url = process.env.REACT_APP_BACKEND_API + '/problem/component-mappings/';
      } else if (type == 'parameter') {
        url = process.env.REACT_APP_BACKEND_API + '/problem/parameter-mappings/';
      }
      data.map((p) => {
        promises.push(auth.authAxios.get(url + p.problem_id));
        problemMapping[p.problem_id] = p.problem_name;
      });
      let values = await Promise.all(promises);
      let temp = [];
      values.map((value) => {
        let v = [...value.data.data];
        v.map((mapping) => {
          mapping['problem_name'] = problemMapping[mapping.problem_id];
          mapping['gcs_name'] = customerData.find((c) => c.customer_id == mapping.customer_id).gcs_name;
          mapping['airplane_submodel'] = airplaneData[mapping['airplane']].map((a) => a.submodel).join('|');
          mapping['problem_ata_code'] = mapping['problem_ata_code'] && mapping['problem_ata_code'].includes(',') ? mapping['problem_ata_code'].split(',').join('|') : mapping['problem_ata_code'];
          if(type == 'parameter') {
            mapping['aggregators'] = mapping['aggregators'];
            mapping['flight_phases'] = mapping['flight_phases'];
            mapping['parameter_ata_code'] = mapping['parameter_ata_code'] && mapping['parameter_ata_code'].includes(',') ? mapping['parameter_ata_code'].split(',').join('|') : mapping['parameter_ata_code'];
          } else {
            mapping['component_ata_code'] = mapping['component_ata_code'] && mapping['component_ata_code'].includes(',') ? mapping['component_ata_code'].split(',').join('|') : mapping['component_ata_code'];
          }
        })
        temp = temp.concat(value.data.data);
      })
      return temp;
    } catch (error) {
      let msg = '';
      if(error && error.response.data.error) {
        msg = error.response.data.error
      } else if (error && error.response.data.msg) {
        msg = error.response.data.msg
      } else {
        msg = error
      }
      openPopup({ type: 'error', text: msg})
    }
  }

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  useEffect(() => {
    let temp = [...data].map((value, index) => {
      if(value['ata_code'].includes(',')) { // belonging to multiple ATA categories
        let ataCodes = value['ata_code'].split(',');
        value['ata_code'] = ataCodes;
      } else {
        value['ata_code'] = value['ata_code'] ? [value['ata_code']] : [];
      }
      value["actions"] = <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div className='list-action' onClick={() => handleModalOpen(index)}><span className="material-icons">launch</span></div>
        <div className='list-action' onClick={() => handleCloneModalOpen(value['problem_id'])}><span className="material-icons">file_copy</span></div>
        <div className='list-action' onClick={() => confirmDelete(value['problem_id'])}><span className="material-icons">delete</span></div>
      </div>
      value["mappings"] = <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='list-action' style={{ color: 'var(--color-primary)' }} onClick={() => openParameterMapping(index)}>Parameters</div>
        <hr style={{ margin: '0 10px' }} />
        <div className='list-action' style={{ color: 'var(--color-primary)' }} onClick={() => openComponentMapping(index)}>Components</div>
      </div>
      return value
    })

    setTableData(temp)
  }, [data])

  const checkboxOnChange = (rowIndex) => {
    let temp = [...selectedProblems];
    const id = tableData[rowIndex].problem_id;
    let idx = temp.indexOf(id);
    if (idx !== -1) {
        temp.splice(idx, 1);
    } else {
        temp.push(id);
    }
    setSelectedProblems(temp);
  }

  const isCheckboxChecked = (index) => {
    const problem_id = tableData[index].problem_id;
    return selectedProblems.includes(problem_id)
  }

  const listParameterModalOpen = useRef(null)
  const listParameterModalClose = useRef(null)
  const [listParameterModalData, setListParameterModalData] = useState(null)
  const openParameterMapping = (index) => {
    setListParameterModalData(data[index])
    listParameterModalOpen.current()
  }
  const closeParameterMapping = () => {
    listParameterModalClose.current()
    setListParameterModalData(null)
  }

  const listComponentModalOpen = useRef(null)
  const listComponentModalClose = useRef(null)
  const [listComponentModalData, setListComponentModalData] = useState(null)
  const openComponentMapping = (index) => {
    setListComponentModalData(data[index])
    listComponentModalOpen.current()
  }
  const closeComponentMapping = () => {
    listComponentModalClose.current()
    setListComponentModalData(null)
  }

  const deleteModalOpen = useRef(null)
  const deletemodalClose = useRef(null)
  const [deleteModalData, setDeleteModalData] = useState(null)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const handleDeleteModalOpen = (data) => {
    setDeleteModalData(data)
    deleteModalOpen.current()
  }

  const handleDeleteModalClose = () => {
    setDeleteModalData("")
    deletemodalClose.current()
  }

  async function confirmDelete(id) {
    handleDeleteModalOpen(id)
  }

  async function handleDelete() {
    setDeleteLoading(true)

    try {
      const idList = !Array.isArray(deleteModalData) ? [deleteModalData] : deleteModalData;
      const res = await auth.authAxios({
        method: 'delete',
        url: process.env.REACT_APP_BACKEND_API + '/problem',
        data: {
          'problem_ids': idList,
          'user':auth.name
        }
      });

      console.log(res);
      if(res.status === 200)
      {
        handleDeleteModalClose()
        fetchAllProblems()
        openPopup({ type: 'success', text: 'Problem Deleted Successfully' })
      }
    } catch (error) {
      const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
      openPopup({ type: 'error', text: msg})
    }
    setDeleteLoading(false)

  }

  const cloneModalOpen = useRef(null);
  const cloneModalClose = useRef(null);
  const [cloneModalData, setCloneModalData] = useState(null);
  const [cloneLoading, setCloneLoading] = useState(false);
  const handleCloneModalOpen = (id) => {
    setCloneModalData(id)
    cloneModalOpen.current()
  }

  const handleCloneModalClose = () => {
    cloneModalClose.current()
    setCloneModalData(null)
  }

  const cloneProblem = async () => {
    setCloneLoading(true)

    try {
      const res = await auth.authAxios({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_API + '/problem/clone/' + cloneModalData,
        data: {
          'user':auth.name
        }
      });

      console.log(res);
      if(res.status === 200)
      {
        handleCloneModalClose()
        fetchAllProblems()
        openPopup({ type: 'success', text: 'Problem Cloned Successfully' })
      }
    } catch (error) {
      const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
      openPopup({ type: 'error', text: msg})
    }
    setCloneLoading(false)
  }

  const Header = [
    { Header: 'Bulk Delete', accessor: 'checkbox', canFilter: false, disableSortBy: true, Cell: (row) => {
      return (
        <input 
          type='checkbox'
          checked={isCheckboxChecked(row.row.index)}
          onChange={() => checkboxOnChange(row.row.index)}
        />
      )
    }},
    { Header: 'Actions', accessor: 'actions', canFilter: false, disableSortBy: true },
    // { Header: 'Problem ID', accessor: 'problem_id' },
    { Header: 'Problem Name', accessor: 'problem_name', canFilter: true },
    // { Header: 'ATA Code', accessor: 'ata_code' },
    { Header: 'ATA Code', accessor: 'ata_code', Cell: ({ cell: { value } }) => { return value ? value.toString() : value }, canFilter: true },
    // { Header: 'Customer ID', accessor: 'customer_id' },
    { Header: 'GCS Name', accessor: 'gcs_name', canFilter: true },
    // { Header: 'Airplane ID', accessor: 'airplane_id' },
    { Header: 'Airplane Description', accessor: 'airplane_desc', canFilter: true },
    { Header: 'Airplane Model', accessor: 'airplane_model', canFilter: true },
    { Header: 'Airplane Sub-Model', accessor: 'airplane_submodel', canFilter: true },
    { Header: 'Mappings', accessor: 'mappings', canFilter: false, disableSortBy: true },
    { Header: 'Version', accessor: 'version', canFilter: false, disableSortBy: true },
  ]

  async function fetchAllAirplanes() {
    console.log("fectching airplane data...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-airplane')
      .then(res => {
        // console.log(res.data.data)
        let temp = res.data.data
        let final = {}

        temp.forEach(element => {
          if (final[element.model]) {
            final[element.model].push({ 'submodel': element.submodel, 'submodel_id': element.submodel_id })
          }
          else {
            final[element.model] = [{ 'submodel': element.submodel, 'submodel_id': element.submodel_id }]
          }
        });
        console.log('airplaneData', final)
        setAirplaneData(final);
      })
      .catch(function (error) {
        const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
        openPopup({ type: 'error', text: msg})
      })
  }

  const fetchCustomers = () => {
    console.log("fectching customer data...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-customer')
      .then(res => {
        // console.log(res.data.data)
        let data = []
        res.data.data.forEach(element => {
          data.push({
            'customer_id': element.id,
            'gcs_name': element.gcs_name,
            'email': element.contact_email
          })
        })
        console.log('customer data', data)
        setCustomerData(data)
      })
      .catch(function (error) {
        const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
        openPopup({ type: 'error', text: msg})
      })
  }

  async function fetchSettings() {
    console.log("fetching settings data...");
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/settings')
      .then((res) => {
        setAtaCodeOptions(generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'ATA Codes')?.value), 'json'));
      })
      .catch(function (error) {
        const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
        openPopup({ type: 'error', text: msg})
    })
  }

  if (customerData) {
    var customerOptions = customerData.map((customer, index) => {
      return (<option key={index} value={customer.customer_id}>{customer.gcs_name}</option>)
    })
  }

  
  if(tableData && customerOptions && airplaneData && ataCodeOptions) {
    return (
      <>
        <div className='list-page-subcontainer'>
          <Table header={Header} data={tableData} title='Problems' pageSize={DEFAULT_PAGE_SIZE} />
          <Modal modalOpen={modalOpen} modalClose={modalClose} title="Edit Problem">
            <EditProblem
              customerOptions={customerOptions}
              ataCodeOptions={ataCodeOptions}
              airplaneData={airplaneData}
              data={modalData}
              modalClose={handleModalClose}
              refresh={fetchAllProblems}
              alert={openPopup}
            />
          </Modal>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              variant={selectedProblems.length < 1 ? "disabled-style" : "filled-danger"}
              disabled={selectedProblems.length < 1}
              action={() => handleDeleteModalOpen(selectedProblems)}
            >
              {deleteLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Bulk Delete</p>}
            </Button>
            <DataExport
              getData={async () => await fetchAllMappedValues('component')}
              columnMapping={{
                gcs_name: 'GCS Name',
                problem_name: 'IA Problem',
                component_name: 'Component Name',
                airplane: 'Airplane',
                airplane_submodel: 'Airplane Submodel',
                problem_ata_code: 'Problem ATA Code',
                component_ata_code: 'Component ATA Code',
                part_no: 'Part Number',
                // component_event_tag_rules: 'Event tag Rules',
                component_position_list: 'Component Position List'
              }}
              fileName='ComponentMappingExport.csv'
              stringifyObject={true}
              arrayDelimiter='|'
              text='Export Component Mapping for All Problems'
            />
            <DataExport
              getData={async () => await fetchAllMappedValues('parameter')}
              columnMapping={{
                gcs_name: 'GCS Name',
                problem_name: 'IA Problem',
                boeing_mnemonic_code: 'Boeing Mnemonic Code',
                airplane: 'Airplane',
                airplane_submodel: 'Airplane Submodel',
                problem_ata_code: 'Problem ATA Code',
                parameter_ata_code: 'Parameter ATA Code',
                fda_name: 'FDA Parameter Name',
                parameter_desc: 'Parameter Description',
                ia_sensor_name: 'IA Base Sensor',
                ia_position_assignment: 'Position',
                additional_info: 'Additional Information',
                aggregators: 'Aggregators',
                flight_phases: 'Flight Phases'
              }}
              fileName='ParameterMappingExport.csv'
              stringifyObject={true}
              arrayDelimiter='|'
              text='Export Parameter Mapping for All Problems'
            />
          </div>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>

        <Modal modalOpen={deleteModalOpen} modalClose={deletemodalClose} title="Delete Problem">
          <>Deleting the problem will remove the mapping data as well.<br /> Are you sure you want to delete?<br /><br /></>
          <Button variant="filled-danger" action={() => { handleDelete() }}>
            {deleteLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Delete</p>}
          </Button>
        </Modal>

        <Modal modalOpen={cloneModalOpen} modalClose={cloneModalClose} title="Clone Problem">
          <>Cloning the problem will copy all paramter and component mappings. Cloned problem can be identified by name. Are you sure you want to clone this problem?<br /><br /></>
          <Button variant="filled-danger" action={() => { cloneProblem() }}>
            {cloneLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Clone</p>}
          </Button>
        </Modal>
        <Modal width='xl' modalOpen={listParameterModalOpen} modalClose={listParameterModalClose} title={<div style={{width:'100%',display:'flex',justifyContent:'space-between'}}><div>Parameter Mappings</div></div>}>
          <ListParameterMappings problem_data={listParameterModalData}/>
        </Modal>

        <Modal width='xl' modalOpen={listComponentModalOpen} modalClose={listComponentModalClose} title={<div style={{width:'100%',display:'flex',justifyContent:'space-between'}}><div>Component Mappings</div></div>}>
          <ListComponentMappings problem_data={listComponentModalData}/>
        </Modal>

      </>
    )
  }
  else{
    return (
      <>
        <div className='list-page-subcontainer' style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading....</p></div>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
      </>
    )
  }
}

export default ListProblem