import React, { useState, useEffect } from 'react'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import '../../Styles/Pages/AddPage.css'
import Table from '../../Components/UI-Elements/Table'
import {useAuth} from "../../Components/Auth/AuthProvider";
import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';

function Logs() {
    let auth = useAuth();
    const [logs, setLogs] = useState(null)

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    useEffect(() => {
        fetchLogs()
    }, [])

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const fetchLogs = () => {
        console.log("fectching logs...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/logs')
            .then(res => {
                // console.log(res.data.data)
                let data = []
                res.data.data.forEach(element => {
                    data.push({
                        'user_name': element.user_name,
                        'action': element.action,
                        'timestamp': element.timestamp,
                    })
                })
                console.log(data)
                setLogs(data.reverse())
            })
            .catch(function (err) {
                const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
                openPopup({ type: 'error', text: msg})
            })
    }

    const Header = [
        // { Header: 'Airplane ID', accessor: 'airplane_id' },
        { Header: 'User Name', accessor: 'user_name', canFilter: true },
        { Header: 'Action', accessor: 'action', canFilter: true },
        { Header: 'Timestamp', accessor: 'timestamp', canFilter: true }
    ]

    if (logs)
        return (
            <>
                <div className='add-page-subcontainer'>
                    <div className='full-width'>
                        <Table
                            header={Header}
                            data={logs}
                            title='Logs'
                            pageSize={DEFAULT_PAGE_SIZE}
                        />

                    </div>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                  <Alert severity={alert['type']} sx={{ width: '100%' }}>
                    {alert['text']}
                  </Alert>
                </Snackbar>
            </>
        )
    else {
        return (
            <>
                <div className='list-page-subcontainer' style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading....</p></div>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
            </>
        )
    }
}

export default Logs