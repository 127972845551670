import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from './Components/Layout'
import AddParameter from './Pages/Parameter/AddParameter';
import ListParameter from './Pages/Parameter/ListParameter';
import Dashboard from './Pages/Other/Dashboard';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import './Styles/App.css';
import './global.css'
import AddComponent from './Pages/Component/AddComponent';
import ListComponent from './Pages/Component/ListComponent';
import ProblemParameterMapping from './Pages/Problem/ProblemParameterMapping';
import ProblemComponentMapping from './Pages/Problem/ProblemComponentMapping';
import ConfigureAirplanes from './Pages/Misc/ConfigureAirplanes';
import ConfigureCustomers from './Pages/Misc/ConfigureCustomers';
import AddProblem from './Pages/Problem/AddProblem';
import ListProblem from './Pages/Problem/ListProblem';
import AddFredFile from './Pages/Misc/AddFredFile';
import ImportDataframes from './Pages/Dataframe/ImportDataframes';
import DataframeCustomerMapping from './Pages/Dataframe/DataframeCustomerMapping';

import AuthProvider, {RequireAuth} from './Components/Auth/AuthProvider';
import GenerateConfig from './Pages/Config/GenerateConfig';
import ListConfig from './Pages/Config/ListConfig';
import Logs from './Pages/Misc/Logs';
import Settings from './Pages/Misc/Settings';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#6971eb',
        main: '#4750D5',
        dark: '#4750d56f',
        contrastText: '#fff',
      },
      secondary: {
        light: '#A6A4B0',
        main: '#625F6E',
        contrastText: '#000',
      },
    }
  });

  let env_info;
  if (process.env.NODE_ENV !== 'production') {
    env_info = <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>;
  } else {
    env_info = <></>;
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <div className="App">
            {env_info}
            <RequireAuth>
              <Layout>
                <Routes>
                  <Route path="/parameter/add" element={ <AddParameter /> }></Route>
                  <Route path="/parameter/list" element={ <ListParameter /> }></Route>
                  <Route path="/component/add" element={ <AddComponent/> }></Route>
                  <Route path="/component/list" element={ <ListComponent/> }></Route>
                  <Route path="/problem/add" element={ <AddProblem/> }></Route>
                  <Route path="/problem/list" element={ <ListProblem/> }></Route>
                  <Route path="/problem/parameter-mapping" element={ <ProblemParameterMapping/> }></Route>
                  <Route path="/problem/component-mapping" element={ <ProblemComponentMapping/> }></Route>
                  <Route path="/config/generate" element={ <GenerateConfig/> }></Route>
                  <Route path="/config/list" element={ <ListConfig/> }></Route>
                  <Route path="/dataframe/import-dataframes" element={ <ImportDataframes/> }></Route>
                  <Route path="/dataframe/customer-mapping" element={ <DataframeCustomerMapping/> }></Route>
                  <Route path="/misc/airplane" element={ <ConfigureAirplanes/> }></Route>
                  <Route path="/misc/customers" element={ <ConfigureCustomers/> }></Route>
                  <Route path="/misc/add-fred-file" element={ <AddFredFile/> }></Route>
                  <Route path="/misc/logs" element={ <Logs/> }></Route>
                  <Route path="/misc/settings" element={ <Settings/> }></Route>
                  <Route path="/" element={ <Dashboard /> }></Route>
                </Routes>
              </Layout>
            </RequireAuth>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
