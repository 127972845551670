import React, { useState, useEffect } from 'react'
import Button from '../UI-Elements/Button'
import AutoCompleteInput from '../UI-Elements/AutoCompleteInput';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';

/*
props:

configuredComponentsData
configuredComponents
data
index
modalClose

*/
function EditMappedComponent(props) {

    const componentTemplate = {
        component_name: '',
        airplane: '',
        part_no: '',
        ata_code: '',
        component_event_tag_rules: '',
        component_position_list: '',
    };

    const [data, setData] = useState(componentTemplate)

    useEffect(() => {
        // console.log(props)
        let temp = {...props.data};
        if (temp) {
            temp.component_event_tag_rules = JSON.stringify(temp.component_event_tag_rules);
        }
        setData(temp);
    }, [props])

    const handleFormChange = (name, value, reason) => {
        let temp = {...data};
        temp[name] = value;

        if(reason === 'reset' && value && props.configuredComponents.includes(value)) {
            let val = props.configuredComponentsData[props.configuredComponents.indexOf(value)]
            temp['component_id'] = val['component_id'];
            temp['part_no'] = val['part_no'];
            temp['airplane'] = val['airplane'];
            temp['ata_code'] = val['ata_code'] ?? '';
            temp['component_event_tag_rules'] = val['component_event_tag_rules'] ?? '';
            temp['component_position_list'] = val['component_position_list'] ?? '';
        } else if(reason === 'clear') {
            temp = componentTemplate;
        }
        setData(temp);
    }

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)
    const [validationError, setValidationError] = useState(false)

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const getAirplaneFromName = (name) => {
        return name.split('(')[1].split('-')[0];
    }

    const handleSave = () => {
        let validateError = validateForm()

        if(!validateError)
        {
            let temp = {...data};
            temp.component_name = temp.component_name.split('(')[0];
            props.modalClose(props.index, temp);
        }
        else
        {
            openPopup({ type: 'error', text: 'Error!! Check Fields Again' })
        }
    }

    function validateForm(){
        if (
            // data['component_name'] == '' ||
            data['airplane'] == '' ||
            data['part_no'] == '') {
            setValidationError(true)
            return true;
        }
        else
        {
            return false;
        }
    }

    if (data)
        return (
            <div>
                {props.configuredComponents.includes(data.component_name)?<><Alert severity="warning">This Component is already configured.<br/> Any changes will update the existing configurations</Alert><br/></>:''}
                <div className='add-page-entity'>
                    <div className='add-page-form'>
                        <AutoCompleteInput
                            options={props.problemAirplane ?
                                props.configuredComponents.filter((p) => getAirplaneFromName(p) == props.problemAirplane) :
                                props.configuredComponents
                            }
                            validationError={validationError}
                            width={'250px'}
                            label={'Component Name'}
                            value={data.component_name}
                            handleChange={(n, v, r)=>{handleFormChange(n, v, r)}}
                            name={'component_name'}
                            placeholder={"Enter Component Name..."}
                        />
                        <div className='input-element'>
                            <label className='input_label'>Airplane Model*</label>
                            <select style={{ width: '250px' }} className={validationError.length > 0 && data.airplane == '' ? 'input_box_error' : 'input_box'} value={data.airplane || ''} onChange={event => handleFormChange(event.target.name, event.target.value)} name="airplane">
                                <option value="" disabled>Select Airplane Model...</option>
                                {props.airplaneOptions}
                            </select>
                        </div>
                        <div className='input-element'>
                            <label className='input_label'>Part No. *</label>
                            <input style={{ width: '250px' }} className={validationError && data.part_no == '' ? 'input_box_error' : 'input_box'} value={data.part_no} onChange={event => handleFormChange(event.target.name, event.target.value)} type="text" name="part_no" placeholder="Enter Part No..." />
                        </div>
                        <div className='input-element'>
                            <label className='input_label'>ATA Code</label>
                            <Select
                                name="ata_code"
                                className={'input_box'}
                                style={{ width: '250px ', height: '40px' }}
                                size='small'
                                placeholder="Select ATA Code..."
                                value={data.ata_code || []}
                                options={props.ataCodeOptions}
                                onChange={event => handleFormChange(event.target.name, event.target.value)}
                            >
                                {props.ataCodeOptions}
                            </Select>
                        </div>
                        {/* <div className='input-element'>
                            <label className='input_label'>Event Tag Rules</label>
                            <input style={{ width: '510px' }} value={data.component_event_tag_rules} onChange={event => handleFormChange(event.target.name, event.target.value)} className='input_box' type="text" name="component_event_tag_rules" placeholder="Enter Event Tag Rules..."/>
                        </div> */}
                        <div className='input-element'>
                            <label className='input_label'>Component Position List</label>
                            <input style={{ width: '510px' }} value={data.component_position_list} onChange={event => handleFormChange(event.target.name, event.target.value)} className={'input_box'} type="text" name="component_position_list" placeholder="IA Component Position List..." />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '21px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <Button variant="outlined-primary" action={handleSave}>
                        <p>Save</p>
                    </Button>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>


            </div>
        )
}

export default EditMappedComponent