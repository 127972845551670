import React, { useState, useEffect, useRef } from 'react'

import XMLUpload from '../../Components/UI-Elements/XMLUpload';
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';
import Divider from '../../Components/UI-Elements/Divider';
import Button from '../../Components/UI-Elements/Button';
import Table from '../../Components/UI-Elements/Table';
import Modal from '../../Components/UI-Elements/Modal';
import DataExport from '../../Components/UI-Elements/DataExport';
import { generateOptions } from '../../Common/Helper';

import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import '../../Styles/Pages/AddPage.css'
import '../../Styles/UI-Elements/InputElement.css'

import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';
import { useAuth } from '../../Components/Auth/AuthProvider'

function AddFredFile() {
  let auth = useAuth();
  
  const [submitLoading, setSubmitLoading] = useState(false)

  const [fredParameters, setFredParameters] = useState(null);
  const [fredFilename, setFredFilename] = useState(null);
  const [availableFreds, setAvailableFreds] = useState(null);

  const [validationError, setValidationError] = useState([])
  const [alert, setAlert] = useState({ type: 'error', text: '' });
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    fetchAvailableFreds()
  }, [])

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  const validateForm = (raw_xml) => {
    return(raw_xml.size > 0 & raw_xml.type === 'text/xml' ? false : true)
  }

  const formatResponse = (responseData) => {
    let parsedParams = [...responseData];
    parsedParams = parsedParams.map(item => {
      let display_arr = []
      if (item.range_text_pair != null ) {
        display_arr = item.range_text_pair.map(({max, min, text}) => text != null ? `text:${text}, min:${min}, max:${max}` : `min:${min}, max:${max}`)
      }
      item['range_text_pair_formatted'] = display_arr
      return (item)
    });
    return(parsedParams)
  }

  async function handleXMLUpload(raw_xml) {
    setSubmitLoading(true)
    const xmlfilename = raw_xml.name.replace(/\.xml$/i, '')
    const validationError = validateForm(raw_xml)
    console.log('Validation Error: ', validationError)
    if (!validationError) {
      try {
        console.log('Start')
        const res = await auth.authAxios({
          method: 'post',
          url: process.env.REACT_APP_BACKEND_API + '/fred/parse/' + xmlfilename,
          data: raw_xml,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET",
            "Content-Type": "text/xml"
          }
        });
  
        const formattedResponse = formatResponse(res.data.data);
        setFredParameters(formattedResponse);
        setFredFilename(xmlfilename);
        fetchAvailableFreds();
        // console.log('response : ', res.data);
        openPopup({ type: 'success', text: 'FRED file parsed successfully' });
      } catch (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
        openPopup({ type: 'error', text: msg })
      } finally {
        setSubmitLoading(false)
        console.log('Finish')
      }
    } else {
      setSubmitLoading(false)
    }
  }

  async function fetchAvailableFreds() {
      console.log("fectching all available FRED files...")
      auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/fred/list')
        .then(res => {
          console.log('FRED files:', res.data.data)
          setAvailableFreds(res.data.data)
        })
        .catch(function (err) {
          const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
          openPopup({ type: 'error', text: msg})
        })
  }

  async function handleFredChange(event) {
    setSubmitLoading(true);
    console.log("fectching specified FRED from GCS...")
    const xmlfilename = event.target.value;
    console.log(xmlfilename);
    await auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/fred/parse/' + xmlfilename)
      .then(res => {
        console.log('FRED files:', res.data.data);
        const formattedResponse = formatResponse(res.data.data);
        setFredParameters(formattedResponse);
        setFredFilename(xmlfilename);
        setSubmitLoading(false);
      })
      .catch(function (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
        openPopup({ type: 'error', text: msg});
        setSubmitLoading(false);
      })
  }

  const listParameterModalOpen = useRef(null)
  const listParameterModalClose = useRef(null)
  const [listParameterModalData, setListParameterModalData] = useState(null)

  const openParameterMapping = (index) => {
    setListParameterModalData(fredParameters[index].range_text_pair)
    listParameterModalOpen.current()
  }

  const closeParameterMapping = () => {
    listParameterModalClose.current()
    setListParameterModalData(null)
  }

  const handleBackdropClose = () => {
    setSubmitLoading(false)
  }

  const Header = [
    { Header: 'Parameter Name', accessor: 'name', canFilter: true },
    { Header: 'Frequency', accessor: 'frequency', canFilter: true },
    { Header: 'Mnemonic', accessor: 'mnemonic', canFilter: true },
    { Header: 'Number of Bits', accessor: 'number_of_bits', canFilter: true },
    { Header: 'Converted Type', accessor: 'converted_type', canFilter: true },
    { Header: 'Data Type', accessor: 'data_type', canFilter: true },
    { Header: 'Offset', accessor: 'offset', canFilter: true },
    { Header: 'Resolution', accessor: 'resolution', canFilter: true },
    { Header: 'Sign Bit', accessor: 'sign_bit', canFilter: true },
    { Header: 'Units', accessor: 'units', canFilter: true },
    { Header: 'Range Text Pair', accessor: 'range_text_pair', canFilter: false, disableSortBy: true, Cell: (row) => {
      const row_ind = row.row.index
      if ( fredParameters[row_ind].range_text_pair != null ) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='list-action' style={{ color: 'var(--color-primary)' }} onClick={() => openParameterMapping(row_ind)}>Range Text Pairs</div>
          </div>
        )
      } else {
        return (<></>)
      }

    }},
  ]

  const ModalHeader = [
    { Header: 'Text', accessor: 'text', canFilter: true },
    { Header: 'Min', accessor: 'min', canFilter: true },
    { Header: 'Max', accessor: 'max', canFilter: true },
  ]

  // const columnMapping = Header.map(({Header, accessor}) => ({[accessor]: Header}))
  const columnMapping = {
    name: 'Parameter Name',
    frequency: 'Frequency', 
    mnemonic: 'Mnemonic',
    number_of_bits: 'Number of Bits',
    converted_type: 'Converted Type',
    data_type: 'Data Type',
    offset: 'Offset',
    resolution: 'Resolution',
    sign_bit: 'Sign Bit',
    units: 'Units',
    range_text_pair_formatted: 'Range Text Pair',
  }

  if (availableFreds != null)
    return (
      <>
        {
          <>
            <div className='add-page-subcontainer'>
              <XMLUpload 
                XMLParseFunction={handleXMLUpload}
              />
            </div>
            <Divider />
          </>
        }

        <div className='add-page-subcontainer'>
          <div className='add-page-entity'>
            <div className='add-page-form'>
              <div className='input-element'>
                <label className='input_label'>Select FRED File</label>
                  <select style={{ width: '250px', justifyContent: 'left' }} className='input_box' value={fredFilename ?? ''} onChange={event => handleFredChange(event)} name="fred_name">
                    <option value="" disabled>Select FRED file...</option>
                      {
                        availableFreds.map((filename, index) => {
                          return (
                            <option key={index} value={filename}>{filename}</option>
                          )
                        })
                      }
                  </select>
              </div>
            </div>
          </div>

          <div className='full-width'>
            <Table
                header={Header}
                data={fredParameters == undefined ? []: fredParameters}
                title='FRED parameters'
                pageSize={DEFAULT_PAGE_SIZE}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <DataExport
              data={fredParameters == undefined ? []: fredParameters}
              columnMapping={columnMapping}
              fileName={ fredFilename != null ? fredFilename + '_export.csv' : '' }
              stringifyObject={true}
              arrayDelimiter='|'
              text='Export Parameters'
            />
          </div>
        </div>
        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>

        <Modal width='xl' modalOpen={listParameterModalOpen} modalClose={listParameterModalClose} title={<div style={{width:'100%',display:'flex',justifyContent:'space-between'}}></div>}>
          <Table
            header={ModalHeader}
            data={listParameterModalData == undefined ? []: listParameterModalData}
            title='Range text pairs'
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </Modal>
        <SimpleBackdrop loading={submitLoading} handleBackdropClose={handleBackdropClose}/>
      </>
    )
  else
    return (
      <>
        <Skeleton variant="rectangular" width='100%' height='200px' />
        <Divider />
        <Skeleton variant="rectangular" width='100%' height='200px' />
      </>
    )
}

export default AddFredFile