import MenuItem from '@mui/material/MenuItem';
// options for Manufacturer selection dropdown

export const Manufacturers = ['Boeing', 'Airbus'];

// Dropdown Manufacturer options
export const ManufacturerOptions = Manufacturers.map((m, index) => {
    return (<MenuItem key={index} value={m}>{m}</MenuItem>)
});

export default ManufacturerOptions;
