import React, { useState, useEffect, useRef } from 'react'
import Modal from '../../Components/UI-Elements/Modal';
import Table from '../../Components/UI-Elements/Table'
import Button from '../../Components/UI-Elements/Button'
import DataExport from '../../Components/UI-Elements/DataExport';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import '../../Styles/Pages/ListPage.css'
import EditComponent from './EditComponent';

import { useAuth } from '../../Components/Auth/AuthProvider'
import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';
import { generateOptions } from '../../Common/Helper';

function ListComponent() {
  let auth = useAuth();

  const modalOpen = React.useRef(null)
  const modalClose = React.useRef(null)
  const [modalData, setModalData] = useState(null)
  const [data, setData] = useState([])

  const [alert, setAlert] = useState({ type: 'error', text: '' })
  const [popup, setPopup] = useState(false)

  const [tableData, setTableData] = useState([])
  const [airplaneOptions, setAirplaneOptions] = useState(null);
  const [ataCodeOptions, setAtaCodeOptions] = useState(null);

  const [customerData, setCustomerData] = useState(null);
  const [customerFilter, setCustomerFilter] = useState(null);

  const [customFileName, setCustomFileName] = useState(null);
  
  const handleModalOpen = (index) => {
    setModalData({ ...data[index] })
    modalOpen.current()
  }

  const handleModalClose = (index) => {
    setModalData("")
    modalClose.current()
  }

  const fileExportDate = () => { 
    const today = new Date(); 
    const day = String(today.getDate()).padStart(2, '0'); 
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const year = today.getFullYear(); 
   
    const formattedDate = `${day}${month}${year}`; 
    return formattedDate; 
  }

  useEffect(() => {
    fetchCustomers()
    fetchAllComponents()
    fetchAllAirplanes()
    fetchSettings()
  }, [])

  useEffect(()=>{ 
    const customer=customerData && customerData.find((customer)=>customer.customer_id === customerFilter) 
    const filename_date=fileExportDate(); 
    if (customer) { 
      setCustomFileName(`${customer.gcs_name.split(' ').join('_')}_${filename_date}`.toLowerCase()+"_ComponentExport.csv") 
    } 
    else{ 
      setCustomFileName(`all_customers_${filename_date}`.toLowerCase()+"_ComponentExport.csv") 
    } 
  },[customerFilter])

  async function fetchAllComponents() {
    console.log("fectching all components...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/component')
      .then(res => {
        let temp = [...res.data.data];
        temp.map((p) => {
          p.gcs_names_temp= p.gcs_names ?? 'Non Assign';
        });
        setData(temp)
      })
      .catch(function (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
        openPopup({ type: 'error', text: msg })
      })
  }

  async function fetchCustomers() {
    console.log("fectching customer data...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-customer')
      .then(res => {
        // console.log(res.data.data)
        let data = []
        res.data.data.forEach(element => {
          data.push({
            'customer_id': element.id,
            'gcs_name': element.gcs_name,
            'email': element.contact_email
          })
        })
        console.log('customer data', data)
        setCustomerData(data)
      })
      .catch(function (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
        openPopup({ type: 'error', text: msg })
      })
  }

  function fetchAllAirplanes() {
    console.log("fectching airplane data...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-airplane')
        .then(res => {
            // console.log(res.data.data)
            let temp = res.data.data
            let final = {}

            temp.forEach(element => {
                if (final[element.model]) {
                    final[element.model].push({ 'submodel': element.submodel, 'id': element.id })
                }
                else {
                    final[element.model] = [{ 'submodel': element.submodel, 'id': element.id }]
                }
            });
            console.log('airplaneData', final)

            setAirplaneOptions(generateOptions(Object.keys(final), 'string'));
        })
        .catch(function (err) {
          const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
          openPopup({ type: 'error', text: msg })
        })
  }

  async function fetchSettings() {
    console.log("fetching settings data...");
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/settings')
      .then((res) => {
        setAtaCodeOptions(generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'ATA Codes')?.value), 'json'));
      })
      .catch(function (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
        openPopup({ type: 'error', text: msg })
      })
  }

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  useEffect(() => {
    let temp = [...data].map((value, index) => {
      value["actions"] = <div style={{ display: 'flex',justifyContent:'space-around'  }}>
        <div className='list-action' onClick={() => handleModalOpen(index)}><span className="material-icons">launch</span></div>
      </div>
      return value
    })
    console.log('table data', temp)
    setTableData(temp)
  }, [data])

  const deleteModalOpen = useRef(null)
  const deletemodalClose = useRef(null)
  const [deleteModalData, setDeleteModalData] = useState(null)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const Header = [
    { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true },
    { Header: 'Component Name', accessor: 'component_name', canFilter: true },
    { Header: 'Part No.', accessor: 'part_no', canFilter: true },
    { Header: 'Airplane', accessor: 'airplane', canFilter: true },
    { Header: 'Component Position List', accessor: 'component_position_list', Cell: ({ cell: { value } }) => { return value.toString() }, canFilter: true },
    { Header: 'ATA Code', accessor: 'ata_code', canFilter: true },
    { Header: 'GCS Names', accessor: 'gcs_names_temp',Cell: ({ cell: { value } }) => { return value && value.toString() }, canFilter: true },
  ]

  const HeaderWithSpecifiedCustomer = [
    ...Header,
    { Header: 'Problems', accessor: 'customers', Cell: ({ cell: { value } }) => {  return value && value[customerFilter] ? value[customerFilter].toString() : null }, canFilter: true }
  ]

  if (customerData) {
    var CustomerOptions = customerData.map((customer, index) => {
      return (<option key={index} value={parseInt(customer.customer_id)}>{customer.gcs_name}</option>)
    })
  }

  if (!tableData || !CustomerOptions || !airplaneOptions || !ataCodeOptions) {
    return (
      <>
        <div className='list-page-subcontainer' style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading....</p></div>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
      </>
    )
  }
  else {
    return (
      <>
        <div className='list-page-subcontainer'>
          <Table
            header={customerFilter ? HeaderWithSpecifiedCustomer : Header}
            data={customerFilter? tableData.filter((c) => customerFilter==10 ? !c.customers : c.customers && c.customers[customerFilter]) : tableData}
            title='Component'
            pageSize={DEFAULT_PAGE_SIZE}
            customContent={
              <div className='input-element'>
                <label className='input_label'>Customer</label>
                <select style={{ width: '250px' }} className='input_box' value={customerFilter || ''} onChange={event => setCustomerFilter(parseInt(event.target.value))} name="customer_id">
                  <option value="">Filter by Customer...</option>
                  {CustomerOptions}
                </select>
              </div>
            }
          />
          <Modal modalOpen={modalOpen} modalClose={modalClose} title="Edit Component">
            <EditComponent
              data={modalData}
              modalClose={handleModalClose}
              refresh={fetchAllComponents}
              alert={openPopup}
              airplaneOptions={airplaneOptions}
              ataCodeOptions={ataCodeOptions}
            />
          </Modal>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <DataExport
              data={customerFilter? data.filter((c) => customerFilter==10 ? !c.customers : c.customers && c.customers[customerFilter]) : data}
              columnMapping={{
                component_name: 'Component Name',
                part_no: 'Part Number',
                airplane: 'Airplane',
                component_position_list: 'Component Position List',
                ata_code: 'ATA Code',
                // component_event_tag_rules: 'Event Tag Rules'
                gcs_names_temp:'GCS Names'
              }}
              fileName={customFileName}
              stringifyObject={true}
              arrayDelimiter='|'
              text='Export Components'
            />
          </div>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
      </>
    )
  }
}

export default ListComponent