import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// add Route information whenever creating a new page or route

export const BreadcrumbData = {
  '/' : {
    'title' : 'Dashboard',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Dashboard</p>
                    </Breadcrumbs>
  },
  '/parameter/add' : {
    'title' : 'Add Parameters',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Parameter</p>
                        <p>Add Parameter</p>
                    </Breadcrumbs>
  },
  '/parameter/list' : {
    'title' : 'List Parameters',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Parameter</p>
                        <p>List Parameter</p>
                    </Breadcrumbs>
  },
  '/component/add' : {
    'title' : 'Add Components',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Component</p>
                        <p>Add Component</p>
                    </Breadcrumbs>
  },
  '/component/list' : {
    'title' : 'List Components',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Component</p>
                        <p>List Component</p>
                    </Breadcrumbs>
  },
  '/problem/parameter-mapping' : {
    'title' : 'Problem Parameter Mapping',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Problem</p>
                        <p>Add Parameter Mapping</p>
                    </Breadcrumbs>
  },
  '/problem/component-mapping' : {
    'title' : 'Problem Component Mapping',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Problem</p>
                        <p>Add Component Mapping</p>
                    </Breadcrumbs>
  },
  '/problem/add' : {
    'title' : 'Generate Problem',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Problem</p>
                        <p>Add Problem</p>
                    </Breadcrumbs>
  },
  '/problem/list' : {
    'title' : 'List Problems',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Problem</p>
                        <p>List Problems</p>
                    </Breadcrumbs>
  },
  '/config/generate' : {
    'title' : 'Generate Config',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Config</p>
                        <p>Generate Config Files</p>
                    </Breadcrumbs>
  },
  '/config/list' : {
    'title' : 'List Config',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Config</p>
                        <p>List Generated Configs</p>
                    </Breadcrumbs>
  },
  '/dataframe/import-dataframes' : {
    'title' : 'Import Dataframes',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Dataframe</p>
                        <p>Import Dataframes</p>
                    </Breadcrumbs>
  },
  '/dataframe/customer-mapping' : {
    'title' : 'Customer Mapping',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Dataframe</p>
                        <p>Customer Mapping</p>
                    </Breadcrumbs>
  },
  '/misc/airplane' : {
    'title' : 'Airplanes',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Configure Airplanes</p>
                    </Breadcrumbs>
  },
  '/misc/customers' : {
    'title' : 'Customers',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Configure Customers</p>
                    </Breadcrumbs>
  },
  '/misc/add-fred-file' : {
    'title' : 'Add FRED Files',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Add FRED Files</p>
                    </Breadcrumbs>
  },
  '/misc/logs' : {
    'title' : 'Logs',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Logs</p>
                    </Breadcrumbs>
  },
  '/misc/settings' : {
    'title' : 'Settings',
    'breadcrumb' : <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <p>Home</p>
                        <p>Settings</p>
                    </Breadcrumbs>
  },
}