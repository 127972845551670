import React, { useState, useEffect } from 'react'

import Button from '../../Components/UI-Elements/Button'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import '../../Styles/Pages/AddPage.css'
import '../../Styles/UI-Elements/InputElement.css'

import { useAuth } from '../../Components/Auth/AuthProvider'
import { generateOptions } from '../../Common/Helper';

function AddProblem() {
    const problemTemplate = {
        problem_name: '',
        customer_id: '',
        airplane_model: '',
        airplane_submodel: [],
        ata_code: [],
        version: process.env.REACT_APP_TOOL_VERSION,
    };
    let auth = useAuth();

    const [data, setData] = useState(problemTemplate)
    const [customerData, setCustomerData] = useState(null)
    const [airplaneData, setAirplaneData] = useState(null)
    const [ataCodeOptions, setAtaCodeOptions] = useState([]);

    const handleFormChange = (event) => {
        let temp = { ...data };
        temp[event.target.name] = event.target.value;
        setData(temp);
    }

    const getSelectedAirplanes = (event) => {
        let temp = { ...data };
        temp.airplane_model = event.target.value;
        setData(temp);
    }
    
    const getSelectedSubmodels = (event) => {
        let temp = {...data };
        temp.airplane_submodel = event.target.value;
        setData(temp);
    }

    useEffect(() => {
        fetchCustomers()
        fetchAllAirplanes()
        fetchSettings()
    }, [])

    async function fetchAllAirplanes() {
        console.log("fectching airplane data...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-airplane')
            .then(res => {
                // console.log(res.data.data)
                let temp = res.data.data
                let final = {}

                temp.forEach(element => {
                    if (final[element.model]) {
                        final[element.model].push({ 'submodel': element.submodel, 'submodel_id': element.submodel_id })
                    }
                    else {
                        final[element.model] = [{ 'submodel': element.submodel, 'submodel_id': element.submodel_id }]
                    }
                });
                console.log('airplaneData', final)
                setAirplaneData(final);
            })
            .catch(function (error) {
                const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
                openPopup({ type: 'error', text: msg})
            })
    }

    const fetchCustomers = () => {
        console.log("fectching customer data...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-customer')
            .then(res => {
                // console.log(res.data.data)
                let data = []
                res.data.data.forEach(element => {
                    data.push({
                        'customer_id': element.id,
                        'gcs_name': element.gcs_name,
                        'email': element.contact_email
                    })
                })
                console.log('customer data', data)
                setCustomerData(data)
            })
            .catch(function (error) {
                const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
                openPopup({ type: 'error', text: msg})
            })
    }

    async function fetchSettings() {
        console.log("fetching settings data...");
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/settings')
        .then((res) => {
            setAtaCodeOptions(generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'ATA Codes')?.value), 'json'));
        })
        .catch(function (error) {
            const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
            openPopup({ type: 'error', text: msg})
        })
    }

    if (customerData) {
        var CustomerOptions = customerData.map((customer, index) => {
            return (<option key={index} value={customer.customer_id}>{customer.gcs_name}</option>)
        })
    }

    if (airplaneData) {
        let airplaneKeys = Object.keys(airplaneData)

        var airplaneModelOptions = [...airplaneKeys].map((item, index) => {
            return (<MenuItem key={index} value={item}>{item}</MenuItem>)
        })
    }

    if(airplaneModelOptions && airplaneModelOptions.length && data && data.airplane_model && data.airplane_model) {
        var airplaneSubmodelOptions = [];
        let submodels = data.airplane_model && airplaneData[data.airplane_model].length ? [...airplaneData[data.airplane_model]] : [];
        if (submodels.length) {
            submodels.map((item, _index) => {
                airplaneSubmodelOptions.push(<MenuItem key={item.submodel_id} value={item.submodel}>{item.submodel}</MenuItem>)
            })
        }
    }

    const removeAllFields = () => {
        setData(problemTemplate)
    }

    const [submitLoading, setSubmitLoading] = useState(false)

    const handleSubmit = (e) => {
        setSubmitLoading(true)
        e.preventDefault();
        console.log("submitted data", data)
        submit()
    }

    async function submit() {
        console.log("submit")
        let validateError = validateForm()
        console.log('validation error : ', validateError)

        if (!validateError) {
            let submodel_ids = [];
            for(var ind in airplaneData[data['airplane_model']]) {
                if (data['airplane_submodel'].includes(airplaneData[data['airplane_model']][ind]['submodel'])) {
                    submodel_ids.push(airplaneData[data['airplane_model']][ind]['submodel_id'])
                }
            }

            var req = {
                'problem_name': data['problem_name'],
                'customer_id': data['customer_id'],
                'ata_code': data['ata_code'].join(','),
                'submodel_ids': submodel_ids,
                'version': data['version'],
                'user':auth.name
            }

            console.log('req', req)

            try {
                const res = await auth.authAxios({
                    method: 'post',
                    url: process.env.REACT_APP_BACKEND_API + '/problem',
                    data: req,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                    }
                });

                console.log('response : ', res);
                if (res.status === 200) {
                    removeAllFields()
                    openPopup({ type: 'success', text: 'Success!! Problem Added Successfully' })
                }
            } catch (err) {
                const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
                openPopup({ type: 'error', text: msg })
            }
        }

        setSubmitLoading(false)
    }

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const [validationError, setValidationError] = useState(false)

    const validateForm = () => {
        console.log("validating data...")
        if (data['problem_name'] == '' || data['customer_id'] == '' || data['airplane_model'] == '' || data['airplane_submodel'].length == 0) {
            setValidationError(true)
            openPopup({ type: 'error', text: 'Error!! Check Problem Fields' })
            return true;
        }
        else {
            setValidationError(false)
        }

        return false
    }

    if (customerData && airplaneData)
        return (
            <>
                <div className='add-page-subcontainer'>
                    <div className='full-width'>
                        <p className='table-title'>Problem Details</p>
                        <div className='add-page-entity'>
                            <div className='add-page-form'>
                                <div className='input-element'>
                                    <label className='input_label'>Problem Name*</label>
                                    <input style={{ width: '200px' }} value={data.problem_name} onChange={event => handleFormChange(event)} className={validationError && data.problem_name == '' ? 'input_box_error' : 'input_box'} type="text" name="problem_name" placeholder="Enter Problem Name..." />
                                </div>
                                <div className='input-element'>
                                    <label className='input_label'>GCS Name*</label>
                                    <select style={{ width: '250px' }} className={validationError && data.customer_id == '' ? 'input_box_error' : 'input_box'} value={data.customer_id} onChange={event => handleFormChange(event)} name="customer_id">
                                        <option value="" disabled>Select Customer...</option>
                                        {CustomerOptions}
                                    </select>
                                </div>
                                <div className='input-element'>
                                    <label className='input_label'>ATA Code</label>
                                    <Select
                                        name="ata_code"
                                        className={'input_box'}
                                        style={{ width: '250px ', height: '40px' }}
                                        size='small'
                                        placeholder="Select ATA Code..."
                                        value={data.ata_code || ''}
                                        options={ataCodeOptions}
                                        onChange={(e) => handleFormChange(e)}
                                        multiple
                                    >
                                        {ataCodeOptions}
                                    </Select>
                                </div>
                                <div className='input-element'>
                                  <label className='input_label'>Airplane Model*</label>
                                  <Select
                                    name="airplane_model"
                                    className={validationError && data.airplane_model == '' ? 'input_box_error' : 'input_box'}
                                    style={{ width: '220px ', height: '40px' }}
                                    size='small'
                                    placeholder="Select Airplane Model..."
                                    value={data.airplane_model}
                                    options={airplaneModelOptions}
                                    onChange={(e) => getSelectedAirplanes(e)}
                                  >
                                    {airplaneModelOptions}
                                  </Select>
                                </div>
                                <div className='input-element'>
                                  <label className='input_label'>Airplane Sub-Model*</label>
                                    <Select
                                      name="airplane_submodel"
                                      className={validationError && data.airplane_submodel == '' ? 'input_box_error' : 'input_box'}
                                      style={{ width: '220px ', height: '40px' }}
                                      size='small'
                                      placeholder="Select Airplane Model..."
                                      value={data.airplane_submodel}
                                      options={airplaneSubmodelOptions}
                                      onChange={(e) => getSelectedSubmodels(e)}
                                      multiple
                                    >
                                      {airplaneSubmodelOptions}
                                    </Select>
                                </div>
                                <div className='input-element'>
                                    <label className='input_label'>Version</label>
                                    <input style={{ width: '75px' }} value={data.version} className={data.version == '' ? 'input_box_error' : 'input_box'} type="text" name="version" disabled />
                                </div>
                            </div>
                        </div>

                        <div className='add-page-button-group'>
                            <div style={{ display: 'flex' }}>
                                <Button variant="filled-primary" action={handleSubmit}>
                                    {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Submit</p>}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
            </>
        )
    else
    return(<><Skeleton variant="rectangular" width='100%' height='300px' /></>)
}

export default AddProblem