import React, { useState, useEffect } from 'react'
import Button from '../../Components/UI-Elements/Button'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { useAuth } from '../../Components/Auth/AuthProvider'

function EditParameter(props) {
  let auth = useAuth();

  const [data, setData] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [airplaneData, setAirplaneData] = useState(null);

  const [alert, setAlert] = useState({ type: 'error', text: '' });
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    fetchAllAirplanes()
    let temp = {...props.data};
    temp.ata_code = temp.ata_code ? temp.ata_code.split(','): [];
    temp.flight_phases = props.customerFilter && temp.customers[props.customerFilter] ? Object.keys(temp.customers[props.customerFilter]) : [];
    setData(temp)
  }, [])

  function fetchAllAirplanes() {
    console.log("fectching airplane data...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-airplane')
        .then(res => {
            // console.log(res.data.data)
            let temp = res.data.data
            let final = {}

            temp.forEach(element => {
                if (final[element.model]) {
                    final[element.model].push({ 'submodel': element.submodel, 'id': element.id })
                }
                else {
                    final[element.model] = [{ 'submodel': element.submodel, 'id': element.id }]
                }
            });
            console.log('airplaneData', final)
            setAirplaneData(final);
        })
        .catch(function (err) {
          const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
          openPopup({ type: 'error', text: msg})
        })
  }

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  const handleFormChange = (event) => {
    let temp = { ...data };
    temp[event.target.name] = event.target.value;
    setData(temp);
  }

  const handleSubmit = () => {
    setSubmitLoading(true)
    console.log("submitted data", data)
    submit()
  }

  async function submit() {
    console.log("submit")
    let validateError = validateForm()
    console.log('validation error : ', validateError)

    if (!validateError) {

      var req = {
        'parameter_desc': data['parameter_desc'],
        'boeing_mnemonic_code': data['boeing_mnemonic_code'],
        'airplane': data['airplane'],
        'fda_parameter_name': data['fda_name'].toUpperCase(),
        'ata_code': data['ata_code'].join(','),
        'ia_sensor_name': data['ia_sensor_name'].toUpperCase(),
        'additional_info':data['additional_info'],
        'aggregators':data['aggregators'],
      }

      if (props.customerFilter) {
        req['flight_phases'] = data.flight_phases;
      }

      console.log('request : ', req)

      var fin_req = {
        'parameters':[req],
        'user':auth.name
      }

      const url = props.customerFilter ?
                process.env.REACT_APP_BACKEND_API + '/parameter/' + data['parameter_id'] + '/' + props.customerFilter :
                process.env.REACT_APP_BACKEND_API + '/parameter/' + data['parameter_id'];

      try {
        const res = await auth.authAxios({
          method: 'put',
          url,
          data: fin_req,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
          }
        });

        console.log('response : ', res.data);
        props.modalClose()
        props.refresh()
        props.alert({ type: 'success', text: 'Parameter Updated Successfully' })

      } catch (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
        openPopup({ type: 'error', text: msg })
      } finally {
        setSubmitLoading(false)
      }
    }
    else {
      setSubmitLoading(false)
    }

  }

  function validateForm() {
    let res = false;
    console.log("validating data...");

    if (data['fda_name'] == '' || 
      data['airplane'] == '' ||
      data['boeing_mnemonic_code'] == '' ||
      data['ia_sensor_name'] == '') {
      openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
      res = true
    }

    return res;
  }

  if (airplaneData) {
    let airplaneKeys = Object.keys(airplaneData)

    var airplaneModelOptions = [...airplaneKeys].map((item, index) => {
        return (<MenuItem key={index} value={item}>{item}</MenuItem>)
    })
  }

  if (data)
    return (
      <div>
        <div className='add-page-entity'>
          <div className='add-page-form'>
            <div className='input-element'>
              <label className='input_label'>FDA Name *</label>
              <input style={{ width: '250px' }} className={data.fda_name == '' ? 'input_box_error' : 'input_box'} value={data.fda_name} onChange={event => handleFormChange(event)} type="text" name="fda_name" placeholder="Enter FDA Name..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Boeing Mnemonic Code*</label>
              <input style={{ width: '250px' }} value={data.boeing_mnemonic_code} onChange={event => handleFormChange(event)} className='input_box' type="text" name="boeing_mnemonic_code" placeholder="Enter Boeing mnemonic code..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Parameter Description</label>
              <input style={{ width: '250px' }} value={data.parameter_desc} onChange={event => handleFormChange(event)} className='input_box' type="text" name="parameter_desc" placeholder="Enter Parameter Description..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Airplane Model*</label>
              <Select
                name="airplane"
                className={data.airplane == '' ? 'input_box_error' : 'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select Airplane Model..."
                value={data.airplane || ''}
                options={airplaneModelOptions}
                onChange={event => handleFormChange(event)}
              >
                {airplaneModelOptions}
              </Select>
            </div>
            <div className='input-element'>
              <label className='input_label'>ATA Code</label>
              <Select
                name="ata_code"
                className={'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select ATA Code..."
                value={data.ata_code || []}
                options={props.ataCodeOptions}
                onChange={(e) => handleFormChange(e)}
                multiple
              >
                {props.ataCodeOptions}
              </Select>
            </div>
            <div className='input-element'>
              <label className='input_label'>IA Sensor Name *</label>
              <input style={{ width: '250px' }} value={data.ia_sensor_name} onChange={event => handleFormChange(event)} className={data.ia_sensor_name == '' ? 'input_box_error' : 'input_box'} type="text" name="ia_sensor_name" placeholder="Enter IA Sensor Name..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Aggregators</label>
              <Select
                name="aggregators"
                className={'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select Aggregators..."
                value={data.aggregators || []}
                options={props.aggregatorOptions}
                onChange={(e) => handleFormChange(e)}
                multiple
              >
                {props.aggregatorOptions}
              </Select>
            </div>
            {
              props.customerFilter &&
              <div className='input-element'>
                <label className='input_label'>Flight Phases</label>
                <Select
                  name="flight_phases"
                  className={'input_box'}
                  style={{ width: '250px ', height: '40px' }}
                  size='small'
                  placeholder="Select Flight Phases..."
                  value={data.flight_phases || []}
                  options={props.flightPhaseOptions}
                  onChange={(e) => handleFormChange(e)}
                  multiple
                >
                  {props.flightPhaseOptions}
                </Select>
              </div>
            }
            <div className='input-element'>
              <label className='input_label'>Additional Information</label>
              <textarea style={{ width: '510px',resize: 'vertical'  }} value={data.additional_info || ''} onChange={event => handleFormChange(event)} className='input_box' type="text" name="additional_info" placeholder="Enter Additional Information..." />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '21px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <Button variant="filled-primary" action={() => { handleSubmit() }}>
            {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Submit</p>}
          </Button>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>

      </div>
    )
  else
    return null
}

export default EditParameter