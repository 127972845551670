import React, { useState, useEffect, useRef } from 'react';

import FileUpload from '../../Components/UI-Elements/FileUpload';
import Divider from '../../Components/UI-Elements/Divider';
import Button from '../../Components/UI-Elements/Button';
import Checkbox from '../../Components/UI-Elements/Checkbox';
import Table from '../../Components/UI-Elements/Table';
import Modal from '../../Components/UI-Elements/Modal';


import '../../Styles/UI-Elements/Checkbox.css';
import { generateOptions } from '../../Common/Helper';
import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';

import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import '../../Styles/Pages/AddPage.css';
import '../../Styles/UI-Elements/InputElement.css';

import { useAuth } from '../../Components/Auth/AuthProvider';
import { ConstructionOutlined, ImportExportRounded, ResetTv } from '@mui/icons-material';
import ListDataframeMappings from '../../Components/Mapping/ListDataframeMappings';

function ImportDataframes() {
  let auth = useAuth();

  const [dataframeInfo, setDataframeInfo] = useState(undefined)
  const [selectedDataframes, setSelectedDataframes] = useState(null)

  const [validationError, setValidationError] = useState([])
  const [alert, setAlert] = useState({ type: 'error', text: '' });
  const [popup, setPopup] = useState(false);
  const importModalOpen = useRef(null)
  const importModalClose = useRef(null)

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  const handleModalClose = () => importModalClose.current()
  const handleModalOpen = () => importModalOpen.current()

  async function fetchAllDataframes() {
    console.log("fetching dataframe names...");
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/dataframes' + '?include_gcs=true')
    .then(res => {
      // Build dataframe info
      const allDataframes = res.data.data.map((element, index) => {return {
          id: index,
          dataframe_id: element.id,
          gcs_name: element.gcs_name,
          file: element.file,
          imported: element.imported,
          enabled: element.enabled
        };
      });

      const initializeSelected = {};
      allDataframes.forEach(item => {
        initializeSelected[item.id] = false
      });
      setSelectedDataframes(initializeSelected);
      setDataframeInfo(allDataframes);
    });
  }

  useEffect(() => {
    fetchAllDataframes()
  }, [])

  function handleCheckboxSelect(event) {
    const checkedId = event.target.id.split('_')[1];
    let currentSelections = {...selectedDataframes};

    if(event.target.checked) {
      currentSelections[checkedId] = true
    }
    else {
      currentSelections[checkedId] = false
    }

    setSelectedDataframes(currentSelections)
  }

  function handleUpdateDataframeInfo(resp) {
    const tempDataframeInfo = [...dataframeInfo];
    const currentSelections = {...selectedDataframes};

    tempDataframeInfo.map((element, ind) => {
      const createdDfId = resp.find(e => e.file == element.file);

      if (currentSelections[element.id] == true && createdDfId != undefined && element.dataframe_id == null) {
        element.dataframe_id = createdDfId.dataframe_id;
        element.imported = true;
      };
      // We always want to deselect dataframes after the "Import Datarames" button is clicked
      currentSelections[element.id] = false;
      
    });
    
    setSelectedDataframes(currentSelections);
    setDataframeInfo(tempDataframeInfo);
  }

  async function handleImports() {

    // Filter dataframes on only selected options
    const selections = dataframeInfo.filter(item => selectedDataframes[item.id] === true);
    handleModalOpen();
    
    let fin_req = {
      'dataframes': selections,
      'user': auth.name
    }
    
    console.log(fin_req)
    try {
      const res = await auth.authAxios({
        method: 'POST',
        url: process.env.REACT_APP_BACKEND_API + '/import-dataframes',
        data: fin_req,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,PATCH,OPTIONS",
        }
      });
      console.log('response : ', res.data);

      handleUpdateDataframeInfo(res.data.dataframes_imported);
      handleModalClose();
      openPopup({ type: 'success', text: 'Dataframes Imported Successfully!!' })

    } catch (err) {
      const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
      openPopup({ type: 'error', text: msg});
    }
  }

  const listParameterModalOpen = useRef(null)
  const listParameterModalClose = useRef(null)
  const [listParameterModalData, setListParameterModalData] = useState(null)
  
  const openParameterMapping = (index) => {
    setListParameterModalData(dataframeInfo[index])
    listParameterModalOpen.current()
  }

  const closeParameterMapping = () => {
    listParameterModalClose.current()
    setListParameterModalData(null)
  }

  const Header = [
    { Header: 'Select', accessor: 'checkbox', canFilter: false, disableSortBy: true, Cell: (row) => {
      const row_ind = row.row.index
      return (
        <Checkbox 
          id={ dataframeInfo[row_ind].id } 
          name={ dataframeInfo[row_ind].file } 
          checked={ selectedDataframes[row_ind] === true ? "checked": "" } 
          onChange={ (event) => handleCheckboxSelect(event) } 
        />
      )
    }},
    { Header: 'Dataframe ID', accessor: 'dataframe_id', canFilter: true },
    { Header: 'Filename', accessor: 'file', canFilter: true },
    { Header: 'GCS Name', accessor: 'gcs_name', canFilter: true },
    { Header: 'Imported', id: 'imported', accessor: d => d.imported != null ? d.imported.toString(): '', canFilter: true },
    { Header: 'Enabled', id: 'enabled', accessor: d => d.enabled != null ? d.enabled.toString(): '', canFilter: true },
    { Header: 'Mappings', accessor: 'mappings', canFilter: false, disableSortBy: true, Cell: (row) => {
      const row_ind = row.row.index
      if ( dataframeInfo[row_ind].dataframe_id != null ) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='list-action' style={{ color: 'var(--color-primary)' }} onClick={() => openParameterMapping(row_ind)}>Parameters</div>
          </div>
        )
      } else {
        return (<></>)
      }

    }},
  ]

  
  if (dataframeInfo) {
    return (
        <>
          <div className='add-page-subcontainer'>
              <>
                <div className='list-page-subcontainer'>
                  <Table header={ Header } data={ dataframeInfo } title='Available Dataframes' pageSize={DEFAULT_PAGE_SIZE}/>
                </div>
              </>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="outlined-primary" action={(event) => handleImports(event)}>
                  <span className="material-icons-outlined" style={{ marginRight: '5px' }}>add</span><p>Import Dataframes</p>
                </Button>
              </div>
          </div>

          <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
            <Alert severity={alert['type']} sx={{ width: '100%' }}>
              {alert['text']}
            </Alert>
          </Snackbar>

          <Modal width='xl' modalOpen={listParameterModalOpen} modalClose={listParameterModalClose} title={<div style={{width:'100%',display:'flex',justifyContent:'space-between'}}><div>Parameter Mappings</div></div>}>
            <ListDataframeMappings problem_data={listParameterModalData}/>
          </Modal>
          
          <Modal 
            width='xl' 
            modalOpen={importModalOpen} 
            modalClose={importModalClose} 
            title={
              <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
                <div>Importing dataframes</div>
              </div>
            }
            >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span>
              <p>Please Wait...</p>
            </div>
          </Modal>

        </>
    )}
  else {
    return (
      <>
        <div className='list-page-subcontainer' style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span>
              <p>Loading....</p>
          </div>
        </div>
      </>
    )
  }
}

export default ImportDataframes;
