import React, { useState, useEffect } from 'react'

import FileUpload from '../../Components/UI-Elements/FileUpload'
import Divider from '../../Components/UI-Elements/Divider'
import Button from '../../Components/UI-Elements/Button'
import { generateOptions } from '../../Common/Helper';

import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import '../../Styles/Pages/AddPage.css'
import '../../Styles/UI-Elements/InputElement.css'

import { useAuth } from '../../Components/Auth/AuthProvider'

function AddParameter() {
  let auth = useAuth();

  const parameterTemplate = {
    parameter_desc: '',
    fda_name: '',
    airplane: '',
    boeing_mnemonic_code: '',
    ata_code: [],
    ia_sensor_name: '',
    aggregators: [],
    flight_phases: [],
    additional_info:''
  };

  const [inputFields, setInputFields] = useState([parameterTemplate])
  
  const [submitLoading, setSubmitLoading] = useState(false)
  const [parsedData, setParsedData] = useState(null);
  const [airplaneData, setAirplaneData] = useState(null);

  const [flightPhaseOptions, setFlightPhaseOptions] = useState([]);
  const [aggregatorOptions, setAggregatorOptions] = useState([]);
  const [ataCodeOptions, setAtaCodeOptions] = useState([]);

  const [configuredParameters, setConfiguredParameters] = useState(null)

  const [validationError, setValidationError] = useState([])
  const [alert, setAlert] = useState({ type: 'error', text: '' });
  const [popup, setPopup] = useState(false);

  async function fetchAllAirplanes() {
    console.log("fectching airplane data...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-airplane')
        .then(res => {
            // console.log(res.data.data)
            let temp = res.data.data
            let final = {}

            temp.forEach(element => {
                if (final[element.model]) {
                    final[element.model].push({ 'submodel': element.submodel, 'id': element.id })
                }
                else {
                    final[element.model] = [{ 'submodel': element.submodel, 'id': element.id }]
                }
            });
            console.log('airplaneData', final)
            setAirplaneData(final);
        })
        .catch(function (err) {
          const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
          openPopup({ type: 'error', text: msg})
        })
  }

  async function fetchSettings() {
    console.log("fetching settings data...");
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/settings')
      .then((res) => {
        setAtaCodeOptions(generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'ATA Codes')?.value), 'json'));
        setFlightPhaseOptions(generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'Flight Phases')?.value), 'json'));
        setAggregatorOptions(generateOptions(res.data.data.find((s) => s.name === 'Aggregators')?.value?.split(','), 'string'));
      })
      .catch(function (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
        openPopup({ type: 'error', text: msg})
      })
  }

  useEffect(() => {
    fetchAllAirplanes()
    fetchSettings()
  }, [])

  useEffect(() => {
    if (!parsedData)
      return;

    let temp = parsedData.map((d) => {
      return {
        parameter_desc: d['Parameter Description'],
        fda_name: d['FDA Name'],
        airplane: d['Airplane'],
        boeing_mnemonic_code: d['Boeing Mnemonic Code'],
        ata_code: d['ATA Code'] ? d['ATA Code'].split('|') : [],
        ia_sensor_name: d['IA Sensor Name'],
        aggregators: d['Aggregators'] ? d['Aggregators'].split('|') : [],
        flight_phases: d['Flight Phases'] ? d['Flight Phases'].split('|') : [],
        additional_info:''
      };
    });
    console.log('Parsed parameter data', temp)
    setInputFields(temp)
  }, [parsedData])


  if (airplaneData) {
    let airplaneKeys = Object.keys(airplaneData)

    var airplaneModelOptions = [...airplaneKeys].map((item, index) => {
        return (<MenuItem key={index} value={item}>{item}</MenuItem>)
    })
  }

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  }

  const addFields = () => {
    setInputFields([...inputFields, parameterTemplate])
  }

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1)
    setInputFields(data)
  }

  const removeAllFields = () => {
    setInputFields([parameterTemplate])
  }

  const handleSubmit = (e) => {
    setSubmitLoading(true)
    e.preventDefault();
    console.log("submitted data", inputFields)
    submit()
  }

  async function submit() {
    console.log("submit")
    let validateError = validateForm()
    console.log('validation error : ', validateError)

    if (!validateError) {

      let temp = [...inputFields]

      console.log("no error")

      var req = [...temp].map((item) => {
        return {
          'parameter_desc': item['parameter_desc'],
          'airplane': item['airplane'],
          'boeing_mnemonic_code': item['boeing_mnemonic_code'],
          'fda_parameter_name': item['fda_name'].toUpperCase(),
          'ata_code': item['ata_code'].join(','),
          'ia_sensor_name': item['ia_sensor_name'].toUpperCase(),
          'additional_info':item['additional_info'],
          'aggregators':item['aggregators'],
          'flight_phases':item['flight_phases']
        }
      })

      // console.log('request : ', req)

      var fin_req = {
        'parameters':req,
        'user':auth.name
      }

      try {
        const res = await auth.authAxios({
          method: 'post',
          url: process.env.REACT_APP_BACKEND_API + '/parameter',
          data: fin_req,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
          }
        });

        // console.log('response : ', res.data);
        removeAllFields()
        openPopup({ type: 'success', text: 'Parameter Added Successfully' })

      } catch (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
        openPopup({ type: 'error', text: msg })
      } finally {
        setSubmitLoading(false)
      }

    }
    else {
      setSubmitLoading(false)
    }
  }

  const validateForm = () => {
    console.log("validating parameter data...")
    let res = false;
    let temp = [];
    [...inputFields].forEach((element, index) => {
      if (element['fda_name']==''||
          element['airplane']==''||
          element['boeing_mnemonic_code']==''||
          element['ia_sensor_name']=='') {
        temp.push(index)
        openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
        res = true;
      }
    });
    setValidationError(temp)
    console.log('validation errors : ', validationError)
    return res
  }

  useEffect(() => {
    fetchAllParameters()
  }, [])

  async function fetchAllParameters() {
    console.log("fectching all parameters...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/parameter?detailed=true&all=true')
      .then(res => {
        console.log('parameters', res.data.data)
        let temp = res.data.data.map((elem) => {
          return elem.fda_name
        })
        setConfiguredParameters(temp)
      })
      .catch(function (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
        openPopup({ type: 'error', text: msg})
      })
  }

  if (inputFields && configuredParameters)
    return (
      <>
        {
          <>
            <div className='add-page-subcontainer'>
              <FileUpload returnParsedData={setParsedData} />
            </div>
            <Divider />
          </>
        }
        <div className='add-page-subcontainer'>
          {
            inputFields.map((input, index) => {
              return (
                <div key={index} className='full-width'>
                  {configuredParameters.includes(input.fda_name)?<><Alert severity="warning">This Parameter - {input.fda_name}  is already configured.<br/> Any changes will update the existing configurations</Alert><br/></>:''}
                  <div className='add-page-entity'>
                    <div className='add-page-form'>
                      <div className='input-element'>
                        <label className='input_label'>FDA Name*</label>
                        <input style={{ width: '250px' }} className={(validationError.length > 0 && validationError.includes(index)) && input.fda_name == '' ? 'input_box_error' : 'input_box'} value={input.fda_name} onChange={event => handleFormChange(index, event)} type="text" name="fda_name" placeholder="Enter FDA Name..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Boeing Mnemonic Code*</label>
                        <input style={{ width: '250px' }} className='input_box' value={input.boeing_mnemonic_code} onChange={event => handleFormChange(index, event)} type="text" name="boeing_mnemonic_code" placeholder="Enter Boeing Mnemonic Code..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Parameter Description</label>
                        <input style={{ width: '250px' }} value={input.parameter_desc} onChange={event => handleFormChange(index, event)} className='input_box' type="text" name="parameter_desc" placeholder="Enter Parameter Description..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Airplane Model*</label>
                        <Select
                          name="airplane"
                          className={validationError.length > 0 && input.airplane == '' ? 'input_box_error' : 'input_box'}
                          style={{ width: '250px ', height: '40px' }}
                          size='small'
                          placeholder="Select Airplane Model..."
                          value={input.airplane || ''}
                          options={airplaneModelOptions}
                          onChange={event => handleFormChange(index, event)}
                        >
                          {airplaneModelOptions}
                        </Select>
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>ATA Code</label>
                        <Select
                          name="ata_code"
                          className={'input_box'}
                          style={{ width: '250px ', height: '40px' }}
                          size='small'
                          placeholder="Select ATA Code..."
                          value={input.ata_code || []}
                          options={ataCodeOptions}
                          onChange={event => handleFormChange(index, event)}
                          multiple
                        >
                          {ataCodeOptions}
                        </Select>
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>IA Sensor Name*</label>
                        <input style={{ width: '250px' }} value={input.ia_sensor_name} onChange={event => handleFormChange(index, event)} className={(validationError.length > 0 && validationError.includes(index)) && input.ia_sensor_name === '' ? 'input_box_error' : 'input_box'} type="text" name="ia_sensor_name" placeholder="Enter IA Sensor Name..." />
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Aggregators</label>
                        <Select
                          name="aggregators"
                          className={'input_box'}
                          style={{ width: '250px ', height: '40px' }}
                          size='small'
                          placeholder="Select Aggregators..."
                          value={input.aggregators || []}
                          options={aggregatorOptions}
                          onChange={(e) => handleFormChange(index, e)}
                          multiple
                        >
                          {aggregatorOptions}
                        </Select>
                      </div>
                      <div className='input-element'>
                        <label className='input_label'>Additional Information</label>
                        <input style={{ width: '500px' }} value={input.additional_info} onChange={event => handleFormChange(index, event)} className='input_box' type="text" name="additional_info" placeholder="Enter Additional Information..." />
                      </div>
                    </div>
                    <div style={{ marginTop: '21px' }}>
                      <Button variant="outlined-danger" action={() => { removeFields(index) }}>
                        <span className="material-icons-outlined" style={{ marginRight: '5px' }}>clear</span><p>Delete</p>
                      </Button>
                    </div>
                  </div>
                  <div style={{ borderTop: '1px solid var(--color-secondary-light)', margin: '25px 0', width: '100%' }} />
                </div>
              )
            })
          }

          <div className='add-page-button-group'>
            <Button variant="outlined-primary" action={addFields}>
              <span className="material-icons-outlined" style={{ marginRight: '5px' }}>add</span><p>Add New</p>
            </Button>
            <div style={{ display: 'flex' }}>
              <Button variant="outlined-danger" action={() => { removeAllFields() }}>
                <span className="material-icons-outlined" style={{ marginRight: '5px' }}>clear</span><p>Clear All</p>
              </Button>
              <Button variant="filled-primary" action={handleSubmit}>
                {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Submit</p>}
              </Button>
            </div>
          </div>

        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
      </>
    )
  else
    return (
      <>
        <Skeleton variant="rectangular" width='100%' height='200px' />
        <Divider />
        <Skeleton variant="rectangular" width='100%' height='200px' />
      </>
    )
}

export default AddParameter