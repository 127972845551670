import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import '../../Styles/Pages/Config.css'
import Button from '../../Components/UI-Elements/Button'
import {useAuth} from "../../Components/Auth/AuthProvider";
import { Divider, Link, Typography } from '@mui/material';

function GenerateConfig() {
  let auth = useAuth();

  const [data, setData] = useState({ customer_id: '' })
  const [configFiles, setConfigFiles] = useState(null)

  const handleFormChange = (event) => {
    let temp = { ...data };
    temp[event.target.name] = event.target.value;
    setData(temp);
  }

  const [customerData, setCustomerData] = useState(null)

  useEffect(() => {
    fetchCustomers()
  }, [])

  const fetchCustomers = () => {
    console.log("fectching customer data...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-customer')
      .then(res => {
        // console.log(res.data.data)
        let data = []
        res.data.data.forEach(element => {
          data.push({
            'customer_id': element.id,
            'gcs_name': element.gcs_name,
            'email': element.contact_email
          })
        })
        console.log('customer data', data)
        setCustomerData(data)
      })
      .catch(function (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
        openPopup({ type: 'error', text: msg})
      })
  }

  if (customerData) {
    var CustomerOptions = customerData.map((customer, index) => {
      return (<option key={index} value={customer.customer_id}>{customer.gcs_name}</option>)
    })
  }

  const [alert, setAlert] = useState({ type: 'error', text: '' })
  const [popup, setPopup] = useState(false)

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  const [submitLoading, setSubmitLoading] = useState(false)

  const handleSubmit = async () => {
    setSubmitLoading(true);
    if (data.customer_id === '')
    {
      openPopup({ type: 'error', text: 'Select Customer' });
      setSubmitLoading(false);
      return;
    }

    // generate config file and store in GCP Storage
    var req = {
      'customer_id': data.customer_id,
      'user': auth.name
    };

    try {
      const res = await auth.authAxios({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_API + '/config_generation/' + data.customer_id,
        data: req,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,POST,OPTIONS"
        }
      });
      setConfigFiles(res.data);
      openPopup({ type: 'success', text: 'Files have been successfully created!' })

    } catch (err) {
      //const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
      openPopup({ type: 'error', text: 'Internal Server Error' })
    } finally {
      setSubmitLoading(false);
    }
  }

  function generateConfigItem(key, item) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{margin: '5px'}}>{key+': '}</p>
        <div dangerouslySetInnerHTML={ {__html: item} } />
      </div>
    );
  }

  if (customerData)
    return (
      <>
        <div className='add-page-subcontainer'>
          <div className='full-width'>
            <br /><p className='table-title'>Config Details</p><br />
            <div className='add-page-entity'>
              <div className='add-page-form'>

                <div className='input-element'>
                  <label className='input_label'>GCS Name</label>
                  <select style={{ width: '250px' }} className='input_box' value={data.customer_id} onChange={event => handleFormChange(event)} name="customer_id">
                    <option value="" disabled>Select Customer...</option>
                    {CustomerOptions}
                  </select>
                </div>
              </div>
            </div>

            <br />

            <div className='add-page-button-group'>
              <div style={{ display: 'flex' }}>
                <Button variant="filled-primary" action={handleSubmit}>
                  {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Generate Config</p>}
                </Button>
              </div>
            </div>
            { configFiles &&
              <div>
                <Divider style={{margin: '20px'}}/>
                <Card variant="outlined" style={{ marginTop: '20px' }}>
                  {Object.entries(configFiles).map(([key, item])=> generateConfigItem(key, item))}
                </Card>
              </div>
            }
          </div>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
      </>
    )
    else {
      return (
        <>
          <div className='list-page-subcontainer' style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading....</p></div>
          </div>

          <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
            <Alert severity={alert['type']} sx={{ width: '100%' }}>
              {alert['text']}
            </Alert>
          </Snackbar>
        </>
      )
    }
}

export default GenerateConfig