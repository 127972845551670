import React, { useState } from 'react'
import Papa from "papaparse";
import { saveAs } from 'file-saver';

import Button from './Button';

function DataExport(props) {
    const [loading, setLoading] = useState(false);

    const onButtonClick = async () => {
        setLoading(true);
        let data = [];
        if (!props.data) {
            console.log('here')
            data = await props.getData();
        } else {
            console.log('there')
            data = [...props.data];
        }
        
        // Double loop through each row / column and push to a new array to avoid state mutation 
        const exportData = [];
        data.forEach((row) => {
            const rowMap = {};
            Object.keys(row).forEach((col) => {
                if(Object.keys(props.columnMapping).some(item => item === col)) {
                    let val = row[col];
                    if(props.stringifyObject){
                        if(row[col] === Object(row[col]) && !Array.isArray(row[col])) {
                            val = JSON.stringify(row[col]);
                        }
                    }
                    if(props.arrayDelimiter) {
                        if(Array.isArray(row[col])) {
                            val = row[col].join(props.arrayDelimiter);
                        }
                    }
                    if(props.columnMapping) {
                        rowMap[props.columnMapping[col]] = val;
                    } else {
                        rowMap[col] = val;
                    }
                }
            })
            exportData.push(rowMap);
        });

        const csv = Papa.unparse(exportData, {
            skipEmptyLines: true,
            columns: Object.values(props.columnMapping)
        });
        const blob = new Blob([csv]);
        saveAs(blob, props.fileName);
        setLoading(false);
    };

    return (
        <div>
            <Button variant="filled-primary" action={onButtonClick}>
                <div>
                {
                    loading ?
                    <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader">autorenew</span><p>Loading....</p></div>
                    :
                    <div style={{ display: 'flex', alignItems: 'center' }}><span style={{ display: 'none' }} className="material-icons-outlined">data_export</span><p>{props.text}</p></div>
                }
                </div>
            </Button>
        </div>  
    );
}

export default DataExport;
