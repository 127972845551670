import React from 'react'

import '../../Styles/UI-Elements/Button.css'

/*
props:

variant - filled-primary, outlined-primary, filled-danger, outlined-danger
action - onClick callback
children

example usage:

<Button variant="filled-primary" action={onButtonClick}>
  <span className="material-icons-outlined">file_upload</span><p>Choose a File</p>
</Button>

*/

function Button(props) {
  return (
    <div id={props.id} className={'button-container ' + props.variant} onClick={props.disabled ? () => {} : props.action}>
      {props.children}
    </div>
  )
}

export default Button