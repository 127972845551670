import React, { useState, useRef, useEffect } from 'react'
import Papa from "papaparse";

import Button from './Button';

import '../../Styles/UI-Elements/FileUpload.css';

/*

props:
 
returnParsedData - callback function returns parsed csv data in json format

*/

function XMLUpload(props) {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    console.log(e.target.id);
    e.dataTransfer.setData("text", e.target.id);
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    // setLoading(true);
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0]);
    }
    inputRef.current.value = '';
  };

  const handleFiles = function (xml_file) {
    props.XMLParseFunction(xml_file);
  }

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <div className={dragActive ? "file-upload-container file-upload-container-drag-active" : "file-upload-container"}>
        <input ref={inputRef} type="file" className="file-upload-input" multiple={true} onChange={handleChange} />
        <p className='file-upload-title'>Drag your FRED file (.xml) here</p>
        <p className='file-upload-subtitle'>or click the button below to upload your file</p>
        <Button variant="filled-primary" action={onButtonClick}>
            <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined" style={{ marginRight: '5px' }}>file_upload</span><p>Choose a File</p></div>
        </Button>
      </div>
      {dragActive && <div className="file-upload-drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
    </form>
  )
}

export default XMLUpload