import React, { useState, useEffect, useRef } from 'react'



import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Select from '@mui/material/Select';

import Button from '../../Components/UI-Elements/Button'
import Modal from '../../Components/UI-Elements/Modal';
import SimpleModal from '../../Components/UI-Elements/SimpleModal';
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';

import '../../Styles/Pages/AddPage.css'
import '../../Styles/UI-Elements/InputElement.css'
import Table from '../../Components/UI-Elements/Table'
import {useAuth} from "../../Components/Auth/AuthProvider";
import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';
import ManufacturerOptions from '../../Components/Data/Manufacturers';
import EditAirplane from './EditAirplane';

function ConfigureAirplanes() {
    let auth = useAuth();
    const [inputFields, setInputFields] = useState({
        airplane_manufacturer: '',
        airplane_model : '',
        airplane_submodel: '',
        description:''
    })

    const [submitLoading, setSubmitLoading] = useState(false)
    const [validationError, setValidationError] = useState(false)

    const handleFormChange = (event) => {
        let data = { ...inputFields };
        data[event.target.name] = event.target.value;
        setInputFields(null)
        setInputFields(data);
    }

    const handleSubmit = (e) => {
        setSubmitLoading(true)
        e.preventDefault();
        console.log("submitted data", inputFields)
        submit()
    }

    async function submit() {
        console.log("submit")
        let validateError = validateForm()
        console.log('validation error : ', validateError)

        if (!validateError) {
            try {
                const res = await auth.authAxios({
                  method: 'post',
                  url: process.env.REACT_APP_BACKEND_API + '/airplane',
                  data: {
                            'airplane_manufacturer':inputFields['airplane_manufacturer'],
                            'airplane_model':inputFields['airplane_model'],
                            'airplane_submodel':inputFields['airplane_submodel'],
                            'airplane_desc':inputFields['description']
                        },
                  headers:{
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                  }
                });
        
                console.log('response : ', res.data.message);
                if(res.status === 200){
                    openPopup({ type: 'success', text: 'Success!! Airplane Added Succesfully'})
                    setInputFields({
                        airplane_manufacturer: '',
                        airplane_model : '',
                        airplane_submodel: '',
                        description:''
                    })
                    fetchAirplaneModels()
                }
              } catch (err) {
                const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
                openPopup({ type: 'error', text: msg})
              } finally {
                setSubmitLoading(false)
              }
        }
        else
        {
            setSubmitLoading(false)
        }
    }

    function validateForm() {
        if(inputFields['airplane_manufacturer'] === '' || inputFields['airplane_model']==='' || inputFields['airplane_submodel']==='')
        {
            setValidationError(true)
            openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
            return true;
        }
        else
        {
            setValidationError(false)
            return false;
        }
    }

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const [airplaneData, setAirplaneData] = useState(null)
    const [initialAirplaneData, setInitialAirplaneData] = useState(null)
    const [rowData, setRowData] = useState({})
    const [mappedCounts, setMappedCounts] = useState(null)

    useEffect(() => {
        fetchAirplaneModels()
    }, [])

    const fetchAirplaneModels = () => {
        console.log("fectching airplane data...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-airplane')
            .then(res => {
                // console.log(res.data.data)
                let data = []
                res.data.data.forEach(element => {
                    data.push({
                        'submodel_id':element.submodel_id,
                        'airplane_id': element.airplane_id,
                        'airplane_manufacturer':element.manufacturer,
                        'airplane_model':element.model,
                        'airplane_submodel':element.submodel,
                        'airplane_desc':element.description,
                    })
                })
                console.log(data)
                setAirplaneData(data)
                setInitialAirplaneData(data)
            })
            .catch(function (err) {
                const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
                openPopup({ type: 'error', text: msg})
            })
    }

    const fetchAirplaneCounts = (submodel_id) => {
        console.log("fectching airplane counts...")
        setSubmitLoading(true);
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/airplane/count/' + submodel_id)
            .then(res => {
                console.log(res.data);
                setMappedCounts(res.data, setDeleteModalVisible(true));
            })
            .catch(function (err) {
                const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
                openPopup({ type: 'error', text: msg})
            })
        setSubmitLoading(false);
    }

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false)

    const displayEditModal = (airplaneRowInd) => {
        setEditModalVisible(true);
        let airplaneRowData = airplaneData[airplaneRowInd];
        setRowData(airplaneRowData);
    }

    const hideEditModal = () => {
        setEditModalVisible(false);
        setRowData({});
    }

    const displayDeleteModal = (airplaneRowInd) => {
        let airplaneRowData = airplaneData[airplaneRowInd];
        fetchAirplaneCounts(airplaneRowData.submodel_id);
        setDeleteModalVisible(true);
        setRowData(airplaneRowData);
    }

    const hideDeleteModal = () => {
        setDeleteModalVisible(false);
        setMappedCounts(null);
        setRowData({});
    }

    const handleBackdropClose = () => {
        setSubmitLoading(false);
    }

    async function handleEdit(editedData) {
        console.log('Sending requet to edit airplane ID:', editedData.submodel_id);
        hideEditModal();
        setSubmitLoading(true);
        try {
          const res = await auth.authAxios({
            method: 'put',
            url: process.env.REACT_APP_BACKEND_API + '/airplane',
            data: {
              'data': editedData,
              'user': auth.name
            }
          });
    
          console.log(res);
          if(res.status === 200)
          {
            fetchAirplaneModels();
            openPopup({ type: 'success', text: 'Airplane Edited Successfully' });
          }
        } catch (error) {
          const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
          openPopup({ type: 'error', text: msg});
        }
        setSubmitLoading(false);
    }

    async function handleDelete() {
        console.log('Deleting airplane ID:', rowData.submodel_id);
        hideDeleteModal();
        setSubmitLoading(true);
        try {
          const res = await auth.authAxios({
            method: 'delete',
            url: process.env.REACT_APP_BACKEND_API + '/airplane',
            data: {
              'data': rowData,
              'user':auth.name
            }
          });
    
          console.log(res);
          if(res.status === 200)
          {
            let data = [...airplaneData];
            const filteredData = data.filter(e => e.submodel_id != rowData.submodel_id);
            setAirplaneData(filteredData);
            openPopup({ type: 'success', text: 'Airplane Deleted Successfully' });
          }
        } catch (error) {
          const msg = error.response.data.error ? error.response.data.error : error.response.data.msg;
          openPopup({ type: 'error', text: msg});
        }
        setSubmitLoading(false);
    }

    const Header = [
        { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true, Cell: (row) => {
            const airplaneRowInd = row.row.index;
            return (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div className='list-action' onClick={() => displayEditModal(airplaneRowInd)}><span className="material-icons">launch</span></div>
                    <div className='list-action' onClick={() => displayDeleteModal(airplaneRowInd)}><span className="material-icons">delete</span></div>
                </div>
        )
        }},
        { Header: 'Airplane Manufacturer', accessor: 'airplane_manufacturer', canFilter: true },
        { Header: 'Airplane Model', accessor: 'airplane_model', canFilter: true },
        { Header: 'Airplane Sub-Model', accessor: 'airplane_submodel', canFilter: true },
        { Header: 'Airplane Description', accessor: 'airplane_desc', canFilter: true },
    ]

    function ModalDeleteMessage({mappedCounts}) {
        let messageText = <></>
        const filteredArray = mappedCounts != null ? mappedCounts.counts.filter(item => item.count > 0): null;
        if (mappedCounts != null && mappedCounts.airplanes === 1) {
            let arrayText = filteredArray.map(item => <><b>Table: {item.table}, Count: {item.count}</b><br/></>);
            if (filteredArray.length === 0) {
                // Case when this is the last airplane AND airplane submodel to be deleted but there are no associated mappings
                messageText = 
                    <div>
                        <>Deleting the airplane will remove the airplane submodel and its parent airplane.<br /> Are you sure you want to delete?<br /><br /></>
                        <Button variant="filled-danger" action={handleDelete}>
                            <p>Delete</p>
                        </Button>
                    </div>
            } else {
                // Case when this is the last airplane AND airplane submodel to be deleted AND associated mappings exist
                messageText = 
                    <div>
                        <>One or more mappings with this airplane submodel or its parent still exists in the database. 
                            Please delete the following before deleting the airplane submodel.<br/><br/>
                            {arrayText}
                            <br/>
                        </>
                        <Button variant="filled-primary" action={hideDeleteModal}>
                            <p>OK</p>
                        </Button>
                    </div>
            }
        } else if (mappedCounts != null && mappedCounts.airplanes > 1) {
            const critProblems = filteredArray.find(item => item.table === 'critical_problems')
            if (filteredArray.length > 0 && critProblems != undefined) {
                    // Case when a there are problems mapped to only this particular submodel. User should not be able to delete
                messageText = 
                    <div>
                        <>There are <b>{critProblems.count}</b> problems <b>ONLY</b> mapped to this particular submodel. 
                            Please delete these problems before proceeding.<br/><br/>
                            <br/>
                        </>
                        <Button variant="filled-primary" action={hideDeleteModal}>
                            <p>OK</p>
                        </Button>
                    </div>
            } else if (filteredArray.length > 0 && critProblems === undefined) {
                    // Case when a there are NOT ANY problems mapped to ONLY this particular submodel, 
                    // but problems mapped to this submodel AND other submodels. User should be able to delete
                messageText = 
                    <div>
                        <>This airplane submodel is mapped to <b>{filteredArray.find(item => item.table === 'problem').count}</b> problems. 
                            Are you sure you want to proceed? 
                            This will <b>permanently delete the submodel and its problem mappings,</b> but preserve the problems themselves.<br/><br/>
                            <br/>
                        </>
                        <Button variant="filled-warning" action={handleDelete}>
                            <p>Delete anyway</p>
                        </Button>
                    </div>
            } else {
                // Case when there are multiple submodels of the same airplane but this particular submodel has no problem mappings
                messageText = 
                    <div>
                        <>Deleting the airplane will remove the mapping data as well.<br /> Are you sure you want to delete?<br /><br /></>
                        <Button variant="filled-danger" action={handleDelete}>
                            <p>Delete</p>
                        </Button>
                    </div>
            }
        }
        return(messageText)
    }

    if(airplaneData)
    return (
        <>
            <div className='add-page-subcontainer'>
                <div className='full-width'>
                    <br />
                    <p className='table-title'>Add Airplane</p>
                    <br />
                    <div className='add-page-entity'>
                        <div className='add-page-form'>
                            <div className='input-element'>
                                <label className='input_label'>Airplane Manufacturer*</label>
                                <Select
                                    name="airplane_manufacturer"
                                    className={validationError && inputFields.airplane_manufacturer == '' ? 'input_box_error' : 'input_box'}
                                    style={{ width: '300px ', height: '40px' }}
                                    size='small'
                                    placeholder="Select Airplane Manufacturer..."
                                    value={inputFields.airplane_manufacturer || ''}
                                    options={ManufacturerOptions}
                                    onChange={event => handleFormChange(event)}
                                >
                                    {ManufacturerOptions}
                                </Select>
                            </div>
                            <div className='input-element'>
                                <label className='input_label'>Airplane Model*</label>
                                <input style={{ width: '300px' }} value={inputFields.airplane_model} onChange={event => handleFormChange(event)} className={validationError && inputFields.airplane_model == '' ? 'input_box_error' : 'input_box'} type="text" name="airplane_model" placeholder="Enter Airplane Model Name..." />
                            </div>
                            <div className='input-element'>
                                <label className='input_label'>Airplane Submodel*</label>
                                <input style={{ width: '300px' }} className={validationError && inputFields.airplane_submodel == '' ? 'input_box_error' : 'input_box'} value={inputFields.airplane_submodel} onChange={event => handleFormChange(event)} type="text" name="airplane_submodel" placeholder="Enter Airplane Submodel Name..." />
                            </div> 
                            <div className='input-element'>
                                <label className='input_label'>Airplane Description</label>
                                <input style={{ width: '300px' }} className='input_box' value={inputFields.description} onChange={event => handleFormChange(event)} type="text" name="description" placeholder="Enter Airplane Description..." />
                            </div> 
                        </div>
                    </div>
                    <div style={{display:'flex',alignItems:"flex-end",flexGrow:'2'}}>
                            <Button variant="filled-primary" action={handleSubmit}>
                                {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Submit</p>}
                            </Button>
                    </div>
                    <div style={{ borderTop: '1px solid var(--color-secondary-light)', margin: '25px 0', width: '100%' }} />

                    <Table
                        header={Header}
                        data={airplaneData}
                        title='Airplane List'
                        pageSize={DEFAULT_PAGE_SIZE}
                    />

                </div>
            </div>

            <SimpleModal 
                visible={editModalVisible}
                closeAction={hideEditModal}
                title="Edit Airplane">
                <EditAirplane 
                    rowData={rowData} 
                    airplaneData={airplaneData} 
                    initialAirplaneData={initialAirplaneData} 
                    handleEdit={handleEdit} 
                    alert={alert} 
                    popup={popup} 
                    setPopup={setPopup}
                />
            </SimpleModal>

            <SimpleModal
                visible={deleteModalVisible}
                closeAction={hideDeleteModal}
                title="Delete Airplane"
                >
                <ModalDeleteMessage mappedCounts={mappedCounts} />
            </SimpleModal>

            <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
                <Alert severity={alert['type']} sx={{ width: '100%' }}>
                    {alert['text']}
                </Alert>
            </Snackbar>
            <SimpleBackdrop loading={submitLoading} handleBackdropClose={handleBackdropClose}/>
        </>
    )
    else
    return(<><Skeleton variant="rectangular" width='100%' height='600px' /></>)
}

export default ConfigureAirplanes