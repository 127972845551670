import React, { useState, useEffect } from 'react';

import Button from '../../Components/UI-Elements/Button';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AutoCompleteInput from '../../Components/UI-Elements/AutoCompleteInput';

import { useAuth } from '../../Components/Auth/AuthProvider';

function EditAirplane(props) {
    let auth = useAuth();

    const [formData, setFormData] = useState({
        'submodel_id': '',
        'airplane_manufacturer': '',
        'airplane_model': '',
        'airplane_submodel': '',
        'airplane_desc': ''
    })

    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        let temp = {...props.rowData};
        setFormData(temp);
    }, [])

    const handleFormChange = (event) => {
        let temp = { ...formData };
        temp[event.target.name] = event.target.value ?? '';
        setFormData(temp);
    }

    const handleSubmit = () => {
        setSubmitLoading(true);
        console.log("submitted form data", formData);
        props.handleEdit(formData);
    }

    const handleAutoInputChange = (name, value, reason) => {
        let temp = {...formData};
        temp[name] = value;
        setFormData(temp);
    }

    function validateForm() {
        let res = false;

        if(formData['customer_name']== '')
        {
            // openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
            res = true
        }

        return res;
    }

    const generateOptions = (key) => {
        return ([... new Set(props.initialAirplaneData.map((item) => item[key]))])
    }

    const validationError = false

    if (formData)
        return (
        <div>
        <div className='add-page-entity'>
          <div className='add-page-form'>
            <div className='input-element'>
            </div>
            <div className='input-element'>
                <label className='input_label'>Airplane Manufacturer*</label>
                <input style={{ width: '250px' }} value={formData.airplane_manufacturer} onChange={event => handleFormChange(event)} className={formData.customer_name == '' ? 'input_box_error' : 'input_box'} type="text" name="airplane_manufacturuer" placeholder="Enter Airplane Manufacturer..." />
            </div>
            <AutoCompleteInput
                    options={ generateOptions('airplane_model') }
                    validationError={validationError}
                    width={'200px'}
                    label={'Aiplane Model*'}
                    value={formData.airplane_model}
                    handleChange={(name, value, reason) => { handleAutoInputChange(name, value, reason) }}
                    // handleChange={(name, value, reason) => { generalHandler(name, value, reason) }}
                    name={'airplane_model'}
                    placeholder={"Enter Airplane Model..."}
                />
            <AutoCompleteInput
                    options={ generateOptions('airplane_submodel') }
                    validationError={validationError}
                    width={'200px'}
                    label={'Aiplane Submodel*'}
                    value={formData.airplane_submodel}
                    handleChange={(name, value, reason) => { handleAutoInputChange(name, value, reason) }}
                    // handleChange={(name, value, reason) => { generalHandler(name, value, reason) }}
                    name={'airplane_submodel'}
                    placeholder={"Enter Airplane Submodel..."}
                />
            <div className='input-element'>
                <label className='input_label'>Airplane Description</label>
                <input style={{ width: '250px' }} value={formData.airplane_desc || ''} onChange={event => handleFormChange(event)} className={'input_box'} type="text" name="airplane_desc" placeholder="Enter Airplane Description..." />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '21px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <Button variant="filled-primary" action={() => { handleSubmit() }}>
            {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Submit</p>}
          </Button>
        </div>

        <Snackbar open={props.popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { props.setPopup(false) }}>
          <Alert severity={props.alert['type']} sx={{ width: '100%' }}>
            {props.alert['text']}
          </Alert>
        </Snackbar>
        </div>
    )
    else
        return null
}

export default EditAirplane;
