import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import '../Styles/Components/Sidebar.css'
import {useAuth} from './Auth/AuthProvider'

/*

props:
    toggleSidebar - callback function, handles sidebar opening and closing
*/
function Sidebar(props) {
    let navigate = useNavigate();
    let auth = useAuth();

    return (
        <>
            <div className="sidebar-container">
                <div className="sidebar-logo-container">
                    <img className='sidebar-logo' src='/assets/logo.png' alt='logo' />
                    <span className="material-icons-outlined" style={{cursor:'pointer',marginRight:'20px'}} onClick={props.toggleSidebar}>close</span>
                </div>
                <div className='shadow-bottom'></div>
                <div className="sidebar-nav-items-container">
                    <div className="sidebar-nav-items">

                        <ul className="sidebar-nav-list" style={{ marginTop: '10px' }}>

                            <Link to="/">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">space_dashboard</span>
                                    <div>Dashboard</div>
                                </li>
                            </Link>


                            <li className='label'>Parameters</li>

                            <Link to="/parameter/add">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">add_circle_outline</span>
                                    <div>Add Parameters</div>
                                </li>
                            </Link>


                            <Link to="/parameter/list">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">subject</span>
                                    <div>List Parameters</div>
                                </li>
                            </Link>

                            <li className='label'>Components</li>

                            <Link to="/component/add">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">add_circle_outline</span>
                                    <div>Add Components</div>
                                </li>
                            </Link>

                            <Link to="/component/list">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">subject</span>
                                    <div>List Components</div>
                                </li>
                            </Link>

                            <li className='label'>Problems</li>

                            <Link to="/problem/add">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">receipt_long</span>
                                    <div>Generate Problem</div>
                                </li>
                            </Link>

                            <Link to="/problem/list">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">subject</span>
                                    <div>List Problem</div>
                                </li>
                            </Link>

                            <li className='label'>Mappings</li>

                            <Link to="/problem/parameter-mapping">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">playlist_add</span>
                                    <div>Parameter Mapping</div>
                                </li>
                            </Link>
                            <Link to="/problem/component-mapping">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">playlist_add</span>
                                    <div>Component Mapping</div>
                                </li>
                            </Link>

                            <li className='label'>Config</li>

                            <Link to="/config/generate">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">published_with_changes</span>
                                    <div>Generate Config</div>
                                </li>
                            </Link>

                            <Link to="/config/list">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">format_list_numbered</span>
                                    <div>List Configs</div>
                                </li>
                            </Link>

                            <li className='label'>Dataframes</li>

                            <Link to="/dataframe/import-dataframes">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">add_circle_outline</span>
                                    <div>Import Dataframes</div>
                                </li>
                            </Link>

                            <Link to="/dataframe/customer-mapping">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">playlist_add</span>
                                    <div>Customer Mapping</div>
                                </li>
                            </Link>

                            <li className='label'>Miscellaneous</li>

                            <Link to="/misc/airplane">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">flight</span>
                                    <div>Configure Airplane</div>
                                </li>
                            </Link>

                            <Link to="/misc/customers">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">people</span>
                                    <div>Configure Customers</div>
                                </li>
                            </Link>

                            <Link to="/misc/add-fred-file">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">add_circle_outline</span>
                                    <div>Add FRED File</div>
                                </li>
                            </Link>

                            <Link to="/misc/logs">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">assignment</span>
                                    <div>Logs</div>
                                </li>
                            </Link>

                        </ul>

                        <ul className="sidebar-nav-list" style={{ marginTop: '50px' }}>
                            <Link to="/misc/settings">
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">settings</span>
                                    <div>Settings</div>
                                </li>
                            </Link>

                            <div onClick={()=>{auth.signout(()=>{navigate("/")})}}>
                                <li className="sidebar-nav-item">
                                    <span className="material-icons-outlined">logout</span>
                                    <div>Logout</div>
                                </li>
                            </div>

                        </ul>

                    </div>
                </div>

            </div>
        </>
    )
}

export default Sidebar