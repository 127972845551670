import React, { useState, useEffect, useRef } from 'react'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';

import Button from '../../Components/UI-Elements/Button'
import Modal from '../../Components/UI-Elements/Modal';

import '../../Styles/Pages/AddPage.css'
import '../../Styles/UI-Elements/InputElement.css'
import Table from '../../Components/UI-Elements/Table'
import {useAuth} from "../../Components/Auth/AuthProvider";
import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';
import EditCustomer from './EditCustomer';

function ConfigureCustomers() {
    let auth = useAuth();
    const [inputFields, setInputFields] = useState({
        customer_name : '',
        email: ''
    })
    const [submitLoading, setSubmitLoading] = useState(false)
    const [validationError, setValidationError] = useState(false)

    const modalOpen = useRef(null)
    const modalClose = useRef(null)
    const [modalData, setModalData] = useState(null)
    const handleModalOpen = (value) => {
        setModalData(value);
        modalOpen.current();
    }

    const handleModalClose = () => {
        modalClose.current();
        setModalData(null);
    }

    const handleFormChange = (event) => {
        let data = { ...inputFields };
        data[event.target.name] = event.target.value;
        setInputFields(null)
        setInputFields(data);
    }

    const handleSubmit = (e) => {
        setSubmitLoading(true)
        e.preventDefault();
        console.log("submitted data", inputFields)
        submit()
    }

    async function submit() {
        console.log("submit")
        let validateError = validateForm()
        console.log('validation error : ', validateError)

        if (!validateError) {
            try {
                const res = await auth.authAxios({
                  method: 'post',
                  url: process.env.REACT_APP_BACKEND_API + '/customer',
                  data: {
                            'customer_name':inputFields['customer_name'],
                            'gcs_name':inputFields['gcs_name'],
                            'email':inputFields['email']
                        },
                  headers:{
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                  }
                });
        
                console.log('response : ', res.data.message);
                if(res.status === 200){
                    openPopup({ type: 'success', text: 'Success!! Customer Added Succesfully'})
                    setInputFields({
                        customer_name : '',
                        email: ''
                    })
                    fetchCustomers()
                }
              } catch (err) {
                const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
                openPopup({ type: 'error', text: msg})
              } finally {
                setSubmitLoading(false)
              }
        }
        else
        {
            setSubmitLoading(false)
        }
    }

    function validateForm() {
        if(inputFields['customer_name']==='')
        {
            setValidationError(true)
            openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
            return true;
        }
        else
        {
            setValidationError(false)
            return false;
        }
    }

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const [customerData, setCustomerData] = useState(null)

    useEffect(() => {
        fetchCustomers()
    }, [])

    const fetchCustomers = () => {
        console.log("fectching customer data...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-customer')
            .then(res => {
                // console.log(res.data.data)
                let data = []
                res.data.data.forEach(element => {
                    data.push({
                        'customer_id':element.id,
                        'customer_name':element.customer_name,
                        'gcs_name':element.gcs_name,
                        'contact_name': element.contact_name,
                        'email': element.contact_email,
                        'properties': element.properties,
                        'event_tag_rules': element.event_tag_rules,
                        'parameter_count': element.parameter_count
                    })
                })
                data.map((value) => {
                    value["actions"] = <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className='list-action' onClick={() => handleModalOpen(value)}><span className="material-icons">launch</span></div>
                    </div>
                });
                console.log(data)
                setCustomerData(data)
            })
            .catch(function (error) {
                const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
                openPopup({ type: 'error', text: msg})
            })
    }

    const Header = [
        // { Header: 'Customer ID', accessor: 'customer_id' },
        { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true },
        { Header: 'Customer Name', accessor: 'customer_name', canFilter: true },
        { Header: 'GCS Name', accessor: 'gcs_name', canFilter: true },
        { Header: 'Customer Email', accessor: 'email', canFilter: true },
        { Header: 'Unique Parameter Count', accessor: 'parameter_count', canFilter: true }
    ]

    if(customerData)
    return (
        <>
            <div className='add-page-subcontainer'>
                <div className='full-width'>
                    <br />
                    <p className='table-title'>Add Customers</p>
                    <br />
                    <div className='add-page-entity'>
                        <div className='add-page-form'>
                            <div className='input-element'>
                                <label className='input_label'>Customer Name*</label>
                                <input style={{ width: '250px' }} value={inputFields.customer_name} onChange={event => handleFormChange(event)} className={validationError && inputFields.customer_name == '' ? 'input_box_error' : 'input_box'} type="text" name="customer_name" placeholder="Enter Customer Name..." />
                            </div>
                            <div className='input-element'>
                                <label className='input_label'>GCS Customer Abbreviation</label>
                                <input style={{ width: '250px' }} className='input_box' value={inputFields.gcs_name} onChange={event => handleFormChange(event)} type="text" name="gcs_name" placeholder="Enter Customer GCS abbreviation..." />
                            </div>
                            <div className='input-element'>
                                <label className='input_label'>Email</label>
                                <input style={{ width: '250px' }} className='input_box' value={inputFields.email} onChange={event => handleFormChange(event)} type="text" name="email" placeholder="Enter Customer Email..." />
                            </div> 
                        </div>
                        <div style={{display:'flex',alignItems:"flex-end",flexGrow:'2',justifyContent:'flex-end'}}>
                                <Button variant="filled-primary" action={handleSubmit}>
                                    {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Submit</p>}
                                </Button>
                        </div>
                    </div>
                    <div style={{ borderTop: '1px solid var(--color-secondary-light)', margin: '25px 0', width: '100%' }} />

                    <Table
                        header={Header}
                        data={customerData}
                        title='Customer List'
                        pageSize={DEFAULT_PAGE_SIZE}
                    />

                </div>
            </div>

            <Modal modalOpen={modalOpen} modalClose={modalClose} title="Edit Customer">
                <EditCustomer data={modalData} modalClose={handleModalClose} refresh={fetchCustomers} alert={openPopup} />
            </Modal>

            <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={3000} onClose={() => { setPopup(false) }}>
                <Alert severity={alert['type']} sx={{ width: '100%' }}>
                    {alert['text']}
                </Alert>
            </Snackbar>

        </>
    )
    else
    return(<><Skeleton variant="rectangular" width='100%' height='600px' /></>)
}

export default ConfigureCustomers