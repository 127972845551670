import React, { useState, useEffect, useRef } from 'react'
import Table from '../UI-Elements/Table'
import Modal from '../UI-Elements/Modal'
import Button from '../UI-Elements/Button';

import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useAuth } from '../Auth/AuthProvider'
import { DEFAULT_PAGE_SIZE } from '../Data/Constants';
import PaginatedTable from '../UI-Elements/PaginatedTable';

function ListDataframeMappings(props) {
    let auth = useAuth();
    let { problem_data } = props;

    const [tableData, setTableData] = useState(null)
    const pageSizeRef = useRef()

    useEffect(() => {
        console.log(problem_data)
        fetchDataframes()
    }, [])

    async function fetchDataframes() {
        console.log("fectching all mapped parameters...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/dataframe/parameter-mappings/' + problem_data['dataframe_id'] + '?all=true')
            .then(res => {
                console.log('parameters', res.data.data)
                // setData(res.data.data)
                setTableData(res.data.data)
            })
            .catch(function (error) {
                const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
                openPopup({ type: 'error', text: msg})
            })
    }

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const Header = [
        { Header: 'Dataframe ID', accessor: 'dataframe_id'},
        { Header: 'IA Name', accessor: 'ia_name', canFilter: true },
        { Header: 'FDA Name', accessor: 'fda_name', canFilter: true },
        { Header: 'Mnemonic', accessor: 'mnemonic', canFilter: true },
        { Header: 'GCS Name', accessor: 'gcs_name', Cell: ({ cell: { value } }) => { return value && value.toString() }, canFilter: true },
        { Header: 'ATA', accessor: 'ata', canFilter: true },
        { Header: 'Aggregators', accessor: 'aggregators', Cell: ({ cell: { value } }) => { return value && value.join(', ') }, canFilter: true },
        { Header: 'Frequency', accessor: 'frequency', canFilter: true },
        { Header: 'Machine Type', accessor: 'machine_type', canFilter: true },
        { Header: 'Disabled', id: 'disabled', accessor: d => d.disabled != null ? d.disabled.toString(): '', canFilter: true },
        { Header: 'Type', accessor: 'type', canFilter: true },
        { Header: 'Unit', accessor: 'unit', canFilter: true },
        { Header: 'Is Binary', id: 'is_binary', accessor: d => d.is_binary != null ? d.is_binary.toString(): '', canFilter: true },    
    ]

    if (tableData) {
        return (
            <>  
                <PaginatedTable ref={pageSizeRef} header={Header} data={tableData} title='' pageSize={DEFAULT_PAGE_SIZE} />
                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
            </>
        )
    }
    else {
        return (
            <>
                <Skeleton variant="rectangular" width='100%' height='100px' />
            </>
        )
    }
}

export default ListDataframeMappings