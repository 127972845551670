import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
// import Button from '@mui/material/Button';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

/*
props:
  modalClose - ref to handleClickOpen
  modalOpen - ref to handleClickClose
  title - modal title
  width - modal width
  children
*/

/*
  visible - State indicating whether the modal is visible or not
  clickAction - Function to run if button is clicked
  closeAction - Function to run if modal is closed
*/


function SimpleModal({visible, closeAction, title, rowData, width, children}) {
  
  const theme = useTheme();
  //add responsiveness, modal is full screen on smaller screen sizes
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={visible}
        onClose={closeAction}
        maxWidth={width?width:'sm'}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {title}
          <IconButton onClick={closeAction}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SimpleModal
