import React from 'react'

//  --------------------------------------- OR -------------------------------------

function Divider() {
  return (
    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: '10px 0' }}>
      <div style={{ borderTop: '1px dashed var(--color-primary)', width: '50%', marginRight: '5px' }} />
      <p>OR</p>
      <div style={{ borderTop: '1px dashed var(--color-primary)', width: '50%', marginLeft: '5px' }} />
    </div>
  )
}

export default Divider