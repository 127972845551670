import React from 'react'

import '../../Styles/UI-Elements/Checkbox.css'

function Checkbox(props) {
    const checkboxId = "checkbox_" + props.id
    const checkboxLabelKey = "checkbox_key_" + props.id
    return (
        <div className="checkbox-wrapper">
            <div className="round">
                <input type="checkbox" id={ checkboxId } onChange={ props.onChange } checked={ props.checked } />
                <label key={ checkboxLabelKey } htmlFor={ checkboxId }></label>
            </div>
        </div>
    )
}

export default Checkbox;