import React, { useState, useEffect } from 'react'
import FileUpload from '../../Components/UI-Elements/FileUpload'

import '../../Styles/Pages/AddPage.css'
import '../../Styles/UI-Elements/InputElement.css'
import Alert from '@mui/material/Alert';
import Button from '../../Components/UI-Elements/Button'
import Chip from '@mui/material/Chip';
import Divider from '../../Components/UI-Elements/Divider'
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import ComponentMapper from '../../Components/Mapping/ComponentMapper';
import {useAuth} from "../../Components/Auth/AuthProvider";
import { generateOptions } from '../../Common/Helper';
import DataframeMapper from '../../Components/Mapping/DataframeMapper';
import { ImportExport } from '@mui/icons-material';

function DataframeCustomerMapping() {

    // Define templates
    const dataframeTemplate = {
        id: '',
        actions: '',
        dataframe_id: '',
        gcs_name: '',
        name: '',
        file: '',
        imported: '',
        last_updated: '',
        airplane_name: '',
        configured: false,
    };

    const mappingTemplate = {
        customer_id: '',
        dataframes: [],
        error: false
    };

    // Define states
    let auth = useAuth();
    const [refresh, setRefresh] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false)

    const [airplaneData, setAirplaneData] = useState(null)

    const [customerData, setCustomerData] = useState(null);
    const [dataframeData, setDataframeData] = useState(null);
    const [tableData, setTableData] = useState([mappingTemplate])

    //error alert popup state
    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    // Ensures data is loaded before elements can be interacted with
    useEffect(() => {
        fetchAllCustomers()
        fetchAllDataframes()
        fetchAllAirplanes()
    }, [])
    
    // Gets list of all airplanes
    async function fetchAllAirplanes() {
        console.log("fectching airplane data...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-airplane')
            .then(res => {
                // console.log(res.data.data)
                let temp = res.data.data
                let final = {}

                temp.forEach(element => {
                    if (final[element.model]) {
                        final[element.model].push({ 'submodel': element.submodel, 'id': element.id })
                    }
                    else {
                        final[element.model] = [{ 'submodel': element.submodel, 'id': element.id }]
                    }
                });
                console.log('airplaneData', final)
                setAirplaneData(final);
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error})
            })
    }

    // Fetches all customers
    async function fetchAllCustomers () {
        console.log("fectching customer data...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/customer/list')
            .then(res => {
                // console.log(res.data.data)
                let data = []
                res.data.data.forEach(element => {
                    data.push({
                        'customer_id': element.id,
                        'gcs_name': element.gcs_name,
                        'display_name': element.display_name,
                    })
                })
                console.log('customer data', data)
                setCustomerData(data)
            })
            .catch(function (error) {
                openPopup({ type: 'error', text: error.response ? error.response.data.error : error})
            })
    }

    // Fetches all dataframes
    async function fetchAllDataframes() {
        console.log("fetching dataframe names...");
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/dataframes')
        .then(res => {
          // Build dataframe info
          const allDataframes = res.data.data.map((element, index) => {return {
              id: index,
              actions: '',
              dataframe_id: element.id,
              gcs_name: element.gcs_name,
              name: element.name,
              file: element.file,
              imported: element.imported,
              last_updated: element.last_updated,
              airplane_name: element.airplane_name
            };
          });
          setDataframeData(allDataframes);
        });
    }

    // Calls customer mappings to get dataframe mappings for a given customer ID
    const loadCustomerMappings = (customer_id, callback) => {
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/dataframe/customer-mappings/' + customer_id)
        .then(res => {
            const customerMap = res.data.data.map((element, index) => {return {
                customer_id: element.customer_id,
                dataframe_id: element.dataframe_id,
                configured: true,
              };
            });
            callback(customerMap);
        })
        .catch(function (error) {
            openPopup({ type: 'error', text: error.response ? error.response.data.error : error})
        });
    }

    // Handles customer change. Makes backend call 
    function handleCustomerChange(e, index) {
        const customerId = e.target.value;
        let displayData = [...tableData];

        // Prepare new entry
        const newCustomer = {...mappingTemplate}

        // Reloads customer mappings with request to backend. Provides callback function to update relevant fields
        loadCustomerMappings(customerId, (resp) => {
            const relevantDataframeIds = resp.map(element => element.dataframe_id);
            if (relevantDataframeIds != undefined) {
                const mappedDataframes = dataframeData.filter(item => relevantDataframeIds.some( (x) => x == item.dataframe_id) == true );
                newCustomer.dataframes = mappedDataframes.map(item => ({...item, 'configured': true}));
            }

            newCustomer.customer_id = customerId;
            displayData[index] = newCustomer
            setTableData(displayData);
        });
    }

    // Adds customer card and creates templates for user to select customer and add dataframes
    const addNewMapping = () => {
        const displayData = [...tableData];
        displayData.push(mappingTemplate);

        setTableData(displayData);
    }

    // Removes customer card and configured dataframes from view. Triggered when user clicks the X icon at the top right of the card
    const removeMapping = (index) => {
        const displayData = [...tableData];        
        displayData.splice(index, 1);
        setTableData(displayData);
    }

    // Saves user edit of dataframe in table
    const saveComponentEdit = ((customerIndex, dataframeIndex, data) => {
        let displayData = [...tableData];
        let linkedDataframes = displayData[customerIndex].dataframes;

        // Check if name already exists
        const existingMappingIndex = linkedDataframes.map(item => item.name).indexOf(data.name)

        if (existingMappingIndex === -1) {
            linkedDataframes[dataframeIndex] = data;
        } else {
            linkedDataframes[existingMappingIndex] = data;
        }

        //check if the updated fda name coresponds to a already configured component
        if (!("configured" in linkedDataframes[dataframeIndex])) {
            linkedDataframes[dataframeIndex]["configured"] = false
        }
        // linkedDataframes[dataframeIndex]["status_chip"] = linkedDataframes[dataframeIndex]["configured"] == true ? <Chip label="configured" color="primary" variant="outlined" /> : <Chip label="new" color="success" variant="outlined" />;

        setTableData(displayData);
    })

    // Removes single dataframe (row) from table. Triggered when user clicks the trashcan icon
    const removeFields = (customerInd, dataframeInd) => {
        console.log('delete index', dataframeInd);
        let data = [...tableData];
        let dataframes = data[customerInd].dataframes;
        dataframes.splice(dataframeInd, 1);
        setTableData(data);
    }

    // Adds dataframe (row) to table. Triggered when user clicks the [Add Dataframe] button
    const addFields = (problemInd) => {
        let newfield = {...dataframeTemplate};
        let data = [...tableData];
        let dataframes = data[problemInd].dataframes;
        dataframes.push(newfield);
        setTableData(data);
    }

    // Removes all dataframes (rows) from table. Triggered when user clicks the [Clear all] button
    const removeAllFields = (index) => {
        let emptyDataframe = {...dataframeTemplate};
        let data = [...tableData];
        // emptyDataframe.status_chip = <Chip label="new" color="primary" variant="outlined" />;
        data[index].dataframes = [emptyDataframe];
        setTableData(data);
    }
    
    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    // validate fields before submission to backend
    const validateForm = () => {
        console.log("validating data...");
        const displayData = [...tableData]
        displayData.map(item => {
            if (item.customer_id == '' || item.dataframes.filter(df => df.dataframe_id == null).length > 0) {
                item.error = true;
                openPopup({ type: 'error', text: 'Error!! Remove empty customers or dataframes with undefined IDs' });
            } else {
                item.error = false
            }
        });
        if (displayData.find((m) => m.error == true) !== undefined)
            return true;
        return false;
    }

    // Handles submission to backend. Validates form and formats data for post request
    const handleSubmit = async () => {
        let validateError = validateForm();
        console.log('validation error : ', validateError);
        setSubmitLoading(true);
        const displayData = [...tableData];
        let mappings = [];

        if (!validateError) {
            displayData.forEach(customer => {
                const dataframe_ids = customer.dataframes.map(df => df.dataframe_id);
                mappings.push({
                    customer_id: customer.customer_id,
                    dataframes: dataframe_ids
                });
            });

            try {
                const res = await auth.authAxios({
                    method: 'post',
                    url: process.env.REACT_APP_BACKEND_API + '/dataframe/customer-mappings',
                    data: {
                        "mappings": mappings,
                        "user": auth.name
                    },
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "POST"
                    }
                });

                console.log('response : ', res.data.message);
                if (res.status === 200) {
                    fetchAllDataframes().then(() => {
                        openPopup({ type: 'success', text: 'Dataframe mappings submitted successfully!' })
                        setTableData([mappingTemplate]);
                        setSubmitLoading(false);
                    })
                }
            } catch (err) {
                openPopup({ type: 'error', text: err.response ? err.response.data.error : err})
            } finally {
                setSubmitLoading(false);
            }
        } else {
            setSubmitLoading(false);
        }
    }


    if (dataframeData && customerData)
        return (
            <>
                <>
                </>

                {
                    tableData.map((input, index) => {
                        return (
                            <div key={index}>
                                <div className='add-page-subcontainer'>
                                    <DataframeMapper
                                        customerData={customerData}
                                        dataframeData={dataframeData}
                                        tableDisplayData={input.dataframes}
                                        selectedCustomer={input.customer_id}
                                        handleCustomerChange={handleCustomerChange}
                                        index={index}
                                        validationError={input.error} 
                                        saveComponentEdit={saveComponentEdit}
                                        removeFields={removeFields}
                                        addFields={addFields}
                                        removeAllFields={removeAllFields}
                                        removeMapping={removeMapping}
                                        refresh={refresh}
                                        airplaneData={airplaneData}
                                    />
                                </div>
                                <br />
                            </div>

                        )
                    })
                }
                <div className='add-page-button-group'>
                    <div style={{ display: 'flex' }}>
                        <Button variant="outlined-primary" action={addNewMapping}>
                            <span className="material-icons-outlined" style={{ marginRight: '5px' }}>add</span><p>Add New Mapping</p>
                        </Button>
                        {/* <Button variant="filled-primary" action={handleSubmit}>
                            <p>Submit</p>
                        </Button> */}
                        <Button variant="filled-primary" action={handleSubmit}>
                            {
                                submitLoading ? 
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span>
                                        <p>Loading...</p>
                                    </div> : 
                                    <p>Submit</p>
                            }
                        </Button>
                    </div>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={5000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
            </>
        )
    else
        return (
            <>
                <>
                </>
                <Skeleton variant="rectangular" width='100%' height='200px' />
            </>
        )
}

export default DataframeCustomerMapping