import React, { useState, useEffect, useRef } from 'react'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {useAuth} from "../../Components/Auth/AuthProvider";

import Button from '../../Components/UI-Elements/Button'
import Modal from '../../Components/UI-Elements/Modal';
import SettingList from '../../Components/UI-Elements/SettingList';

function Settings() {
    let auth = useAuth();
    
    const [settings, setSettings] = useState(null);

    const [alert, setAlert] = useState({ type: 'error', text: '' });
    const [popup, setPopup] = useState(false);

    const modalOpen = useRef(null);
    const modalClose = useRef(null);
    const [modalData, setModalData] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = () => {
        console.log("fectching settings...");
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/settings')
            .then(res => {
                // console.log(res.data.data)
                let data = [];
                res.data.data.forEach(element => {
                    if (element.name == 'Aggregators') {
                        let value = element.value.split(',');
                        data.push({
                            'id': element.id,
                            'name': element.name,
                            'value': value,
                            'properties': element.properties, // TODO
                        });
                    } else if (element.name == 'ATA Codes' || element.name == 'Flight Phases') {
                        let value = JSON.parse(element.value).map((v) => v.value + ' - ' + v.name)
                        data.push({
                            'id': element.id,
                            'name': element.name,
                            'value': value,
                            'properties': element.properties, // TODO
                        });
                    }
                });
                console.log(data);
                setSettings(data);
            })
            .catch(function (err) {
                const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
                openPopup({ type: 'error', text: msg})
            })
    };

    const handleModalOpen = (name, newItem) => {
        setModalData({name, newItem});
        modalOpen.current();
    }

    const handleModalClose = () => {
        modalClose.current();
        setModalData(null);
    }

    const openPopup = (temp) => {
        setAlert(temp);
        setPopup(true);
    }

    const validate = (name, existingValue, newItem) => {
        if(name == 'ATA Codes' || name == 'Flight Phases') {
            const newTokens = newItem.split('-');
            const newValue = newTokens[0].trim();
            const newName = newTokens[1].trim();
            const existingValues = existingValue.map((v) => v.split('-')[0].trim());
            const existingNames = existingValue.map((v) => v.split('-')[1].trim());
            return existingValues.includes(newValue) || existingNames.includes(newName);
        } else if((name == 'Aggregators') && (!newItem || existingValue.includes(newItem)))
            return true;
        return false;
    }

    const save = async () => {
        setSubmitLoading(true);
        let setting = {...settings.find((s) => s.name == modalData.name)};
        console.log("submit");
        let validateError = validate(setting.name, setting.value, modalData.newItem);
        console.log('validation error : ', validateError)
        if (!validateError) {
            setting.value.push(modalData.newItem);
            let req = {};
            if(setting.name == 'ATA Codes' || setting.name == 'Flight Phases') {
                req = {
                    'name': setting.name,
                    'value': '[' + setting.value.map((v) => { return JSON.stringify({
                        'value': v.split('-')[0].trim(),
                        'name': v.split('-')[1].trim(),
                        'active': true
                    })}).join(',') + ']',
                    'user': auth.name
                };
            } else if (setting.name == 'Aggregators') {
                req = {
                    'name': setting.name,
                    'value': setting.value.join(','),
                    'user': auth.name
                };
            }

            try {
                const res = await auth.authAxios({
                    method: 'put',
                    url: process.env.REACT_APP_BACKEND_API + '/settings/' + setting.id,
                    data: req,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                    }
                });

                // console.log('response : ', res.data);
                openPopup({ type: 'success', text: 'Added Option Successfully' });
                handleModalClose();
            } catch (err) {
                const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
                openPopup({ type: 'error', text: msg})
            } finally {
                setSubmitLoading(false);
                fetchSettings();
            }
        }
        else {
            openPopup({ type: 'error', text: 'Cannot add this option, check your input again.' });
            setSubmitLoading(false);
        }
    };

    if(settings) {
        return (
            <>
                <div className='add-page-subcontainer'>
                    <div className='full-width'>
                        <br /><p className='table-title'>Settings</p><br />
                        <div className='add-page-entity'>
                            <div className='add-page-form'>
                                <div className='input-element'>
                                    <h4 style={{ margin: '10px' }}>Flight Phases</h4>
                                    <SettingList
                                        data={settings.find((s) => s.name == 'Flight Phases') ? settings.find((s) => s.name == 'Flight Phases').value : []}
                                        placeholder={'e.g. taxiout - 010'}
                                        saveCallback={(newItem) => handleModalOpen('Flight Phases', newItem)}
                                    />
                                </div>
                                <div className='input-element'>
                                <h4 style={{ margin: '10px' }}>Aggregators</h4>
                                    <SettingList
                                        data={settings.find((s) => s.name == 'Aggregators') ? settings.find((s) => s.name == 'Aggregators').value : []}
                                        placeholder={'Enter New Aggregator...'}
                                        saveCallback={(newItem) => handleModalOpen('Aggregators', newItem)}
                                    />
                                </div>
                                <div className='input-element'>
                                    <h4 style={{ margin: '10px' }}>ATA Codes</h4>
                                    <SettingList
                                        name='ATA Codes'
                                        data={settings.find((s) => s.name == 'ATA Codes') ? settings.find((s) => s.name == 'ATA Codes').value : []}
                                        placeholder={'e.g. Code - Description'}
                                        saveCallback={(newItem) => handleModalOpen('ATA Codes', newItem)}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>

                <Modal modalOpen={modalOpen} modalClose={modalClose} title="Add Option">
                    <>Added option is permenant and cannot be removed, but can be disabled. Are you sure you want to add new option?<br /><br /></>
                    <Button variant="filled-danger" action={save}>
                        {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Add</p>}
                    </Button>
                </Modal>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
            </>
        );
    } else {
        return (
            <>
                <div className='list-page-subcontainer' style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading....</p></div>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
            </>
        )
    }
}

export default Settings;
