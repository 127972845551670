import React,{useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from './Sidebar';
import Topbar from './Topbar';
import Breadcrumb from './Breadcrumb';

import Drawer from '@mui/material/Drawer';

import '../Styles/Components/Layout.css';

/*

props:
  children

*/

function Layout(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    setSidebar(false)
  }, [pathname])

  const [sidebar, setSidebar] = React.useState(false)

  const toggleSidebar =
    (open) =>
    (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      // console.log('open',open)
      setSidebar(open);
    };
  
    useEffect(() => {
      const keyDownHandler = event => {
        // console.log('User pressed: ', event.key);
        if (event.key == '`') {
          event.preventDefault();
          setSidebar(true);
        }
      };
  
      document.addEventListener('keydown', keyDownHandler);
  
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }, []);


  return (
    <>
      {
        pathname && pathname !== "/login" && pathname !== "/signup" && pathname !== "/misc/docs"?
          <>
            <div className='layout' >
              <Drawer
                anchor={'left'}
                open={sidebar}
                onClose={toggleSidebar(false)}
              >
                <Sidebar toggleSidebar={toggleSidebar(false)}/> 
              </Drawer>
              <div className='layout-container'>
                <div className='layout-sticky'>
                  <Topbar toggleSidebar={toggleSidebar(true)}/>
                  <Breadcrumb data={pathname} />
                </div>
                <div className='layout-content'>
                  {props.children}
                </div>
              </div>
            </div>
          </>
          :
          // for login and signup pages that don't follow the sidebar and topbar layout
          <div>
            {props.children}
          </div>
      }

    </>
  );
}

export default Layout;