import React, { useState, useEffect, useRef } from 'react'

import Divider from '../UI-Elements/Divider'
import Button from '../UI-Elements/Button'
import Modal from '../UI-Elements/Modal';
import Table from '../UI-Elements/Table'

import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import '../../Styles/Components/Mapper.css'
import EditMappedComponent from './EditMappedComponent';
import AutoCompleteInput from '../UI-Elements/AutoCompleteInput';
import { DEFAULT_PAGE_SIZE } from '../Data/Constants';

function ComponentMapper(props) {
    const {
        problemData,
        componentData,
        configuredProblems,
        configuredProblemCustomer,
        configuredComponents,
        configuredComponentsData,
        refresh,
        airplaneData,
        customerData,
        index,
        validationError,
        handleProblemChange,
        saveComponentEdit,
        removeFields,
        addFields,
        removeAllFields,
        removeMapping,
        ataCodeOptions
    } = props;
    //default input values passed from ProblemComponentMapping.js
    const [componentInputFields, setComponentInputFields] = useState(componentData.map((value, componentInd) => {
        value["actions"] = <div style={{ display: 'flex' }}>
            <div className='list-action' onClick={() => handleModalOpen(componentInd)}><span className="material-icons">launch</span></div>
            <div className='list-action' onClick={() => removeFields(index, componentInd)}><span className="material-icons">delete</span></div>
        </div>
        value["status"] = configuredComponentsData.filter((c) => c.airplane == value['airplane'] && c.part_no == value['part_no']).length > 0;
        value["status_chip"] = value["status"] ? <Chip label="configured" color="primary" variant="outlined" /> : <Chip label="new" color="success" variant="outlined" />

        value['component_position_list'] = Array.isArray(value['component_position_list']) ? value['component_position_list'].join(',') : value['component_position_list'].split('|').join(',')
        return value
    }))

    //update Problem and Component Data when refresh prop changes, i.e. when a mapping is submitted
    useEffect(() => {
        let temp = [...props.componentData].map((value, componentInd) => {
            value["actions"] = <div style={{ display: 'flex' }}>
                <div className='list-action' onClick={() => handleModalOpen(componentInd)}><span className="material-icons">launch</span></div>
                <div className='list-action' onClick={() => removeFields(index, componentInd)}><span className="material-icons">delete</span></div>
            </div>
            value["status"] = configuredComponentsData.filter((c) => c.airplane == value['airplane'] && c.part_no == value['part_no']).length > 0;
            value["status_chip"] = value["status"] ? <Chip label="configured" color="primary" variant="outlined" /> : <Chip label="new" color="success" variant="outlined" />

            return value
        })
        setComponentInputFields(temp)
    }, [refresh])

    //EditMappedComponent modal handler
    const modalOpen = useRef(null)
    const modalClose = useRef(null)
    const [modalData, setModalData] = useState(null)
    const handleModalOpen = (index) => {
        setModalData(index)
        modalOpen.current()
    }

    const handleModalClose = () => {
        modalClose.current();
        setModalData(null);
    }
    
    if (customerData) {
        var CustomerOptions = customerData.map((customer, index) => {
            return (<option key={index} value={customer.customer_id}>{customer.gcs_name}</option>)
        })
    }
    
    //Airplane model & sub-model Dropdown options
    if (airplaneData) {
        let airplaneKeys = Object.keys(airplaneData)

        var airplaneModelOptions = [...airplaneKeys].map((item, index) => {
            return (<option key={index} value={item}>{item}</option>)
        })
    }

    if(airplaneModelOptions && airplaneModelOptions.length && problemData && problemData.airplane_model) {
        var airplaneSubmodelOptions = [];

        let submodels = problemData.airplane_model.split(',').map(model => airplaneData[model]).flat().length ? problemData.airplane_model.split(',').map(model => airplaneData[model]).flat() : [];
        if (submodels.length) {
            submodels.map((item, _index) => {
                airplaneSubmodelOptions.push(<MenuItem key={item.id} value={item.submodel}>{item.submodel}</MenuItem>)
            })
        }
    }

    //component list table column headers
    const Header = [
        { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true },
        { Header: 'Status', accessor: 'status_chip', canFilter: false, disableSortBy: true },
        { Header: 'Component Name', accessor: 'component_name', canFilter: true },
        { Header: 'Airplane', accessor: 'airplane', canFilter: true },
        { Header: 'Part No.', accessor: 'part_no', canFilter: true },
        // { Header: 'Event Tag Rules', accessor: 'component_event_tag_rules', Cell: ({ cell: { value } }) => { return JSON.stringify(value) }, canFilter: true },
        { Header: 'ATA Code', accessor: 'ata_code', canFilter: true },
        { Header: 'Component Position List', accessor: 'component_position_list', Cell: ({ cell: { value } }) => { return value.toString() }, canFilter: true },
    ]

    if (componentInputFields && problemData && airplaneData && configuredProblems)
        return (
            <>
                <div className='full-width'>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p className='table-title'>Problem Details</p>
                        <IconButton onClick={() => {removeMapping(index)}}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <br />
                    {configuredProblemCustomer.includes(problemData.problem_name+'-'+problemData.customer_id) ? <><Alert severity="warning">This Problem is already configured for this Customer..<br /> Any changes will update the existing configurations</Alert><br /></> : ''}
                    <div className='add-page-entity'>
                        <div className='add-page-form'>
                            <AutoCompleteInput
                                options={configuredProblems}
                                validationError={validationError}
                                width={'200px'}
                                label={'Problem Name*'}
                                value={problemData.problem_name}
                                handleChange={(n, v, r) => { handleProblemChange(index, n, v, r) }}
                                name={'problem_name'}
                                placeholder={"Enter Problem Name..."}
                            />
                            <div className='input-element'>
                                <label className='input_label'>GCS Name*</label>
                                <select style={{ width: '250px' }} className={validationError && problemData.customer_id == '' ? 'input_box_error' : 'input_box'} value={problemData.customer_id} onChange={event => handleProblemChange(index, event.target.name, event.target.value)} name="customer_id">
                                    <option value="" disabled>Select Customer...</option>
                                    {CustomerOptions}
                                </select>
                            </div>
                            <div className='input-element'>
                                <label className='input_label'>ATA Code</label>
                                <Select
                                    name="ata_code"
                                    className={'input_box'}
                                    style={{ width: '250px ', height: '40px' }}
                                    size='small'
                                    placeholder="Select ATA Code..."
                                    value={problemData.ata_code || ''}
                                    options={ataCodeOptions}
                                    onChange={(e) => handleProblemChange(index, e.target.name, e.target.value)}
                                    multiple
                                >
                                    {ataCodeOptions}
                                </Select>
                            </div>
                            <div className='input-element'>
                                <label className='input_label'>Airplane Model*</label>
                                <select style={{ width: '200px' }} className={validationError && problemData.airplane_model == '' ? 'input_box_error' : 'input_box'} value={problemData.airplane_model} onChange={event => handleProblemChange(index, event.target.name, event.target.value)} name="airplane_model">
                                    <option value="" disabled>Select Airplane Model...</option>
                                    {airplaneModelOptions}
                                </select>
                            </div>
                            <div className='input-element'>
                                <label className='input_label'>Airplane Sub-Model*</label>
                                <Select
                                    name="airplane_submodel"
                                    className={validationError && problemData.airplane_submodel == '' ? 'input_box_error' : 'input_box'}
                                    style={{ width: '220px', height: '40px' }}
                                    size='small'
                                    placeholder="Select Airplane Model..."
                                    value={problemData.airplane_submodel}
                                    options={airplaneSubmodelOptions}
                                    onChange={event => handleProblemChange(index, event.target.name, event.target.value)}
                                    multiple
                                >
                                    {airplaneSubmodelOptions}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderTop: '1px solid var(--color-secondary-light)', margin: '25px 0', width: '100%' }} />

                    <Table
                        header={Header}
                        data={componentInputFields}
                        title='Component List'
                        pageSize={DEFAULT_PAGE_SIZE}
                    />

                    <div className='add-page-button-group'>
                        <Button variant="outlined-primary" action={() => addFields(index)}>
                            <span className="material-icons-outlined" style={{ marginRight: '5px' }}>add</span><p>Add Component</p>
                        </Button>
                        <Button variant="outlined-danger" action={() => { removeAllFields(index) }}>
                            <span className="material-icons-outlined" style={{ marginRight: '5px' }}>clear</span><p>Clear All</p>
                        </Button>
                    </div>
                </div>

                <Modal modalOpen={modalOpen} modalClose={modalClose} title="Edit Mapped Component">
                    <EditMappedComponent
                        problemAirplane={problemData.airplane_model}
                        configuredComponentsData={configuredComponentsData}
                        configuredComponents={configuredComponents}
                        data={componentInputFields[modalData]}
                        airplaneOptions={airplaneModelOptions}
                        ataCodeOptions={ataCodeOptions}
                        index={modalData}
                        modalClose={(componentIndex, data) => {
                            saveComponentEdit(index, componentIndex, data);
                            handleModalClose();
                        }}
                    />
                </Modal>
            </>
        )
    else
        return (
            <>
                <Skeleton variant="rectangular" width='100%' height='200px' />
                <Divider />
                <Skeleton variant="rectangular" width='100%' height='200px' />
            </>
        )
}

export default ComponentMapper