import React, { useState, useEffect, useRef } from 'react'
import Table from '../UI-Elements/Table'
import Modal from '../UI-Elements/Modal'
import Button from '../UI-Elements/Button';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useAuth } from '../Auth/AuthProvider'
import { DEFAULT_PAGE_SIZE } from '../Data/Constants';

function ListParameterMappings(props) {
    let auth = useAuth();
    const { problem_data } = props;
    
    const [data, setData] = useState([])
    const [tableData, setTableData] = useState(null)

    useEffect(() => {
        console.log(problem_data)
        fetchAllParameters()
    }, [])

    async function fetchAllParameters() {
        console.log("fectching all mapped parameters...")
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/problem/parameter-mappings/'+problem_data['problem_id'])
            .then(res => {
                console.log('parameters', res.data.data)
                setData(res.data.data)
            })
            .catch(function (err) {
                const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
                openPopup({ type: 'error', text: msg})
            })
    }

    useEffect(() => {
        let temp = [...data].map((value, index) => {
            value["actions"] = <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div className='list-action' onClick={() => {confirmDelete(index) }}><span className="material-icons">delete</span></div>
            </div>
            return value
        })

        setTableData(temp)
    }, [data])

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const deleteModalOpen = useRef(null)
    const deletemodalClose = useRef(null)
    const [deleteModalData, setDeleteModalData] = useState(null)
    const [deleteLoading, setDeleteLoading] = useState(false)

    const handleDeleteModalOpen = (index) => {
        setDeleteModalData(index)
        deleteModalOpen.current()
    }

    const handleDeleteModalClose = () => {
        setDeleteModalData(null)
        deletemodalClose.current()
    }

    function confirmDelete(index) {
        handleDeleteModalOpen(index)
    }

    async function handleDelete() {
        setDeleteLoading(true)
        console.log('delete id', data[deleteModalData]['parameter_id'])
        try {
            const { res } = await auth.authAxios({
                method: 'delete',
                url: process.env.REACT_APP_BACKEND_API + '/parameter-mapping/' + data[deleteModalData]['parameter_id'],
                data: {
                    'problem_id': problem_data['problem_id'],
                    'user':auth.name
                }
            });
            console.log(res);
            handleDeleteModalClose()
            fetchAllParameters()
            openPopup({ type: 'success', text: 'Parameter Mapping Deleted Successfully' })
        } catch (err) {
            const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
            openPopup({ type: 'error', text: msg })
        }
        setDeleteLoading(false)
    }


    const Header = [
        { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true },
        // { Header: 'Parameter ID', accessor: 'parameter_id' },
        { Header: 'FDA Name', accessor: 'fda_name', canFilter: true },
        { Header: 'Boeing mnemonic code', accessor: 'boeing_mnemonic_code', canFilter: true },
        { Header: 'Airplane', accessor: 'airplane', canFilter: true },
        { Header: 'Parameter Description', accessor: 'parameter_desc', canFilter: true },
        { Header: 'ATA Code', accessor: 'parameter_ata_code', canFilter: true },
        { Header: 'IA Sensor Name', accessor: 'ia_sensor_name', canFilter: true },
        { Header: 'Aggregators', accessor: 'aggregators', Cell: ({ cell: { value } }) => { return value ? value.toString() : value }, canFilter: true },
        { Header: 'Additional Information', accessor: 'additional_info', canFilter: true },
    ]

    if (tableData) {
        return (
            <>
                <Table header={Header} data={tableData} title='' pageSize={DEFAULT_PAGE_SIZE} />
            
                <Modal modalOpen={deleteModalOpen} modalClose={deletemodalClose} title="Delete Parameter Mapping">
                    <>Deleting this mapping won't delete the Parameter.<br /> Are you sure you want to delete?<br /><br /></>

                    <Button variant="filled-danger" action={() => { handleDelete() }}>
                        {deleteLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Delete</p>}
                    </Button>
                </Modal>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
            </>
        )

    }
    else {
        return (<></>)
    }
}

export default ListParameterMappings