import React, { useState, useEffect, useRef } from 'react'

import Divider from '../UI-Elements/Divider'
import Button from '../UI-Elements/Button'
import Modal from '../UI-Elements/Modal';
import Table from '../UI-Elements/Table'

import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import '../../Styles/Components/Mapper.css'
import EditMappedDataframe from './EditMappedDataframe';
import AutoCompleteInput from '../UI-Elements/AutoCompleteInput';
import { DEFAULT_PAGE_SIZE } from '../Data/Constants';
import { IndeterminateCheckBox } from '@mui/icons-material';

function DataframeMapper(props) {
    const {
        selectedCustomer,
        customerData,
        dataframeData,
        tableDisplayData,
        index,
        validationError,
        handleCustomerChange,
        airplaneData,
        saveComponentEdit,
        removeFields,
        addFields,
        removeAllFields,
        removeMapping,
    } = props;

    //EditMappedComponent modal handler
    const modalOpen = useRef(null)
    const modalClose = useRef(null)
    const [modalData, setModalData] = useState(null)

    const handleModalOpen = (index) => {
        setModalData(index)
        modalOpen.current()
    }
    
    const handleModalClose = () => {
        modalClose.current();
        setModalData(null);
    }
    
    // sets Customer options
    if (customerData) {
        var CustomerOptions = customerData.map((customer, ind) => {
            return (<option key={ind} value={customer.customer_id}>{customer.gcs_name}</option>)
        })
    }
    
    // Airplane model dropdown
    if (airplaneData) {
        let airplaneKeys = Object.keys(airplaneData)
        var airplaneModelOptions = [...airplaneKeys].map((item, index) => {
            return (<option key={index} value={item}>{item}</option>)
        })
    }

    // Dataframe list table column headers, sets actions and status
    const Header = [
        { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true, Cell: (row) => {
            const dataframeInd = row.row.index
            return (
                <div style={{ display: 'flex' }}>
                    <div className='list-action' onClick={() => handleModalOpen(dataframeInd)}><span className="material-icons">launch</span></div>
                    <div className='list-action' onClick={() => removeFields(index, dataframeInd)}><span className="material-icons">delete</span></div>
                </div>
            )
        }},
        { Header: 'Status', accessor: 'configured', canFilter: false, disableSortBy: true, Cell: (row) => {
            const rowVal = row.value
            return (
                rowVal === true ? 
                <Chip label="configured" color="primary" variant="outlined" /> : 
                <Chip label="new" color="success" variant="outlined" />
            )
        }},
        { Header: 'Dataframe Name', accessor: 'name', canFilter: true },
        { Header: 'Filename', accessor: 'file', canFilter: true },
        { Header: 'GCS Name', accessor: 'gcs_name', canFilter: true },
        { Header: 'Imported', id: 'imported', accessor: d => d.imported != null ? d.imported.toString(): '', canFilter: true },
        { Header: 'Airplane', accessor: 'airplane_name', canFilter: true },
        { Header: 'Last Updated', id: 'last_updated', accessor: d => d.last_updated != null ? d.last_updated.toString(): '', canFilter: true },
    ]

    if (customerData && tableDisplayData)
        return (
            <>
                <div className='full-width'>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p className='table-title'>Customer Details</p>
                        <IconButton onClick={() => {removeMapping(index)}}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <br />
                    <div className='add-page-entity'>
                        <div className='add-page-form'>
                            <div className='input-element'>
                                <label className='input_label'>GCS Name*</label>
                                <select style={{ width: '250px' }} defaultValue={''} className={validationError ? 'input_box_error' : 'input_box'} value={selectedCustomer} onChange={e => handleCustomerChange(e, index)} name="customer_id">
                                    <option value="" disabled>Select Customer...</option>
                                    {CustomerOptions}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderTop: '1px solid var(--color-secondary-light)', margin: '25px 0', width: '100%' }} />

                    <Table
                        header={Header}
                        data={tableDisplayData == undefined ? []: tableDisplayData}
                        title='Dataframe List'
                        pageSize={DEFAULT_PAGE_SIZE}
                    />

                    <div className='add-page-button-group'>
                        <Button variant="outlined-primary" action={() => addFields(index)}>
                            <span className="material-icons-outlined" style={{ marginRight: '5px' }}>add</span><p>Add Dataframe</p>
                        </Button>
                        <Button variant="outlined-danger" action={() => { removeAllFields(index) }}>
                            <span className="material-icons-outlined" style={{ marginRight: '5px' }}>clear</span><p>Clear All</p>
                        </Button>
                    </div>
                </div>

                <Modal modalOpen={modalOpen} modalClose={modalClose} title="Edit Mapped Dataframe">
                    <EditMappedDataframe
                        dataframeData={dataframeData}
                        tableDisplayData={tableDisplayData}
                        data={tableDisplayData[modalData]}
                        airplaneOptions={airplaneModelOptions}
                        index={modalData}
                        modalClose={(dataframeInd, data) => {
                            saveComponentEdit(index, dataframeInd, data);
                            handleModalClose();
                        }}
                    />
                </Modal>
            </>
        )
    else
        return (
            <>
                <Skeleton variant="rectangular" width='100%' height='200px' />
                <Divider />
                <Skeleton variant="rectangular" width='100%' height='200px' />
            </>
        )
}

export default DataframeMapper