import React, { useEffect } from 'react'
import { TextField } from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";

import '../../Styles/Components/AutoCompleteInput.css'

/*
props:

options
validationError
width
label
value
handleChange
name
placeholder

*/

function AutoCompleteInput(props) {
    return (
        <Autocomplete 
            freeSolo
            options={props.options}
            value={props.value}
            onInputChange={(_event, value, reason) => {
              props.handleChange(props.name, value, reason)
            }}
            renderInput={(params) =>
                <div className='input-element'>
                  <label className='input_label '>{props.label}</label>
                  <div className='autocomplete'>
                    <TextField
                      {...params}
                      id={props.name}
                      className={props.validationError && props.value===''? 'input_box_error' : 'input_box'}
                      size='small'
                      style={{ width: props.width, border: '1px solid var(--color-secondary-light)' }}
                      type="text"
                      name={props.name}
                      placeholder={props.placeholder}
                      sx={{
                        input: {
                           "&::placeholder": {
                              opacity: 1,
                              fontSize: '13px'
                           },
                        }
                     }}
                    />
                  </div>
                </ div>
                
            }
        />
    )
}

export default AutoCompleteInput