import React, { useState } from 'react'

import { Divider, List, ListItem, ListItemText, Paper, Switch } from '@mui/material';

import Button from '../../Components/UI-Elements/Button'

import '../../Styles/Components/SettingList.css'

function SettingList(props) {
    const [newItem, setNewItem] = useState('');

    const addItem = () => {
        setNewItem('');
        props.saveCallback(newItem);
    }

    return (
        <>
            <div className='setting-panel'>
                <Paper elevation={3}>
                    <List className='setting-list'>
                        { props.data.length && props.data.map((item) => {
                            return (
                                <>
                                    <ListItem>
                                        <ListItemText
                                            primary={item}
                                        />
                                        <Switch disabled defaultChecked />
                                    </ListItem>
                                    <Divider />
                                </>
                                
                            )
                        })}
                    </List>
                </Paper>
            </div>
            <div className='input-panel'>
                <input
                    className='input_box'
                    style={{ height: '40px', margin: '5px' }}
                    placeholder={props.placeholder}
                    onChange={e => setNewItem(e.target.value)}
                    value={newItem}
                />
                <Button
                    variant="outlined-primary"
                    action={() => addItem()}
                    style={{ height: '40px' }}
                >
                    Add
                </Button>
            </div>
        </>
    );
}

export default SettingList;
