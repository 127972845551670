import React from 'react'

function Dashboard() {

  return (
    <>
      <div className='add-page-subcontainer'>
        <img src='/assets/IA.PNG' alt='IA' width="100%"></img>
        <div style={{ margin: '10px' }}>
          <h3 style={{ fontSize: '50px', fontWeight: '400', margin: '20px 0 30px' }}>Insight Accelerator</h3>
          <p style={{ fontSize: '20px', fontWeight: '300' }}>
            Insight Accelerator (IA) is an advanced analytics solution offering QAR/CPL analysis that enables operators to derive prognostic insights and create alerting algorithms for effective predictive maintenance - all without the need for specialized data science or programming skills.
            <br /><br />
            Harness the power of big data analysis to create alerts for critical issues without need for Data Scientists. Enable faster data analysis by reducing time needed in preparation work.
          </p>
        </div>
        {/* <img src='/assets/IA-dash.PNG' alt='IA' width="100%"></img> */}

      </div>
    </>
  )
}

export default Dashboard