import React, { useState, useEffect } from 'react'

import '../Styles/Components/Topbar.css'
import { useAuth } from './Auth/AuthProvider'

/*

props:

toggleSidebar - callback, handles sidebar opening and closing

this component uses the useAuth context from AuthProvider component to display name and email of logged in user

*/

function Topbar(props) {
  let auth = useAuth();

  return (
    <div className='topbar-container'>
      <div style={{ display: 'flex',alignItems:'center' }}>
        <span className="material-icons-outlined" style={{ cursor: 'pointer', marginRight: '20px' }} onClick={props.toggleSidebar}>menu</span>
        <div className='topbar-title'>IA Configuration Tool</div>
        <div>
        </div>
      </div>

      <div className='topbar-user'>

        <div className='topbar-user-details'>
          <p className='topbar-user-email'>{auth.name}</p>
          <p className='topbar-user-cred'>{auth.user}</p>
        </div>
        <div className='topbar-user-icon'>
          {/* Dicebear user icon documentation : https://avatars.dicebear.com/docs */}
          <img src={"https://avatars.dicebear.com/api/jdenticon/" + auth.user + ".svg"} alt='user-icon'></img>
        </div>
      </div>
    </div>
  )
}

export default Topbar