import React, { useState, useEffect, useRef } from 'react'
import { Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {useAuth} from "../../Components/Auth/AuthProvider";
import Button from '../../Components/UI-Elements/Button'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Modal from '../../Components/UI-Elements/Modal';
import Table from '../../Components/UI-Elements/Table';
import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';

import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

const DEFAULT_COMPARED_FILE_NAME = 'categories-mapping';

function ListConfig() {
    let auth = useAuth();

    let [configData, setConfigData] = useState(null);
    let [selectedConfigs, setSelectedConfigs] = useState([]);
    let [loading, setLoading] = useState(false);
    let [comparedFileNames, setComparedFileNames] = useState([]);
    let [comparedFileName, setComparedFileName] = useState('');
    let [comparedFiles, setComparedFiles] = useState({});

    const modalOpen = useRef(null)
    const modalClose = useRef(null)

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    useEffect(() => {
        fetchConfigs();
    }, []);

    const checkboxOnChange = (index) => {
        let temp = [...selectedConfigs];
        let idx = temp.indexOf(index);
        if (idx !== -1) {
            temp.splice(idx, 1);
        } else {
            temp.push(index);
        }
        setSelectedConfigs(temp);
    }

    const onChangeComparedFileName = (e) => {
        setComparedFileName(e.target.value);
    }

    const sortObj = (obj) => {
        if (Array.isArray(obj)) {
            return obj.sort();
        } else if(Object(obj) === obj) {
            const sorted = {};
            const keys = Object.keys(obj).sort();
            keys.forEach(key => sorted[key] = sortObj(obj[key]));
            return sorted;
        } else {
            return obj;
        }
    }

    const compareConfigs = (_e) => {
        setLoading(true);
        let fileProms = [];
        let fileNames = [];
        let files = {};
        try {
            selectedConfigs.map((i) => {
                for(const [key, value] of Object.entries(configData[i].files)) {
                    fileNames.push(key);
                    fileProms.push(
                        auth.authAxios({
                            method: 'post',
                            url: process.env.REACT_APP_BACKEND_API + '/download',
                            data: {'file_path': value},
                            headers:{
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                            }
                        }))
                        .then((res) => {
                            files[i + key] = sortObj(res.data);
                        })
                }
                return;
            });
            Promise.all(fileProms).then((_values) => {
                setComparedFiles(files);
                setComparedFileNames([...new Set(fileNames)]);
                setComparedFileName(DEFAULT_COMPARED_FILE_NAME);
                
                modalOpen.current();
            });
        } catch (err) {
            const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
            openPopup({ type: 'error', text: msg })
        }
        setLoading(false);
    }

    const fetchConfigs = () => {
        console.log("fectching configs...");
        auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/config')
            .then(res => {
                let data = [];
                res.data.forEach(element => {
                    data.push({
                        'id': element.id,
                        'gcs_name': element.gcs_name,
                        'created': element.created,
                        'modified': element.modified,
                        'files': element.files,
                        'is_global': element.is_global,
                        'problems': element.problems
                    });
                })
                setConfigData(data.reverse());
            })
            .catch(function (error) {
                const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
                openPopup({ type: 'error', text: msg})
            });
    };

    const Header = [
        { Header: '', accessor: 'checkbox', canFilter: false, disableSortBy: true, Cell: (row) => {
            return (
              <input 
                type='checkbox'
                checked={selectedConfigs.includes(row.row.index)}
                disabled={selectedConfigs.length == 2 && !selectedConfigs.includes(row.row.index)}
                onChange={() => checkboxOnChange(row.row.index)}
              />
            )
        }},
        { Header: 'ID', accessor: 'id', canFilter: true },
        { Header: 'GCS Name', accessor: 'gcs_name', canFilter: true },
        { Header: 'Created', accessor: 'created', canFilter: true },
        { Header: 'Modified', accessor: 'modified', canFilter: true },
        { Header: 'Problems', accessor: 'problems', Cell: ({ cell: { value } }) => { return value.toString() }, canFilter: true },
        { Header: 'Files', accessor: 'files', Cell: ({ cell: { value } }) => {
            return <>
                <Typography>Categories Enabled: {value['categories-enabled']}</Typography>
                <Typography>Categories Mapping: {value['categories-mapping']}</Typography>
                <Typography>Merged Categories Mapping: {value['merged-categories-mapping']}</Typography>
            </>;
        }, canFilter: true },
        { Header: 'Is Global Config', accessor: 'is_global', canFilter: true },
    ];

    const newStyles = {
        variables: {
            light: {
                codeFoldGutterBackground: "#6F767E",
                codeFoldBackground: "#E2E4E5"
            }
        }
    };
    
    if(configData) {
        return (
            <>
                <div className='add-page-subcontainer'>
                    <div className='full-width'>
                        <Table
                            header={Header}
                            data={configData}
                            title='Generated Configurations'
                            pageSize={DEFAULT_PAGE_SIZE}
                        />
                    </div>

                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            variant={selectedConfigs.length < 2 ? "disabled-style" : "outlined-primary"}
                            disabled={selectedConfigs.length < 2}
                            action={compareConfigs}
                        >
                            {loading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading...</p></div> : <p>Compare</p>}
                        </Button>
                    </div>
                    
                </div>

                
                <Modal modalOpen={modalOpen} modalClose={modalClose} width='1000px'>
                    <Select
                        className='input_box'
                        style={{ height: '50px', width: '300px' }}
                        value={comparedFileName}
                        onChange={onChangeComparedFileName}
                    >
                        {
                            comparedFileNames.length && comparedFileNames.map((name, index) => {
                                return <MenuItem key={index} value={name}>{name}</MenuItem>
                            })
                        }
                    </Select>
                    <div style={{ width: '1000px', height: '800px' }}>
                        <ReactDiffViewer
                            oldValue={JSON.stringify(comparedFiles[selectedConfigs[0]+comparedFileName], undefined, 4)}
                            newValue={JSON.stringify(comparedFiles[selectedConfigs[1]+comparedFileName], undefined, 4)}
                            splitView={true}
                            showDiffOnly={false}
                            compareMethod={DiffMethod.WORDS}
                            styles={newStyles}
                            leftTitle={configData[selectedConfigs[0]] ? configData[selectedConfigs[0]].created : ''}
                            rightTitle={configData[selectedConfigs[1]] ? configData[selectedConfigs[1]].created : ''}
                        />
                    </div>
                    
                </Modal>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
            </>
        );
    } else {
        return (
            <>
                <div className='list-page-subcontainer' style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading....</p></div>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>
            </>
        )
    }
}

export default ListConfig;
