import React, { useState, useEffect, useRef } from 'react'
import Modal from '../../Components/UI-Elements/Modal';
import Table from '../../Components/UI-Elements/Table'
import Button from '../../Components/UI-Elements/Button'
import DataExport from '../../Components/UI-Elements/DataExport';
import SimpleBackdrop from '../../Components/UI-Elements/SimpleBackdrop';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';

import '../../Styles/Pages/ListPage.css'
import EditParameter from './EditParameter';
import { useAuth } from '../../Components/Auth/AuthProvider'
import { DEFAULT_PAGE_SIZE } from '../../Components/Data/Constants';
import { generateOptions } from '../../Common/Helper';

function ListParameter() {
  let auth = useAuth();

  const modalOpen = useRef(null)
  const modalClose = useRef(null)
  const [modalData, setModalData] = useState(null)
  const [data, setData] = useState([])

  const [alert, setAlert] = useState({ type: 'error', text: '' })
  const [popup, setPopup] = useState(false)

  const [tableData, setTableData] = useState(null)

  const [customerData, setCustomerData] = useState(null)
  const [customerFilter, setCustomerFilter] = useState(null)

  const [mappedSelected, setMappedSelected] = useState(false)
  const [excludeRawSelected, setExcludeRawSelected] = useState(true)
  const [assignedNameSelected, setAssignedNameSelected] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [flightPhaseOptions, setFlightPhaseOptions] = useState(null);
  const [aggregatorOptions, setAggregatorOptions] = useState(null);
  const [ataCodeOptions, setAtaCodeOptions] = useState(null);

  const [customFileName, setCustomFileName] = useState(null);

  const handleModalOpen = (index) => {
    setModalData({ ...data[index] })
    modalOpen.current()
  }

  const handleModalClose = (index) => {
    setModalData("")
    modalClose.current()
  }

  const fileExportDate = () => { 
    const today = new Date(); 
    const day = String(today.getDate()).padStart(2, '0'); 
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const year = today.getFullYear(); 
   
    const formattedDate = `${day}${month}${year}`; 
    return formattedDate; 
  }

  useEffect(() => {
    fetchCustomers()
    fetchAllParameters()
    fetchSettings()
  }, [])

  useEffect(()=>{ 
    const customer=customerData && customerData.find((customer)=>customer.customer_id === customerFilter) 
    const filename_date=fileExportDate(); 
    if (customer) { 
      setCustomFileName(`${customer.gcs_name.split(' ').join('_')}_${filename_date}`.toLowerCase()+"_ParameterExport.csv") 
    } 
    else{ 
      setCustomFileName(`all_customers_${filename_date}`.toLowerCase()+"_ParameterExport.csv") 
    } 
  },[customerFilter])

  async function fetchAllParameters() {
    setSubmitLoading(true)
    console.log("fectching all parameters...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API+`/parameter?detailed=true&all=true&mapped=${ mappedSelected === true ? 1 : 0}&exclude_raw=${ excludeRawSelected === true ? 1 : 0}&assigned_name=${ assignedNameSelected === true ? 1 : 0}`)
      .then(res => {
        console.log('parameters', res.data.data)
        let temp = [...res.data.data];
        temp.map((p) => {
          p.gcs_names_temp=p.gcs_names ?? 'Non Assign';
          p.ata_code_export = p.ata_code ? p.ata_code.split(',').join('|') : '';
          p.aggregator_export = p.aggregators.join('|');
          p.phase_export = p.customers ? Object.values(p.customers).map((c)=>JSON.stringify(c)):'';
        });
        setData(temp)
        setSubmitLoading(false)
      })
      .catch(function (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
        openPopup({ type: 'error', text: msg})
        setSubmitLoading(false)
      })
  }

  async function fetchCustomers() {
    console.log("fectching customer data...")
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/all-customer')
      .then(res => {
        // console.log(res.data.data)
        let data = []
        res.data.data.forEach(element => {
          data.push({
            'customer_id': element.id,
            'gcs_name': element.gcs_name,
            'email': element.contact_email
          })
        })
        console.log('customer data', data)
        setCustomerData(data)
      })
      .catch(function (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
        openPopup({ type: 'error', text: msg})
      })
  }

  async function fetchSettings() {
    console.log("fetching settings data...");
    auth.authAxios.get(process.env.REACT_APP_BACKEND_API + '/settings')
      .then((res) => {
        setAtaCodeOptions(generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'ATA Codes')?.value), 'json'));
        setFlightPhaseOptions(generateOptions(JSON.parse(res.data.data.find((s) => s.name === 'Flight Phases')?.value), 'json'));
        setAggregatorOptions(generateOptions(res.data.data.find((s) => s.name === 'Aggregators')?.value?.split(',')));
      })
      .catch(function (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg
        openPopup({ type: 'error', text: msg})
      })
  }
  
  useEffect(() => {
    fetchAllParameters()
  }, [mappedSelected, excludeRawSelected, assignedNameSelected])

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  useEffect(() => {
    let temp = [...data].map((value, index) => {
      value["actions"] =  <div style={{ display: 'flex',justifyContent:'space-around'  }}>
                            <div className='list-action' onClick={() => handleModalOpen(index)}><span className="material-icons">launch</span></div>
                          </div>
      return value
    })

    setTableData(temp)
  }, [data])

  const deleteModalOpen = useRef(null)
  const deletemodalClose = useRef(null)
  const [deleteModalData, setDeleteModalData] = useState(null)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const dataframeStyle = {
    display: 'flex',
    justifyContent:'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    flexDirection: "column",
    flexWrap: "wrap",
    overflowY: 'visible',
    overflowX: 'visible',
  };
  const Header = [
    { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true },
    // { Header: 'Parameter ID', accessor: 'parameter_id' },
    { Header: 'FDA Name', accessor: 'fda_name', canFilter: true },
    { Header: 'Boeing Mnemonic Code', accessor: 'boeing_mnemonic_code', canFilter: true },
    { Header: 'Airplane', accessor: 'airplane', canFilter: true },
    { Header: 'Parameter Description', accessor: 'parameter_desc', canFilter: true },
    { Header: 'ATA Code', accessor: 'ata_code', canFilter: true },
    { Header: 'IA Sensor Name', accessor: 'ia_sensor_name', canFilter: true },
    { Header: 'Aggregators', accessor: 'aggregators', Cell: ({ cell: { value } }) => { return value ? value.toString() : value }, canFilter: true },
    { Header: 'Additional Information', accessor: 'additional_info', canFilter: true },
    { Header: 'Dataframes', accessor: 'dataframes',Cell: ({ cell: { value } }) => { return value ?
        <div style={{maxHeight: "200px", overflowY: 'scroll',overflowX: 'scroll'}}> {Object.keys(value).map((k) =>
          <div style={dataframeStyle}>{k}: {Object.keys(value[k]).map((c) =>
            <div style={{paddingLeft: '10px'}}>
              • <font color="#0400ff">{c}</font>: {value[k][c]}
            </div>)}
          </div>)}
        </div>
        : null }, canFilter: true },
    { Header: 'GCS Name', accessor: 'gcs_names_temp',Cell: ({ cell: { value } }) => { return value && value.toString() }, canFilter: true }
  ]

  const HeaderWithSpecifiedCustomer = [
    ...Header,
    { Header: 'Flight Phases', accessor: 'customers', Cell: ({ cell: { value } }) => { return value && value[customerFilter] ? Object.keys(value[customerFilter]).map((k) => <p>{k}: {value[customerFilter][k]}</p>) : null }, canFilter: true }
  ]

  if (customerData) {
    var CustomerOptions = customerData.map((customer, index) => {
      return (<option key={index} value={parseInt(customer.customer_id)}>{customer.gcs_name}</option>)
    })
  }

  if (!tableData || !CustomerOptions || !flightPhaseOptions || !aggregatorOptions || !ataCodeOptions) {
    return (
      <>
        <div className='list-page-subcontainer' style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span><p>Loading....</p></div>
        </div>
      </>
    )
  }
  else {
    return (
      <>
        <div className='list-page-subcontainer'>
          <Table
            header={customerFilter ? HeaderWithSpecifiedCustomer : Header}
            data={customerFilter ? tableData.filter((p) => customerFilter==10 ? !p.customers  : p.customers && Object.keys(p.customers).includes(customerFilter.toString())) : tableData}
            title='Parameter'
            pageSize={DEFAULT_PAGE_SIZE}
            customContent={
              <span>
                <div className='input-element'>
                  <label className='input_label'>Customer</label>
                  <select style={{ width: '250px' }} className='input_box' value={customerFilter || ''} onChange={event => setCustomerFilter(parseInt(event.target.value))} name="customer_id">
                    <option value="">Filter by Customer...</option>
                    {CustomerOptions}
                  </select>
                </div>
                <span className="checkbox-wrapper"> 
                  <Checkbox 
                    checked={mappedSelected} 
                    id="mapped_selection"
                    onChange={(event) => setMappedSelected(event.target.checked)}
                  />
                  <label className='input_label'>Exclude unmapped parameters</label>
                  <Checkbox 
                    checked={excludeRawSelected} 
                    id="exclude_raw_selection"
                    onChange={(event) => setExcludeRawSelected(event.target.checked)}
                  />
                  <label className='input_label'>Exclude 'RAW' prefixes</label>
                  <Checkbox 
                    checked={assignedNameSelected} 
                    id="exclude_assigned_selection"
                    onChange={(event) => setAssignedNameSelected(event.target.checked)}
                  />
                  <label className='input_label'>Exclude unassigned IA names</label>
                </span>
              </span>

      
            }
          />
          <Modal modalOpen={modalOpen} modalClose={modalClose} title="Edit Parameter">
            <EditParameter
              data={modalData}
              modalClose={handleModalClose}
              refresh={fetchAllParameters}
              alert={openPopup}
              flightPhaseOptions={flightPhaseOptions}
              aggregatorOptions={aggregatorOptions}
              ataCodeOptions={ataCodeOptions}
              customerFilter={customerFilter}
            />
          </Modal>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <DataExport
              data={customerFilter ? data.filter((p) => customerFilter==10 ? !p.customers  : p.customers && Object.keys(p.customers).includes(customerFilter.toString())) : data}
              columnMapping={{
                fda_name: 'FDA Name',
                boeing_mnemonic_code: 'Boeing Mnemonic Code',
                airplane: 'Airplane',
                parameter_desc: 'Parameter Description',
                ata_code_export: 'ATA Code',
                ia_sensor_name: 'IA Sensor Name',
                aggregator_export: 'Aggregators',
                gcs_names_temp:'GCS Names',
                phase_export: 'Flight Phases',
              }}
              fileName={customFileName}
              stringifyObject={true}
              arrayDelimiter='|'
              text='Export Parameters'
            />
          </div>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
        <SimpleBackdrop loading={submitLoading} handleBackdropClose={ (event) => setSubmitLoading(false) }/>
      </>
    )
  }
}

export default ListParameter