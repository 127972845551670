import React, { useState, useEffect } from 'react'
import Button from '../../Components/UI-Elements/Button'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { useAuth } from '../../Components/Auth/AuthProvider'

function EditComponent(props) {
  let auth = useAuth();

  const [data, setData] = useState({
    component_name: '',
    part_no: '',
    airplane: '',
    component_position_list: '',
    ata_code: '',
    component_event_tag_rules: '',
  })
  const [alert, setAlert] = useState({ type: 'error', text: '' })
  const [popup, setPopup] = useState(false)

  const [submitLoading, setSubmitLoading] = useState(false)
  const [airplaneData, setAirplaneData] = useState(null);

  const openPopup = (temp) => {
    setAlert(temp)
    setPopup(true)
  }

  useEffect(() => {
    let temp = {...props.data};
    temp.component_event_tag_rules = JSON.stringify(temp.component_event_tag_rules);
    temp.component_position_list = temp.component_position_list.join(',');
    setData(temp);
  }, [])


  const handleFormChange = (event) => {
    let temp = { ...data };
    temp[event.target.name] = event.target.value ?? '';
    setData(temp);
  }

  const handleSubmit = () => {
    setSubmitLoading(true)
    console.log("submitted data", data)
    submit()
  }

  async function submit() {
    console.log("submit")
    let validateError = validateForm()
    console.log('validation error : ', validateError)

    if (!validateError) {

      console.log("no error")

      var req = {
        'component_id': data['component_id'],
        'component_name': data['component_name'],
        'part_no': data['part_no'],
        'airplane': data['airplane'],
        'component_position_list': data['component_position_list'].toUpperCase(), // it's a convention to use positions in upper case
        'ata_code': data['ata_code'],
        'component_event_tag_rules': data['component_event_tag_rules'].replace(/^"(.+)"$/,'$1'),
      }

      // console.log('request : ', req)

      var fin_req = {
        'components':[req],
        'user':auth.name
      }

      try {
        const res = await auth.authAxios({
          method: 'put',
          url: process.env.REACT_APP_BACKEND_API + '/component/' + data['component_id'],
          data: fin_req
        });

        console.log('response : ', res.data);
        props.modalClose()
        props.refresh()
        props.alert({ type: 'success', text: 'Component Updated Successfully' })

      } catch (err) {
        const msg = err.response.data.error ? err.response.data.error : err.response.data.msg;
        openPopup({ type: 'error', text: msg })
      } finally {
        setSubmitLoading(false)
      }
    }
    else {
      setSubmitLoading(false)
    }
  }

  function validateForm() {
    let res = false;

    if(
      // data['component_name']== '' ||
      data['airplane'] == '' ||
      data['part_no'] == '')
      {
        openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
        res = true
      }

    return res;
  }

  if (airplaneData) {
    let airplaneKeys = Object.keys(airplaneData)

    var airplaneModelOptions = [...airplaneKeys].map((item, index) => {
        return (<MenuItem key={index} value={item}>{item}</MenuItem>)
    })
  }

  if (data)
    return (
      <div>
        <div className='add-page-entity'>
          <div className='add-page-form'>
            <div className='input-element'>
              <label className='input_label'>Component Name</label>
              <input style={{ width: '250px' }} value={data.component_name} onChange={event => handleFormChange(event)} className={data.component_name == '' ? 'input_box_error' : 'input_box'} type="text" name="component_name" placeholder="Enter Component Name..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Part No. *</label>
              <input style={{ width: '250px' }} className={data.part_no == '' ? 'input_box_error' : 'input_box'} value={data.part_no} onChange={event => handleFormChange(event)} type="text" name="part_no" placeholder="Enter Part Number..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>Airplane Model*</label>
              <Select
                name="airplane"
                className={data.airplane == '' ? 'input_box_error' : 'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select Airplane Model..."
                value={data.airplane || ''}
                options={props.airplaneOptions}
                onChange={event => handleFormChange(event)}
              >
                {props.airplaneOptions}
              </Select>
            </div>
            <div className='input-element'>
              <label className='input_label'>Component Position List</label>
              <input style={{ width: '250px' }} value={data.component_position_list} onChange={event => handleFormChange(event)} className={'input_box'} type="text" name="component_position_list" placeholder="Enter Component Positions..." />
            </div>
            <div className='input-element'>
              <label className='input_label'>ATA Code</label>
              <Select
                name="ata_code"
                className={'input_box'}
                style={{ width: '250px ', height: '40px' }}
                size='small'
                placeholder="Select ATA Code..."
                value={data.ata_code || []}
                options={props.ataCodeOptions}
                onChange={event => handleFormChange(event)}
              >
                {props.ataCodeOptions}
              </Select>
            </div>
            {/* <div className='input-element'>
              <label className='input_label'>Event Tag Rules</label>
              <input style={{ width: '250px' }} value={data.component_event_tag_rules} onChange={event => handleFormChange(event)} className='input_box' type="text" name="component_event_tag_rules" placeholder="Enter Event Tag Rules..."/>
            </div> */}
          </div>
        </div>
        <div style={{ marginTop: '21px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <Button variant="filled-primary" action={() => { handleSubmit() }}>
            {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Submit</p>}
          </Button>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
      </div>
    )
  else
    return null
}

export default EditComponent