import React,{useState, useEffect} from 'react'

// stores route related Breadcrumb information
import { BreadcrumbData } from './BreadcrumbData';

import '../Styles/Components/Breadcrumb.css'

/*
props:

data - route passed from Layout component using useLocation hook of react-router-dom

*/

function Breadcrumb(props) {
  const [path, setPath] = useState("");
  const [pathExists, setPathExists] = useState(false);

  // check if route data exists in BreadcrumbData whenever route changes
  useEffect(() => {
    if(props.data in BreadcrumbData)
    {
      setPathExists(true)
      setPath(props.data)
    }
  }, [props.data])
  
  if(pathExists)
  return (
    <div className='breadcrumb-container'>
      <div className='breadcrum-title'>{BreadcrumbData[path].title}</div>
      <div className='breadcrumb-divider'>|</div>
      <div className='breadcrumb-path'>
        {BreadcrumbData[path].breadcrumb}
      </div>

    </div>
  )
  else
  return(<></>)
}

export default Breadcrumb