import React, { useState, useEffect } from 'react';

import Button from '../../Components/UI-Elements/Button';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useAuth } from '../../Components/Auth/AuthProvider';

function EditCustomer(props) {
    let auth = useAuth();

    const [data, setData] = useState({
        'customer_id': '',
        'customer_name': '',
        'gcs_name': '',
        'contact_name': '',
        'email': '',
        'properties': '',
        'event_tag_rules': '',
    })
    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)

    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        let temp = {...props.data};
        if (temp) {
            temp.event_tag_rules = temp.event_tag_rules ? JSON.stringify(temp.event_tag_rules) : '';
            temp.properties = temp.properties ? JSON.stringify(temp.properties) : '';
        }
        setData(temp);
    }, [])

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const handleFormChange = (event) => {
        let temp = { ...data };
        temp[event.target.name] = event.target.value ?? '';
        setData(temp);
    }

    const handleSubmit = () => {
        setSubmitLoading(true)
        console.log("submitted data", data)
        submit()
    }

    async function submit() {
        console.log("submit")
        let validateError = validateForm()
        console.log('validation error : ', validateError)

        if (!validateError) {

            console.log("no error")

            var req = {
                'customer_name': data.customer_name,
                'gcs_name': data.gcs_name,
                'contact_name': data.contact_name,
                'email': data.email,
                'properties': JSON.parse(data.properties || '{}'),
                'event_tag_rules': JSON.parse(data.event_tag_rules || '{}'),
                'user': auth.name
            }

            // console.log('request : ', req)

            try {
                const res = await auth.authAxios({
                  method: 'put',
                  url: process.env.REACT_APP_BACKEND_API + '/customer/' + data['customer_id'],
                  data: req
                });
            
                console.log('response : ', res.data);
                props.modalClose()
                props.refresh()
                props.alert({ type: 'success', text: 'Customer Updated Successfully' })
            
            } catch (error) {
                const msg = error.response.data.error ? error.response.data.error : error.response.data.msg
                openPopup({ type: 'error', text: msg})
                setSubmitLoading(false)
            }
        } else {
            setSubmitLoading(false)
        }
    }

    function validateForm() {
        let res = false;

        if(data['customer_name']== '')
        {
            openPopup({ type: 'error', text: 'Error!! Check Input Fields' })
            res = true
        }

        return res;
    }

    if (data)
        return (
        <div>
        <div className='add-page-entity'>
          <div className='add-page-form'>
            <div className='input-element'>
                <label className='input_label'>Customer Name*</label>
                <input style={{ width: '250px' }} value={data.customer_name} onChange={event => handleFormChange(event)} className={data.customer_name == '' ? 'input_box_error' : 'input_box'} type="text" name="customer_name" placeholder="Enter Customer Name..." />
            </div>
            <div className='input-element'>
                <label className='input_label'>GCS Name</label>
                <input style={{ width: '250px' }} value={data.gcs_name} onChange={event => handleFormChange(event)} className={'input_box'} type="text" name="gcs_name" placeholder="Enter Customer GCS Abbreviation..." />
            </div>
            <div className='input-element'>
                <label className='input_label'>Contact Name</label>
                <input style={{ width: '250px' }} value={data.contact_name || ''} onChange={event => handleFormChange(event)} className={'input_box'} type="text" name="contact_name" placeholder="Enter Contact Name..." />
            </div>
            <div className='input-element'>
                <label className='input_label'>Contact Email</label>
                <input style={{ width: '250px' }} value={data.email || ''} onChange={event => handleFormChange(event)} className={'input_box'} type="text" name="email" placeholder="Enter Contact Email..." />
            </div>
            <div className='input-element'>
                <label className='input_label'>Properties</label>
                <input style={{ width: '250px' }} value={data.properties || ''} onChange={event => handleFormChange(event)} className={'input_box'} type="text" name="properties" placeholder="Enter Properties..." />
            </div>
            <div className='input-element'>
                <label className='input_label'>Event Tag Rules</label>
                <input style={{ width: '250px' }} value={data.event_tag_rules || ''} onChange={event => handleFormChange(event)} className={'input_box'} type="text" name="event_tag_rules" placeholder="Enter Event Tag Rules..." />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '21px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <Button variant="filled-primary" action={() => { handleSubmit() }}>
            {submitLoading ? <div style={{ display: 'flex', alignItems: 'center' }}><span className="material-icons-outlined loader" style={{ marginRight: '5px' }}>autorenew</span></div> : <p>Submit</p>}
          </Button>
        </div>

        <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
          <Alert severity={alert['type']} sx={{ width: '100%' }}>
            {alert['text']}
          </Alert>
        </Snackbar>
        </div>
    )
    else
        return null
}

export default EditCustomer;
