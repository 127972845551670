import React, { useState, useEffect } from 'react'
import Button from '../UI-Elements/Button'
import AutoCompleteInput from '../UI-Elements/AutoCompleteInput';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';

/*
props:

data
dataframeData
tableDisplayData
index
airplaneOptions
modalClose

*/
function EditMappedDataframe(props) {

    const dataframeTemplate = {
        id: '',
        dataframe_id: '',
        gcs_name: '',
        name: '', 
        file: '',
        imported: '', 
        last_updated: '',
        airplane_name: '',
    };

    const [data, setData] = useState(dataframeTemplate)

    useEffect(() => {
        let temp = {...props.data};
        setData(temp);
    }, [props])

    // Handles form change
    const handleFormChange = (name, value, reason) => {
        let temp = {...data};
        if( reason === 'reset' && value && props.dataframeData.filter(item => item.file.includes(value)).length > 0 ) {
            
            const tableVals = props.tableDisplayData.find(item => item.file == value);
            const defaultVals = props.dataframeData.find(item => item.file == value);
            let resetVals = null;

            if (tableVals != undefined) {
                resetVals = tableVals;
            } else {
                resetVals = defaultVals;
            }

            setData(resetVals);
        } else if(reason === 'clear') {
            setData(dataframeTemplate);
        } 
    }

    const handleAirplaneChange = (event) => {
        let temp = {...data};
        temp['airplane_name'] = event.target.value;
        setData(temp);
    }

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)
    const [validationError, setValidationError] = useState(false)

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    // Handles save with basic form check. Triggered when user clicks save
    const handleSave = () => {
        let validateError = validateForm()

        if(!validateError)
        {
            let temp = {...data};
            props.modalClose(props.index, temp);
        }
        else
        {
            openPopup({ type: 'error', text: 'Error!! Check Fields Again' })
        }
    }

    // Validates dataframe edit to make sure it doesn't contain any errors
    function validateForm(){
        if (
            data['file'] === '' ||
            data['airplane_name'] === ''
            ) {
            setValidationError(true)
            return true;
        }
        else
        {
            return false;
        }
    }

    if (data)
        return (
            <div>
                {props.tableDisplayData.some((row) => row.file.includes(data.file))?<><Alert severity="warning">This Dataframe is already configured.<br/> Any changes will update the existing configurations</Alert><br/></>:''}
                <div className='add-page-entity'>
                    <div className='add-page-form'>
                        <AutoCompleteInput
                            options={props.dataframeData.map(item => item.file)}
                            validationError={validationError}
                            width={'250px'}
                            label={'Dataframe Filename'}
                            value={data.file}
                            handleChange={(n, v, r)=>{handleFormChange(n, v, r)}}
                            name={'dataframe_filename'}
                            placeholder={"Enter Dataframe Filename..."}
                        />
                        <div className='input-element'>
                            <label className='input_label'>Airplane Model</label>
                            <select style={{ width: '250px' }} className={validationError.length > 0 && data.airplane_name == '' ? 'input_box_error' : 'input_box'} value={data.airplane_name || ''} onChange={event => handleAirplaneChange(event)} name="airplane">
                                <option value="" disabled>Select Airplane Model...</option>
                                {props.airplaneOptions}
                            </select>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '21px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <Button variant="outlined-primary" action={handleSave}>
                        <p>Save</p>
                    </Button>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>


            </div>
        )
}

export default EditMappedDataframe