import MenuItem from '@mui/material/MenuItem';

export const generateOptions = (options, elementType) => {
    return options.map((option, index) => {
        if(elementType == 'json') {
            return (<MenuItem key={index} value={option.value}>{option.value + ' - ' + option.name}</MenuItem>);
        } else {
            return (<MenuItem key={index} value={option}>{option}</MenuItem>);
        }
    });
};
