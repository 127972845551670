import React, { useState, useEffect, useRef } from 'react'

import Divider from '../UI-Elements/Divider'
import Button from '../UI-Elements/Button'
import Modal from '../UI-Elements/Modal';
import Table from '../UI-Elements/Table'

import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import '../../Styles/Components/Mapper.css'
import EditMappedParameter from './EditMappedParameter'
import AutoCompleteInput from '../UI-Elements/AutoCompleteInput';
import { DEFAULT_PAGE_SIZE } from '../Data/Constants';

function ParameterMapper(props) {
    const {
        airplaneData,
        customerData,
        configuredParameters,
        configuredParametersData,
        configuredProblemCustomer,
        configuredProblems,
        index,
        problemData,
        validationError,
        handleProblemChange,
        parameterData,
        saveParameterEdit,
        removeFields,
        addFields,
        removeAllFields,
        removeMapping,
        refresh,
        aggregatorOptions,
        flightPhaseOptions,
        ataCodeOptions
    } = props;
    const [parameterInputFields, setParameterInputFields] = useState(parameterData.map((value, parameterInd) => {
        value["actions"] = <div style={{ display: 'flex' }}>
            <div className='list-action' onClick={() => handleModalOpen(parameterInd)}><span className="material-icons">launch</span></div>
            <div className='list-action' onClick={() => removeFields(index, parameterInd)}><span className="material-icons">delete</span></div>
        </div>
        value["status"] = configuredParametersData.filter((p) => p.airplane == value.airplane && p.boeing_mnemonic_code == value.boeing_mnemonic_code).length > 0;
        value["status_chip"] = value["status"] ? <Chip label="configured" color="primary" variant="outlined" /> : <Chip label="new" color="success" variant="outlined" />

        return value
    }))

    useEffect(() => {
        let temp = [...parameterData].map((value, parameterInd) => {
            value["actions"] = <div style={{ display: 'flex' }}>
                <div className='list-action' onClick={() => handleModalOpen(parameterInd)}><span className="material-icons">launch</span></div>
                <div className='list-action' onClick={() => removeFields(index, parameterInd)}><span className="material-icons">delete</span></div>
            </div>
            value["status"] = configuredParametersData.filter((p) => p.airplane == value.airplane && p.boeing_mnemonic_code == value.boeing_mnemonic_code).length > 0;
            value["status_chip"] = value["status"] ? <Chip label="configured" color="primary" variant="outlined" /> : <Chip label="new" color="success" variant="outlined" />

            return value
        })
        // console.log('temp',temp)
        setParameterInputFields(temp)
    }, [refresh])

    //modal handler
    const modalOpen = useRef(null)
    const modalClose = useRef(null)
    const [modalData, setModalData] = useState(null)
    const handleModalOpen = (index) => {
        setModalData(index)
        modalOpen.current()
    }

    const handleModalClose = () => {
        modalClose.current();
        setModalData(null);
    }

    if (customerData) {
        var CustomerOptions = customerData.map((customer, index) => {
            return (<option key={index} value={customer.customer_id}>{customer.gcs_name}</option>)
        })
    }

    if (airplaneData) {
        let airplaneKeys = Object.keys(airplaneData)
        var airplaneModelOptions = [...airplaneKeys].map((item, index) => {
            return (<option key={index} value={item}>{item}</option>)
        })
    }

    if(airplaneModelOptions && airplaneModelOptions.length && problemData && problemData.airplane_model) {
        var airplaneSubmodelOptions = [];

        let submodels = problemData.airplane_model.split(',').map(model => airplaneData[model]).flat().length ? problemData.airplane_model.split(',').map(model => airplaneData[model]).flat() : [];
        if (submodels.length) {
            submodels.map((item, _index) => {
                airplaneSubmodelOptions.push(<MenuItem key={item.id} value={item.submodel}>{item.submodel}</MenuItem>)
            })
        }
    }

    const Header = [
        { Header: '', accessor: 'actions', canFilter: false, disableSortBy: true },
        { Header: 'Status', accessor: 'status_chip', canFilter: false, disableSortBy: true },
        { Header: 'FDA Name', accessor: 'fda_name', canFilter: true },
        { Header: 'Boeing Mnemonic Code', accessor: 'boeing_mnemonic_code', canFilter: true },
        { Header: 'Airplane', accessor: 'airplane', canFilter: true },
        { Header: 'Parameter Description', accessor: 'parameter_desc', canFilter: true },
        { Header: 'ATA Code', accessor: 'ata_code', Cell: ({ cell: { value } }) => { return value ? value.join(',') : value }, canFilter: true },
        { Header: 'IA Sensor Name', accessor: 'ia_sensor_name', canFilter: true },
        { Header: 'Aggregators', accessor: 'aggregators', Cell: ({ cell: { value } }) => { return value ? value.toString() : value }, canFilter: true },
        { Header: 'Additional Info', accessor: 'additional_info', canFilter: true },
    ]

    if (parameterInputFields && problemData && airplaneData && configuredProblems)
        return (
            <>
                <div className='full-width'>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p className='table-title'>Problem Details</p>
                        <IconButton onClick={() => {removeMapping(index)}}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <br />
                    {configuredProblemCustomer.includes(problemData.problem_name + '-' + problemData.customer_id) ? <><Alert severity="warning">This Problem is already configured for this Customer.<br /> Any changes will update the existing configurations</Alert><br /></> : ''}
                    <div className='add-page-entity'>
                        <div className='add-page-form'>
                            <AutoCompleteInput
                                options={configuredProblems}
                                validationError={validationError}
                                width={'200px'}
                                label={'Problem Name*'}
                                value={problemData.problem_name}
                                handleChange={(n, v, r) => { handleProblemChange(index, n, v, r) }}
                                name={'problem_name'}
                                placeholder={"Enter Problem Name..."}
                            />
                            <div className='input-element'>
                                <label className='input_label'>GCS Name*</label>
                                <select style={{ width: '250px' }} className={validationError && problemData.customer_id == '' ? 'input_box_error' : 'input_box'} value={problemData.customer_id} onChange={event => handleProblemChange(index, event.target.name, event.target.value)} name="customer_id">
                                    <option value="" disabled>Select Customer...</option>
                                    {CustomerOptions}
                                </select>
                            </div>
                            <div className='input-element'>
                                <label className='input_label'>ATA Code</label>
                                <Select
                                    name="ata_code"
                                    className={'input_box'}
                                    style={{ width: '250px ', height: '40px' }}
                                    size='small'
                                    placeholder="Select ATA Code..."
                                    value={problemData.ata_code || ''}
                                    options={ataCodeOptions}
                                    onChange={(e) => handleProblemChange(index, e.target.name, e.target.value)}
                                    multiple
                                >
                                    {ataCodeOptions}
                                </Select>
                            </div>
                            <div className='input-element'>
                                <label className='input_label'>Airplane Model*</label>
                                <select style={{ width: '200px' }} className={validationError && problemData.airplane_model == '' ? 'input_box_error' : 'input_box'} value={problemData.airplane_model} onChange={event => handleProblemChange(index, event.target.name, event.target.value)} name="airplane_model">
                                    <option value="" disabled>Select Airplane Model...</option>
                                    {airplaneModelOptions}
                                </select>
                            </div>
                            <div className='input-element'>
                                <label className='input_label'>Airplane Sub-Model*</label>
                                <Select
                                    name="airplane_submodel"
                                    className={validationError && problemData.airplane_submodel == '' ? 'input_box_error' : 'input_box'}
                                    style={{ width: '200px', height: '40px' }}
                                    size='small'
                                    placeholder="Select Airplane Model..."
                                    value={problemData.airplane_submodel}
                                    options={airplaneSubmodelOptions}
                                    onChange={event => handleProblemChange(index, event.target.name, event.target.value)}
                                    multiple
                                >
                                    {airplaneSubmodelOptions}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderTop: '1px solid var(--color-secondary-light)', margin: '25px 0', width: '100%' }} />

                    <Table
                        header={Header}
                        data={parameterInputFields}
                        title='Parameter List'
                        pageSize={DEFAULT_PAGE_SIZE}
                    />

                    <div className='add-page-button-group'>
                        <Button variant="outlined-primary" action={() => addFields(index)}>
                            <span className="material-icons-outlined" style={{ marginRight: '5px' }}>add</span><p>Add Parameter</p>
                        </Button>
                        <Button variant="outlined-danger" action={() => removeAllFields(index)}>
                            <span className="material-icons-outlined" style={{ marginRight: '5px' }}>clear</span><p>Clear All</p>
                        </Button>
                    </div>
                </div>

                <Modal modalOpen={modalOpen} modalClose={modalClose} title="Edit Mapped Parameter" >
                    <EditMappedParameter
                        customerId={problemData.customer_id}
                        problemAirplane={problemData.airplane_model}
                        configuredParametersData={configuredParametersData}
                        configuredParameters={configuredParameters}
                        data={parameterInputFields[modalData]}
                        airplaneOptions={airplaneModelOptions}
                        aggregatorOptions={aggregatorOptions}
                        flightPhaseOptions={flightPhaseOptions}
                        ataCodeOptions={ataCodeOptions}
                        index={modalData}
                        modalClose={(parameterIndex, data) => {
                            saveParameterEdit(index, parameterIndex, data);
                            handleModalClose();
                        }}
                    />
                </Modal>
            </>
        )
    else
        return (
            <>
                <Skeleton variant="rectangular" width='100%' height='200px' />
                <Divider />
                <Skeleton variant="rectangular" width='100%' height='200px' />
            </>
        )
}

export default ParameterMapper