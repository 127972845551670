import React, { useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { createAuthProvider } from 'react-token-auth';

let AuthContext = React.createContext({
  isAuthenticated: false,
  name: null,
  googleid: null,
  email: null,
  signout: null,
  handleLogin: null,
  authAxios: null,
});

function wait(ms) {
    return new Promise( (resolve) => {setTimeout(resolve, ms)});
}

function AuthProvider(props) {
  const [name, setName] = useState(null);
  const [googleid, setGoogleid] = useState(null);
  const [email, setEmail] = useState(null);
  const [authInProgress, setAuthInProgress] = useState(false);

  const axios = require('axios');
  let authAxios = axios.create();
  authAxios.defaults.headers.common['Expires'] = `0`;
  authAxios.defaults.headers.common['Cache-Control'] = `no-cache`;

  const {useAuth, authFetch, login, logout, getSession, getSessionState} =
    createAuthProvider({
      getAccessToken: (session) => session.access_token,
      accessTokenKey: 'access_token',
      refreshTokenKey: 'refresh_token',
      onUpdateToken: (token) => fetch(process.env.REACT_APP_BACKEND_API + '/refresh', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token.refresh_token}`
          }
      })
      .then(r => {
        let data = r.json;
        authAxios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
        return data;
      })
    });

  let session = getSessionState();
  const [isAuthenticated, setIsAuthenticated] = useState(session !== null);
  if (isAuthenticated) {
    authAxios.defaults.headers.common['Authorization'] = `Bearer ${session.access_token}`;
  }

  let signout = (callback) => {
    logout();
    setGoogleid(null);
    setEmail(null);
    setName(null);
    setIsAuthenticated(false);
    authAxios.defaults.headers.common['Authorization'] = null;
    callback();
  }

  async function handleLogin(session, login_url, callback) {
    if (session.access_token) { // Tokens available in url (from backend redirect)
      login(session);
      setIsAuthenticated(true);
      authAxios.defaults.headers.common['Authorization'] = `Bearer ${session.access_token}`;
    }

    if (!isAuthenticated) {  // Redirect to login
      window.location.href = login_url;
    } else if (!googleid) {  // Missing user info
      if (!authInProgress) {
        setAuthInProgress(true);
        await wait(500);
        authAxios(process.env.REACT_APP_BACKEND_API + '/userdata').then((response) => {
          setGoogleid(response.data.google_id);
          setEmail(response.data.email);
          setName(response.data.name);
          callback();
          setAuthInProgress(false);
        })
        .catch((error) => {
          setAuthInProgress(false);
          if (error.response) {
            signout(() => {
              window.location.href = login_url;
            });
          }
        });
      }
    }
  }
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {  // running locally
    handleLogin = async (session, login_url, callback) => {
      if (!isAuthenticated) {
        let local_session = {access_token: '123'};
        login(local_session);
        setIsAuthenticated(true);
        authAxios.defaults.headers.common['Authorization'] = `Bearer ${local_session.access_token}`;
      }
      if (!googleid) {
        setAuthInProgress(true);
        await wait(500);
        setGoogleid('2');
        setEmail('dev.user@flight-analytics.com');
        setName('Dev User');
        callback();
        setAuthInProgress(false);
      }
    }
  }
  let value = { isAuthenticated, googleid, email, name, signout, handleLogin, authAxios };

  return (
    <>
      <AuthContext.Provider value={value}>
        {props.children}
      </AuthContext.Provider>
    </>
  );
}

// custom hook to get user details
export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth(props) {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  let login_url = process.env.REACT_APP_BACKEND_API + '/login?return_url=' + encodeURIComponent(window.location.href);
  let access_token = searchParams.get("access_token");
  let session = {};
  if (access_token) {
    session = {
      access_token: access_token,
      refresh_token: searchParams.get("refresh_token")
    };
    searchParams.delete('access_token');
    searchParams.delete('refresh_token');
    login_url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
  }

  const [contents, setContents] = useState(
    <div>
      <h3><a href={login_url}>Logging In...</a></h3>
    </div>);

  auth.handleLogin(session, login_url, () => {
    setSearchParams(searchParams);
    setContents(props.children);
  });
  return (
    <>
      {contents}
    </>
  );
}

export default AuthProvider