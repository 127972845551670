import React,{useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
// import Button from '@mui/material/Button';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

/*
props:
  modalClose - ref to handleClickOpen
  modalOpen - ref to handleClickClose
  title - modal title
  width - modal width
  children
*/


function Modal({modalClose,modalOpen,title,children,width}) {

  //open - state to track if modal is open or closed
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  
  //add responsiveness, modal is full screen on smaller screen sizes
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    modalOpen.current = handleClickOpen;
    modalClose.current = handleClickClose;
  }, [])

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClickClose}
        maxWidth={width?width:'sm'}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {title}
          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Modal
