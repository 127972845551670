import React, { useState, useEffect } from 'react'
import Button from '../../Components/UI-Elements/Button'
import AutoCompleteInput from '../../Components/UI-Elements/AutoCompleteInput';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';

function EditMappedParameter(props) {

    const parameterTemplate = {
        fda_name: '',
        boeing_mnemonic_code: '',
        airplane: '',
        ata_code: '',
        ia_sensor_name: '',
        additional_info: '',
        parameter_desc: '',
        aggregators: [],
        flight_phases: [],
    };

    const [data, setData] = useState(parameterTemplate);

    useEffect(() => {
        // console.log(props)
        setData({...props.data})
    }, [props])

    const handleFormChange = (name, value, reason) => {
        let temp = { ...data };
        temp[name] = value;
        if(reason === 'reset' && value && props.configuredParameters.includes(value)) {
            let val = props.configuredParametersData[props.configuredParameters.indexOf(value)]
            temp['boeing_mnemonic_code'] = val['boeing_mnemonic_code'];
            temp['airplane'] = val['airplane'];
            temp['parameter_id'] = val['parameter_id'];
            temp['ata_code'] = val['ata_code'].split(',') ?? [];
            temp['ia_sensor_name'] = val['ia_sensor_name'] ?? '';
            temp['additional_info'] = val['additional_info'] ?? '';
            temp['parameter_desc'] = val['parameter_desc'] ?? '';
            temp['aggregators'] = val['aggregators'] ?? [];
            if(val === Object(val)) {
                
                temp['flight_phases'] = val['flight_phases']?val['flight_phases'][props.customerId]: [];
            } else {
                temp['flight_phases'] = val['flight_phases'] ?? [];
            }
        } else if(reason === 'clear') {
            temp = parameterTemplate;
        }
        console.log('test101',props.customerId)
        setData(temp);
    }

    const [alert, setAlert] = useState({ type: 'error', text: '' })
    const [popup, setPopup] = useState(false)
    const [validationError, setValidationError] = useState(false)

    const openPopup = (temp) => {
        setAlert(temp)
        setPopup(true)
    }

    const getAirplaneFromName = (name) => {
        return name.split('(')[1].split('-')[0];
    }

    const handleSave = () => {
        let validateError = validateForm()

        if (!validateError) {
            let temp = {...data};
            temp.fda_name = temp.fda_name.split('(')[0];
            props.modalClose(props.index, temp);
        }
        else {
            openPopup({ type: 'error', text: 'Error!! Check Fields Again' })
        }
    }

    function validateForm() {
        if (data['fda_name'] == '' ||
            data['airplane'] == '' ||
            data['boeing_mnemonic_code'] == '' ||
            data['ia_sensor_name'] == '') {
            setValidationError(true)
            return true;
        }
        else {
            return false;
        }
    }

    if (data)
        return (
            <div>
                {props.configuredParameters.includes(data.fda_name) ? <><Alert severity="warning">This Parameter is already configured.<br /> Any changes will update the existing configurations</Alert><br /></> : ''}
                <div className='add-page-entity'>
                    <div className='add-page-form'>
                        <AutoCompleteInput
                            options={props.problemAirplane ?
                                props.configuredParameters.filter((p) => getAirplaneFromName(p) == props.problemAirplane) :
                                props.configuredParameters
                            }
                            validationError={validationError}
                            width={'250px'}
                            label={'FDA Name*'}
                            value={data.fda_name}
                            handleChange={(n, v, r) => { handleFormChange(n, v, r) }}
                            name={'fda_name'}
                            placeholder={"Enter FDA Name..."}
                        />

                        <div className='input-element'>
                            <label className='input_label'>Boeing Mnemonic Code*</label>
                            <input style={{ width: '250px' }} className='input_box' value={data.boeing_mnemonic_code} onChange={event => handleFormChange(event.target.name, event.target.value)} type="text" name="boeing_mnemonic_code" placeholder="Enter Boeing Mnemonic Code..." />
                        </div>
                        <div className='input-element'>
                            <label className='input_label'>Airplane Model*</label>
                            <select 
                                style={{ width: '250px' }} 
                                className={validationError.length > 0 && data.airplane == '' ? 'input_box_error' : 'input_box'} 
                                value={data.airplane || ''} 
                                onChange={event => handleFormChange(event.target.name, event.target.value)} 
                                name="airplane"
                            >
                                <option value="" disabled>Select Airplane Model...</option>
                                {props.airplaneOptions}
                            </select>
                        </div>
                        {/* <div className='input-element'>
                            <label className='input_label'>Airplane Model*</label>
                            <Select
                              name="airplane"
                              className={validationError.length > 0 && data.airplane == '' ? 'input_box_error' : 'input_box'}
                              style={{ width: '250px ', height: '40px' }}
                              size='small'
                              placeholder="Select Airplane Model..."
                              value={data.airplane || ''}
                              options={props.airplaneOptions}
                              onChange={event => handleFormChange(event.target.name, event.target.value)}
                            >
                              {props.airplaneOptions}
                            </Select>
                        </div> */}
                        <div className='input-element'>
                            <label className='input_label'>ATA Code</label>
                            <Select
                                name="ata_code"
                                className={'input_box'}
                                style={{ width: '250px ', height: '40px' }}
                                size='small'
                                placeholder="Select ATA Code..."
                                value={data.ata_code || []}
                                options={props.ataCodeOptions}
                                onChange={event => handleFormChange(event.target.name, event.target.value)}
                                multiple
                            >
                                {props.ataCodeOptions}
                            </Select>
                        </div>
                        <div className='input-element'>
                            <label className='input_label'>IA Sensor Name*</label>
                            <input style={{ width: '250px' }} value={data.ia_sensor_name} onChange={event => handleFormChange(event.target.name, event.target.value)} className={validationError && data.ia_sensor_name == '' ? 'input_box_error' : 'input_box'} type="text" name="ia_sensor_name" placeholder="Enter IA Sensor Name..." />
                        </div>
                        <div className='input-element'>
                            <label className='input_label'>Aggregators</label>
                            <Select
                                name="aggregators"
                                className={'input_box'}
                                style={{ width: '250px ', height: '40px' }}
                                size='small'
                                placeholder="Select Aggregators..."
                                value={data.aggregators || []}
                                options={props.aggregatorOptions}
                                onChange={event => handleFormChange(event.target.name, event.target.value)}
                                multiple
                            >
                                {props.aggregatorOptions}
                            </Select>
                        </div>
                        <div className='input-element'>
                            <label className='input_label'>Additional Information</label>
                            <textarea style={{ width: '510px', resize: 'vertical' }} value={data.additional_info || ''} onChange={event => handleFormChange(event.target.name, event.target.value)} className='input_box' type="text" name="additional_info" placeholder="Enter Additional Information..." />
                        </div>
                        <div className='input-element'>
                            <label className='input_label'>Parameter Description</label>
                            <textarea style={{ width: '510px', resize: 'vertical' }} value={data.parameter_desc || ''} onChange={event => handleFormChange(event.target.name, event.target.value)} className='input_box' type="text" name="parameter_desc" placeholder="Enter Parameter Description..." />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '21px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <Button variant="outlined-primary" action={handleSave}>
                        <p>Save</p>
                    </Button>
                </div>

                <Snackbar open={popup} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }} autoHideDuration={1000} onClose={() => { setPopup(false) }}>
                    <Alert severity={alert['type']} sx={{ width: '100%' }}>
                        {alert['text']}
                    </Alert>
                </Snackbar>

            </div>
        )
}

export default EditMappedParameter